import clsx from 'clsx';
import './styles.scss';

type TProps = {
  children: React.ReactNode;
  className?: string;
  title: string;
};

export const BoxSearchWrapper = (props: TProps) => {
  return (
    <section className={clsx('box-search-wrapper', props.className)}>
      <p className="text-title mb-3">{props.title}</p>
      {props.children}
    </section>
  );
};

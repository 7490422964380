import React, { useState } from 'react';

import { initParamsSearch } from 'src/const';
import { IParamsSearchBook } from 'src/services/book';

import Card from '../../components/Card';

import { SearchBook, ListBook } from './components';

import './styles.scss';

export const Book: React.FC = () => {
  const [paramsSearch, setParamsSearch] = useState<IParamsSearchBook>(initParamsSearch);
  return (
    <div className="book-page">
      <Card>
        <SearchBook paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      </Card>
      <Card className="mt-10">
        <ListBook paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      </Card>
    </div>
  );
};

import httpClient from 'src/configs/http-client';
import { jsonConfig } from 'src/const';
import { IList } from 'src/types';

import { IAuthor } from './author.interface';

export const getListAuthor = () => {
  return httpClient.get<IList<IAuthor>>('/author/getListAuthor');
};
export const createAuthor = (body: IAuthor) => {
  return httpClient.post<IAuthor>('/author/create', body, jsonConfig);
};
export const updateAuthor = (body: IAuthor) => {
  return httpClient.post<IAuthor>('/author/update', body, jsonConfig);
};
export const deleteAuthor = (id: number) => {
  return httpClient.post<void>('/author/delete', { id }, jsonConfig);
};

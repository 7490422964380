import React from 'react';

import styles from './styles.module.scss';

type TPropsCard = {
  children: React.ReactNode;
  className?: string;
};
const Card = (props: TPropsCard) => {
  return <div className={`${styles.cardWrap} ${props.className || ''}`}>{props.children}</div>;
};

export default Card;

import React from 'react';

import './styles.scss';
import { BookBorrowed } from './components/BookBorrowed';
import { LineChartRented } from './components/LineChartRented';
import { ReaderActive } from './components/ReaderActive';

export const ReportRented: React.FC = () => {
  return (
    <div className="report-rented">
      <LineChartRented />
      <ReaderActive />
      <BookBorrowed />
    </div>
  );
};

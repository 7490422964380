import React from 'react';

import { Button } from 'antd';
import clsx from 'clsx';

import './button-primary.scss';
import { TButton } from '../../../types';

export const ButtonPrimary: React.FC<TButton> = ({ children, ...rest }) => {
  const classButtonPrimary = clsx('primary-button', rest.className && rest.className);
  return (
    <Button {...rest} className={classButtonPrimary} type="primary">
      {children}
    </Button>
  );
};

import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { ModalConfirmDelete } from 'src/components/ModalConfirmDelete';
import { TableCustom } from 'src/components/TableCustom';
import { DEFAULT_PAGE_SIZE, FIRST_PAGE, initParamsSearch, PAGE_SIZE_OPTIONS, ResponseStatusEnum } from 'src/const';
import {
  formatStringDate,
  getFullImageUrl,
  messageError,
  notificationSuccessAndScrollToTop,
  parseListCategoryStringToJson,
} from 'src/helpers/fileHelper';
import { usePermission } from 'src/hooks/usePermission';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListBookAction } from 'src/redux/slices/book.slice';
import { deleteBook, IBook, IParamsSearchBook } from 'src/services/book';
import { MayBe } from 'src/types';

import { FormItemBook, OriginBookEnum, ORIGIN_BOOK_CONFIG } from '../config';
import { ModalCreateUpdateBook } from '../ModalCreateUpdateBook';

import { ActionTable } from './ActionTable';

import './styles.scss';

type TListBookProps = {
  paramsSearch: IParamsSearchBook;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsSearchBook>>;
};
export const ListBook = ({ paramsSearch, setParamsSearch }: TListBookProps) => {
  const { loading, listBook, pagination } = useAppSelector((state) => state.book);
  const { listPublisher } = useAppSelector((state) => state.publisher);
  const { listAuthor } = useAppSelector((state) => state.author);
  const { isAccessBook } = usePermission();
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showModalCreateUpdate, setShowModalCreateUpdate] = useState<boolean>(false);
  const [dataDetailBook, setDataDetailBook] = useState<MayBe<IBook>>(null);

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [recordDelete, setRecordDelete] = useState<MayBe<IBook>>(null);

  const tableColumns: any = [
    {
      title: 'Thể loại',
      width: 100,
      render: (record: IBook) => {
        const dataCategory = parseListCategoryStringToJson(record.dataCategory);
        return (
          <>
            {dataCategory.map((item) => (
              <img key={item.id} className="h-8 my-1" src={getFullImageUrl(item.logo)} alt="logo" />
            ))}
          </>
        );
      },
    },
    {
      title: 'Mã sách',
      dataIndex: FormItemBook.CODE,
      width: 150,
      render: (code: string, record: IBook) => {
        return (
          <p className="font-semi-bold cursor-pointer" onClick={() => editRecord(record)}>
            {code}
          </p>
        );
      },
    },
    {
      title: 'Tên sách',
      dataIndex: FormItemBook.NAME,
      width: 250,
      render: (name: string, record: IBook) => {
        return (
          <p className="font-semi-bold cursor-pointer" onClick={() => editRecord(record)}>
            {name}
          </p>
        );
      },
    },
    {
      title: 'Số lượng',
      dataIndex: FormItemBook.QTY,
      width: 100,
    },
    // {
    //   title: 'Có sẵn',
    //   dataIndex: FormItemBook.AVAILABLE,
    //   width: 100,
    // },
    // {
    //   title: 'Vị trí',
    //   width: 200,
    //   render: (record: IBook) => <p>{record.bookPosition?.name}</p>,
    // },
    {
      title: 'Tác giả',
      dataIndex: FormItemBook.AUTHOR_ID,
      width: 200,
      render: (authorId: number) => <p>{listAuthor.find((item) => item.id === authorId)?.name || ''}</p>,
    },
    {
      title: 'Nhà XB',
      dataIndex: FormItemBook.PUBLISHER_ID,
      width: 200,
      render: (publisherId: number) => <p>{listPublisher.find((item) => item.id === publisherId)?.name || ''}</p>,
    },
    {
      title: 'Năm XB',
      dataIndex: FormItemBook.PUBLISH_YEAR,
      width: 100,
    },
    {
      title: 'Nguồn gốc',
      dataIndex: FormItemBook.ORIGIN_BOOK,
      width: 120,
      render: (originBook: OriginBookEnum) => (
        <p>{ORIGIN_BOOK_CONFIG.find((item) => item.value === originBook)?.text || ''}</p>
      ),
    },
    {
      title: 'Ngày nhập',
      dataIndex: FormItemBook.ENTRY_DATE,
      width: 150,
      render: (entryDate: string) => formatStringDate(entryDate),
    },
  ];

  if (isAccessBook) {
    tableColumns.push({
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: IBook) => {
        return (
          <ActionTable
            record={record}
            editRecord={() => editRecord(record)}
            showDeleteConfirm={() => showDeleteConfirm(record)}
          />
        );
      },
    });
  }

  useEffect(() => {
    getListBook(initParamsSearch);
  }, []);

  function onChangePage(page: any, pageSize: any) {
    getListBook({ ...paramsSearch, page, limit: pageSize });
  }

  async function getListBook(params: IParamsSearchBook) {
    setParamsSearch(params);
    const error: any = await dispatch(getListBookAction(params));
    if (error?.length) {
      messageError(error);
    }
  }

  function addRecord() {
    setEditMode(false);
    setShowModalCreateUpdate(true);
  }
  function editRecord(record: IBook) {
    setEditMode(true);
    setDataDetailBook(record);
    setShowModalCreateUpdate(true);
  }
  async function deleteRecord() {
    if (!recordDelete) {
      setShowConfirmDelete(false);
      return;
    }
    setLoadingDelete(true);
    const response: any = await deleteBook(recordDelete.id);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
    } else {
      const params =
        listBook.length > 1 // before delete, list data > 1 record
          ? paramsSearch // search with current params
          : {
              ...paramsSearch,
              page: paramsSearch.page - 1 || FIRST_PAGE,
            }; // search with previous page number
      getListBook(params);
      notificationSuccessAndScrollToTop();
    }
    setLoadingDelete(false);
    setShowConfirmDelete(false);
  }

  const showDeleteConfirm = (recordDelete: IBook) => {
    if (!recordDelete) return;
    setRecordDelete(recordDelete);
    setShowConfirmDelete(true);
  };

  return (
    <div className="list-book">
      <div className="flex justify-between">
        <p className="text-title mb-1">
          Tổng số đầu sách: <span className="secondary-color">{pagination?.totalItem}</span>
        </p>
        {isAccessBook && (
          <ButtonSecondary id="btn-add" onClick={addRecord}>
            <PlusOutlined /> Thêm
          </ButtonSecondary>
        )}
      </div>
      <TableCustom
        columns={tableColumns}
        dataSource={listBook}
        scroll={{ x: 1500 }}
        // scroll={{ x: 1500, y: 500 }}
        rowKey={(record: any) => record.id}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          onChange: onChangePage,
          total: pagination.totalItem,
          current: pagination.currentPage,
        }}
        loading={loading}
      />
      <ModalCreateUpdateBook
        refetchData={() => getListBook(paramsSearch)}
        editMode={editMode}
        open={showModalCreateUpdate}
        onCloseModal={() => {
          setShowModalCreateUpdate(false);
          setDataDetailBook(null);
        }}
        dataDetailBook={dataDetailBook}
      />
      <ModalConfirmDelete
        loading={loadingDelete}
        open={showConfirmDelete}
        content={recordDelete?.name}
        onCancel={() => {
          setShowConfirmDelete(false);
          setRecordDelete(null);
        }}
        onDelete={deleteRecord}
      />
    </div>
  );
};

import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { ModalConfirmDelete } from 'src/components/ModalConfirmDelete';
import { TableCustom } from 'src/components/TableCustom';
import { ResponseStatusEnum } from 'src/const';
import { messageError, notificationSuccess } from 'src/helpers/fileHelper';
import { usePermission } from 'src/hooks/usePermission';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListPublisherAction } from 'src/redux/slices/publisher.slice';
import { deletePublisher, IPublisher } from 'src/services/publisher';
import { MayBe } from 'src/types';

import { ModalCreateUpdatePublisher } from '../ModalCreateUpdate/ModalCreateUpdatePublisher';

import { ActionTable } from './ActionTable';

export const ListPublisher = () => {
  const { loading, listPublisher } = useAppSelector((state) => state.publisher);
  const { isAccessBook } = usePermission();
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [showModalCreateUpdate, setShowModalCreateUpdate] = useState(false);
  const [dataDetailPublisher, setDataDetailPublisher] = useState<MayBe<IPublisher>>(null);

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [recordDelete, setRecordDelete] = useState<MayBe<IPublisher>>(null);

  const tableColumns: any = [
    {
      title: 'Tên nhà xuất bản',
      dataIndex: 'name',
      width: 400,
      render: (name: string, record: IPublisher) => {
        return (
          <p className="font-semi-bold cursor-pointer" onClick={() => editRecord(record)}>
            {name}
          </p>
        );
      },
    },
  ];

  if (isAccessBook) {
    tableColumns.push({
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: IPublisher) => {
        return (
          <ActionTable editRecord={() => editRecord(record)} showDeleteConfirm={() => showDeleteConfirm(record)} />
        );
      },
    });
  }

  useEffect(() => {
    !listPublisher?.length && getListPublisher();
  }, []);

  async function getListPublisher() {
    const error: any = await dispatch(getListPublisherAction());
    if (error?.length) {
      messageError(error);
    }
  }

  function addRecord() {
    setEditMode(false);
    setShowModalCreateUpdate(true);
  }
  function editRecord(record: IPublisher) {
    setEditMode(true);
    setDataDetailPublisher(record);
    setShowModalCreateUpdate(true);
  }
  async function deleteRecord() {
    if (!recordDelete) {
      setShowConfirmDelete(false);
      return;
    }
    setLoadingDelete(true);
    const response: any = await deletePublisher(recordDelete.id);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
    } else {
      getListPublisher();
      notificationSuccess();
    }
    setLoadingDelete(false);
    setShowConfirmDelete(false);
  }

  const showDeleteConfirm = (recordDelete: IPublisher) => {
    if (!recordDelete) return;
    setRecordDelete(recordDelete);
    setShowConfirmDelete(true);
  };

  return (
    <>
      <div className="flex justify-between">
        <p className="text-title mb-1">
          Tổng số nhà xuất bản: <span className="secondary-color">{listPublisher?.length}</span>
        </p>
        {isAccessBook && (
          <ButtonSecondary id="btn-add" onClick={addRecord}>
            <PlusOutlined /> Thêm
          </ButtonSecondary>
        )}
      </div>
      <TableCustom
        columns={tableColumns}
        dataSource={listPublisher}
        scroll={{ x: 300, y: 500 }}
        pagination={false}
        rowKey={(record: any) => record.id}
        loading={loading}
      />
      {showModalCreateUpdate && (
        <ModalCreateUpdatePublisher
          refetchData={() => getListPublisher()}
          editMode={editMode}
          open={showModalCreateUpdate}
          onCloseModal={() => {
            setShowModalCreateUpdate(false);
            setDataDetailPublisher(null);
          }}
          dataDetailPublisher={dataDetailPublisher}
        />
      )}
      {showConfirmDelete && (
        <ModalConfirmDelete
          loading={loadingDelete}
          open={showConfirmDelete}
          content={recordDelete?.name}
          onCancel={() => {
            setShowConfirmDelete(false);
            setRecordDelete(null);
          }}
          onDelete={deleteRecord}
        />
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';

import { Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import moment from 'moment';

import { ButtonPrimary, ModalMessage } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import HandleAddress from 'src/components/HandleAddress';
import { ModalCustom } from 'src/components/ModalCustom';
import {
  CommonStatusEnum,
  DATE_TIME_FORMAT,
  GenderEnum,
  GENDER_CONFIG,
  MESSAGE,
  PATTERN_NOT_ONLY_SPACE,
  PATTERN_NUMBER,
  PATTERN_PHONE,
  ResponseStatusEnum,
} from 'src/const';
import {
  formatDateRequestAPI,
  formatDateSetFieldValue,
  messageError,
  notificationSuccessAndScrollToTop,
} from 'src/helpers';
import HandleReaderStatus from 'src/pages/Reader/components/ModalCreateUpdateReader/HandleReaderStatus';
import { useAppSelector } from 'src/redux/hook';
import {
  checkExistReaderInfo,
  createReader,
  IBodyCreateUpdateReader,
  IReader,
  updateReader,
} from 'src/services/reader';
import { MayBe } from 'src/types';

import { FormItemReader } from '../config';

import './styles.scss';

interface IProps {
  refetchData: () => void;
  onCloseModal: () => void;
  editMode: boolean;
  open: boolean;
  dataDetailReader: MayBe<IReader>;
}

export const ModalCreateUpdateReader = ({ refetchData, editMode, open, onCloseModal, dataDetailReader }: IProps) => {
  const { listRank } = useAppSelector((state) => state.app);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageExistReaderInfo, setMessageExistReaderInfo] = useState('');
  const watchAutoCardNumber = Form.useWatch('autoCardNumber', form);

  useEffect(() => {
    loading && setLoading(false);
    if (!dataDetailReader) {
      form.resetFields();
      form.setFieldsValue({
        createdDate: formatDateSetFieldValue(moment().format()),
      });
      return;
    }
    form.setFieldsValue({
      ...dataDetailReader,
      dob: formatDateSetFieldValue(dataDetailReader.dob),
      lockToDate: formatDateSetFieldValue(dataDetailReader.lockToDate),
      createdDate: formatDateSetFieldValue(dataDetailReader.createdDate),
    });
  }, [dataDetailReader]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const body: IBodyCreateUpdateReader = {
      ...values,
      id: dataDetailReader?.id,
      dob: formatDateRequestAPI(values.dob),
      lockToDate: formatDateRequestAPI(values.lockToDate),
    };

    if (!editMode) {
      // truoc khi tao ban doc, check thong tin xem ban doc da lam the truoc do chua
      const res: any = await checkExistReaderInfo(body);
      if (res.status === ResponseStatusEnum.ERROR) {
        setLoading(false);
        setMessageExistReaderInfo(res.message);
        return;
      }
    }

    const response: any = editMode ? await updateReader(body) : await createReader(body);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      setLoading(false);
      return;
    }
    setLoading(false);
    form.resetFields();
    onCloseModal();
    notificationSuccessAndScrollToTop();
    refetchData();
  };

  return (
    <>
      <ModalCustom
        open={open}
        title={editMode ? 'Sửa thông tin bạn đọc' : 'Thêm bạn đọc'}
        className="modal-reader"
        width={650}
        loading={loading}
        onCancel={onCloseModal}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{
            gender: GenderEnum.FEMALE,
            status: CommonStatusEnum.ACTIVE,
            autoCardNumber: true,
            point: 0,
            rankId: listRank[0].id,
          }}
        >
          {!editMode && (
            <Form.Item name="autoCardNumber">
              <Radio.Group>
                <Radio value={true}>Số thẻ bạn đọc tự động tăng theo số thẻ gần nhất</Radio>
                <Radio value={false}>Số thẻ bạn đọc nhập tay</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {(editMode || !watchAutoCardNumber) && (
            <Form.Item
              name={FormItemReader.CARD_NUMBER}
              label="Số thẻ bạn đọc"
              rules={[
                { required: true },
                {
                  pattern: PATTERN_NUMBER,
                  message: MESSAGE.requiredNumberGte0,
                },
              ]}
            >
              <Input type="text" maxLength={6} />
            </Form.Item>
          )}
          <Row gutter={24}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={FormItemReader.NAME}
                label="Họ tên bạn đọc"
                rules={[{ required: true }, { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name={FormItemReader.DOB} label="Ngày sinh" rules={[{ required: true }]}>
                <DatePicker placeholder="" format={DATE_TIME_FORMAT} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={FormItemReader.PARENT_PHONE}
                label="SĐT liên lạc"
                rules={[
                  {
                    pattern: PATTERN_PHONE,
                    message: 'Vui lòng nhập đúng định dạng SĐT',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name={FormItemReader.PARENT_NAME} label="Người liên lạc">
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          <HandleAddress hamletId={dataDetailReader?.hamletId} address={dataDetailReader?.address} />
          <Row gutter={24}>
            <Col xs={24} sm={12}>
              <Form.Item name={FormItemReader.GENDER} label="Giới tính" rules={[{ required: true }]}>
                <Radio.Group>
                  {GENDER_CONFIG.map((items) => (
                    <Radio key={`READER_GENDER_${items.value}`} value={items.value}>
                      {items.text}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Row gutter={24}>
                <Col xs={14} sm={14}>
                  <Form.Item name={FormItemReader.RANK_ID} label="Phân hạng" rules={[{ required: true }]}>
                    <Select>
                      {listRank.map((item) => (
                        <Select.Option key={`RANK_ID_${item.id}`} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={10} sm={10}>
                  <Form.Item
                    name={FormItemReader.POINT}
                    label="Số điểm"
                    rules={[{ required: true }, { pattern: PATTERN_NUMBER, message: MESSAGE.requiredNumberGte0 }]}
                  >
                    <Input className="text-center" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={12}>
              <HandleReaderStatus status={dataDetailReader?.status} />
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name={FormItemReader.NOTE} label="Ghi chú">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name={FormItemReader.CREATED_DATE} label="Ngày tạo">
                <DatePicker placeholder="" format={DATE_TIME_FORMAT} disabled />
              </Form.Item>
            </Col>
            {editMode && (
              <>
                <Col xs={24} sm={12} className="data-rented-info">
                  <p>
                    Số lượt mượn: <span>{dataDetailReader?.totalRented || 0}</span>
                  </p>
                  <p>
                    Số quyển đã mượn: <span>{dataDetailReader?.totalBorrowed || 0}</span>
                  </p>
                </Col>
                <Col xs={24} sm={12} className="data-rented-info">
                  <p>
                    Số quyển quá hạn: <span>{dataDetailReader?.totalOverdueBook || 0}</span>
                  </p>
                  <p>
                    Số quyển làm mất: <span>{dataDetailReader?.lost || 0}</span>
                  </p>
                </Col>
              </>
            )}
          </Row>
          <div className="flex mt-5">
            <ButtonCancel id="btn-cancel" className="w-full mr-2" onClick={onCloseModal}>
              Thoát
            </ButtonCancel>
            <ButtonPrimary id="btn-save" className="w-full ml-2" htmlType="submit">
              Lưu
            </ButtonPrimary>
          </div>
        </Form>
      </ModalCustom>
      {!!messageExistReaderInfo && (
        <ModalMessage
          open={!!messageExistReaderInfo}
          content={<p className="text-center font-semi-bold text-base">{messageExistReaderInfo}</p>}
          onCancel={() => setMessageExistReaderInfo('')}
        />
      )}
    </>
  );
};

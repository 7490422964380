import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGE_SIZE, MESSAGE, ResponseStatusEnum } from 'src/const';
import {
  getListBookBorrowed,
  getReadersActiveByTime,
  IBookBorrowed,
  IParamsGetBookBorrowed,
  IParamsGetReaderActive,
  IReaderActive,
} from 'src/services/report';
import { TPagination } from 'src/types';

import { AppThunk } from '../store';

const DEFAULT_PAGINATION = {
  totalItem: 0,
  itemPerPage: DEFAULT_PAGE_SIZE,
  totalPage: 0,
  currentPage: 1,
};

type ReportState = {
  loadingReaderActive: boolean;
  paginationReaderActive: TPagination;
  readersActive: IReaderActive[];
  error: string;
  loadingBookBorrowed: boolean;
  paginationBookBorrowed: TPagination;
  booksBorrowed: IBookBorrowed[];
};

const initialState: ReportState = {
  loadingReaderActive: false,
  paginationReaderActive: DEFAULT_PAGINATION,
  readersActive: [],
  error: '',
  loadingBookBorrowed: false,
  paginationBookBorrowed: DEFAULT_PAGINATION,
  booksBorrowed: [],
};

export const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setLoadingReaderActive: (state: ReportState, action: PayloadAction<boolean>) => {
      state.loadingReaderActive = action.payload;
    },
    setListReaderActive: (state: ReportState, action: PayloadAction<IReaderActive[]>) => {
      state.readersActive = action.payload;
    },
    setPaginationReaderActive: (state: ReportState, action: PayloadAction<TPagination>) => {
      state.paginationReaderActive = action.payload;
    },
    setError: (state: ReportState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setLoadingBookBorrowed: (state: ReportState, action: PayloadAction<boolean>) => {
      state.loadingBookBorrowed = action.payload;
    },
    setListBookBorrowed: (state: ReportState, action: PayloadAction<IBookBorrowed[]>) => {
      state.booksBorrowed = action.payload;
    },
    setPaginationBookBorrowed: (state: ReportState, action: PayloadAction<TPagination>) => {
      state.paginationBookBorrowed = action.payload;
    },
  },
});

export const {
  setLoadingReaderActive,
  setListReaderActive,
  setPaginationReaderActive,
  setError,
  setLoadingBookBorrowed,
  setListBookBorrowed,
  setPaginationBookBorrowed,
} = slice.actions;

export const getListReaderActiveAction =
  (params: IParamsGetReaderActive): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingReaderActive(true));
    const response: any = await getReadersActiveByTime(params);
    if (response.status === ResponseStatusEnum.SUCCESS) {
      const { totalItem, itemPerPage, totalPage, currentPage, items } = response.data;
      dispatch(setListReaderActive(items));
      dispatch(
        setPaginationReaderActive({
          totalItem,
          itemPerPage,
          totalPage,
          currentPage,
        }),
      );
    } else {
      dispatch(setLoadingReaderActive(false));
      return response?.message || MESSAGE.getListReaderError;
    }
    dispatch(setLoadingReaderActive(false));
  };

export const getListBookBorrowedAction =
  (params: IParamsGetBookBorrowed): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingBookBorrowed(true));
    const response: any = await getListBookBorrowed(params);
    if (response.status === ResponseStatusEnum.SUCCESS) {
      const { totalItem, itemPerPage, totalPage, currentPage, items } = response.data;
      dispatch(setListBookBorrowed(items));
      dispatch(
        setPaginationBookBorrowed({
          totalItem,
          itemPerPage,
          totalPage,
          currentPage,
        }),
      );
    } else {
      dispatch(setLoadingBookBorrowed(false));
      return response?.message || MESSAGE.getListReaderError;
    }
    dispatch(setLoadingBookBorrowed(false));
  };

export default slice.reducer;

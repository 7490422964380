import React from 'react';

import { TopBook } from './components/TopBook';
import { TopReader } from './components/TopReader';

export const ReportTop: React.FC = () => {
  return (
    <>
      <TopBook />
      <TopReader />
    </>
  );
};

import React from 'react';
import './styles.scss';

export const LoginLayout: React.FC = ({ children }) => {
  return (
    <div className="login-layout flex justify-center items-center px-10 py-80 sm:px-14 lg:py-72 lg:px-28">
      {children}
    </div>
  );
};

import React from 'react';

import { ResponseStatusEnum } from 'src/const';
import { messageError, notificationInfo, notificationSuccess, notificationWarning } from 'src/helpers';
import { useAppSelector } from 'src/redux/hook';

type THandleMessageProps = {
  response: any;
  setLoading: (value: React.SetStateAction<boolean>) => void;
  refetchData: any;
};
export const useHandleUpdateRentedBook = () => {
  const { rentedBookSelected } = useAppSelector((state) => state.rentedBook);

  const countItemNotReturn = rentedBookSelected?.rentedBookDetail?.filter((item) => !item.returnedDate)?.length || 0;

  function handleMessageUpdateRentedBook({ response, setLoading, refetchData }: THandleMessageProps) {
    if (!rentedBookSelected) return false;
    const { status, message, data } = response;
    if (status === ResponseStatusEnum.ERROR) {
      messageError(message);
      setLoading(false);
      return false;
    }
    notificationSuccess();
    if (data?.countOverdueDate) {
      notificationWarning(
        `Trả sách thành công. NHƯNG QUÁ HẠN ${data.countOverdueDate} NGÀY`,
        'TNV vui lòng nhắc bạn đọc trả sách đúng hạn',
      );
    } else if (data?.newRank) {
      notificationInfo(
        `Chúc mừng ${rentedBookSelected.readerName.toUpperCase()} đã được thăng hạng lên hạng ${data.newRank.name}`,
      );
    }
    setLoading(false);
    typeof refetchData === 'function' && refetchData?.();
    return true;
  }
  return {
    countItemNotReturn,
    handleMessageUpdateRentedBook,
  };
};

import React, { useMemo } from 'react';
import { useState } from 'react';

import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

import './styles.scss';
import { DropdownMenuCustom, MenuItem } from 'src/components/DropdownMenuCustom';
import { ModalConfirm } from 'src/components/ModalConfirm';
import { checkCurrentMenu } from 'src/helpers/checkCurrentMenu';
import { useAppSelector } from 'src/redux/hook';
import { logout } from 'src/services/auth';

import { SideBarRoute } from '../main.type';

import { ModalChangePassword } from './ModalChangePassword';

export const Header = () => {
  const { userAuth } = useAppSelector((state) => state.user);
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleLogout = async () => {
    setLoading(true);
    await logout();
    history.push(SideBarRoute.LOGIN);
  };

  const handleShowDropdown = (show: boolean) => {
    setShowMenu(show);
  };

  const _dropdownMenu = (
    <DropdownMenuCustom className="dropdown-menu-header">
      <MenuItem
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
            />
          </svg>
        }
        text="Đổi mật khẩu"
        onClick={() => {
          setShowMenu(false);
          setShowChangePassword(true);
        }}
      />
      <MenuItem
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
          </svg>
        }
        text="Đăng xuất"
        className="delete-color"
        onClick={() => {
          setShowMenu(false);
          setShowConfirmLogout(true);
        }}
      />
    </DropdownMenuCustom>
  );

  const headerCurrent = useMemo(() => {
    return checkCurrentMenu(location.pathname);
  }, [location.pathname]);

  const Icon = headerCurrent.icon;

  return (
    <header className="header-home flex-wrap-reverse">
      <div className="header-home_title">
        <div className="header-home_title_text">
          <Icon
            className={clsx(
              'icon-header',
              [
                SideBarRoute.HOME,
                SideBarRoute.BOOK,
                SideBarRoute.CATEGORY,
                SideBarRoute.MEMBER,
                SideBarRoute.SETTING,
              ].includes(headerCurrent.link) && 'header-other-icon',
            )}
          />
          <h1>{headerCurrent.name}</h1>
        </div>
      </div>
      <Dropdown
        trigger={['click']}
        overlay={_dropdownMenu}
        placement="bottomRight"
        onOpenChange={handleShowDropdown}
        open={showMenu}
      >
        <div className="header-home_avatar">
          <p>{[userAuth?.account, userAuth?.name].filter(Boolean).join(' - ')}</p>
          <CaretDownOutlined className="pb-2 ml-2" />
        </div>
      </Dropdown>
      <ModalConfirm
        loading={loading}
        open={showConfirmLogout}
        title="Bạn chắc chắn muốn đăng xuất không?"
        onCancel={() => setShowConfirmLogout(false)}
        onConfirm={handleLogout}
      />
      <ModalChangePassword open={showChangePassword} onCloseModal={() => setShowChangePassword(false)} />
    </header>
  );
};

import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { useHandleAddress } from 'src/components/HandleAddress/useHandleAddress';
import { ModalConfirmDelete } from 'src/components/ModalConfirmDelete';
import { TableCustom } from 'src/components/TableCustom';
import {
  CommonStatusEnum,
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE,
  GenderEnum,
  GENDER_CONFIG,
  initParamsSearch,
  PAGE_SIZE_OPTIONS,
  ResponseStatusEnum,
} from 'src/const';
import { formatStringDate, messageError, notificationSuccessAndScrollToTop } from 'src/helpers/fileHelper';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListReaderAction } from 'src/redux/slices/reader.slice';
import { deleteReader, IParamsSearchReader, IReader } from 'src/services/reader';
import { MayBe } from 'src/types';

import { FormItemReader, READER_STATUS_CONFIG } from '../config';
import { ModalCreateUpdateReader } from '../ModalCreateUpdateReader';

import { ActionTable } from './ActionTable';

import './styles.scss';

type TListReaderProps = {
  paramsSearch: IParamsSearchReader;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsSearchReader>>;
};
export const ListReader = ({ paramsSearch, setParamsSearch }: TListReaderProps) => {
  const { loading, readers: listReader, pagination } = useAppSelector((state) => state.reader);
  const { listRank } = useAppSelector((state) => state.app);
  const { getAddress } = useHandleAddress();
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataDetailReader, setDataDetailReader] = useState<MayBe<IReader>>(null);

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [recordDelete, setRecordDelete] = useState<MayBe<IReader>>(null);

  const tableColumns: any = [
    {
      title: 'Số thẻ',
      dataIndex: FormItemReader.CARD_NUMBER,
      width: 80,
    },
    {
      title: 'Họ tên',
      dataIndex: FormItemReader.NAME,
      width: 250,
      render: (name: string, record: IReader) => {
        return (
          <p className="text-base cursor-pointer font-semi-bold" onClick={() => editRecord(record)}>
            {name}
          </p>
        );
      },
    },
    {
      title: 'Sinh nhật',
      dataIndex: FormItemReader.DOB,
      width: 110,
      render: (dob: string) => formatStringDate(dob),
    },
    {
      title: 'SĐT liên lạc',
      dataIndex: FormItemReader.PARENT_PHONE,
      width: 120,
    },
    {
      title: 'Địa chỉ',
      dataIndex: FormItemReader.ADDRESS,
      width: 250,
      render: (address: string, record: IReader) => <p>{getAddress(record.hamletId, address)}</p>,
    },
    {
      title: 'Giới tính',
      dataIndex: FormItemReader.GENDER,
      width: 100,
      render: (gender: GenderEnum) => GENDER_CONFIG.find((item) => item.value === gender)?.text || '',
    },
    {
      title: 'Hạng',
      dataIndex: FormItemReader.RANK_ID,
      width: 100,
      render: (rankId: number) => listRank.find((item) => item.id === rankId)?.name || '',
    },
    {
      title: 'Điểm',
      dataIndex: FormItemReader.POINT,
      width: 80,
    },
    {
      title: 'Hoạt động cuối',
      dataIndex: FormItemReader.LAST_ACTIVE_DATE,
      width: 140,
      render: (lastActiveDate: string) => formatStringDate(lastActiveDate),
    },
    {
      title: 'Trạng thái',
      dataIndex: FormItemReader.STATUS,
      width: 120,
      render: (status: number, record: IReader) => {
        return (
          <p className={clsx(record.status === CommonStatusEnum.LOCK && 'lock-status')}>
            {READER_STATUS_CONFIG.find((item) => item.value === status)?.text || ''}
          </p>
        );
      },
    },
    {
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: IReader) => {
        return (
          <ActionTable editRecord={() => editRecord(record)} showDeleteConfirm={() => showDeleteConfirm(record)} />
        );
      },
    },
  ];

  useEffect(() => {
    getListReader(initParamsSearch);
  }, []);

  function onChangePage(page: any, pageSize: any) {
    getListReader({ ...paramsSearch, page, limit: pageSize });
  }

  async function getListReader(params: IParamsSearchReader) {
    setParamsSearch(params);
    const error: any = await dispatch(getListReaderAction(params));
    if (error?.length) {
      messageError(error);
    }
  }

  function addRecord() {
    setEditMode(false);
    setShowModal(true);
  }
  function editRecord(record: IReader) {
    setEditMode(true);
    setDataDetailReader(record);
    setShowModal(true);
  }
  async function deleteRecord() {
    if (!recordDelete) {
      setShowConfirmDelete(false);
      return;
    }
    setLoadingDelete(true);
    const response: any = await deleteReader(recordDelete.id);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
    } else {
      const params =
        listReader.length > 1 // before delete, list data > 1 record
          ? paramsSearch // search with current params
          : {
              ...paramsSearch,
              page: paramsSearch.page - 1 || FIRST_PAGE,
            }; // search with previous page number
      getListReader(params);
      notificationSuccessAndScrollToTop();
    }
    setLoadingDelete(false);
    setShowConfirmDelete(false);
  }

  const showDeleteConfirm = (recordDelete: IReader) => {
    if (!recordDelete) return;
    setRecordDelete(recordDelete);
    setShowConfirmDelete(true);
  };

  return (
    <div className="list-reader">
      <div className="flex justify-between">
        <p className="text-title mb-1">
          Có tất cả <span className="secondary-color">{pagination?.totalItem}</span> bạn đọc
        </p>
        <ButtonSecondary id="btn-add" onClick={addRecord}>
          <PlusOutlined /> Thêm
        </ButtonSecondary>
      </div>
      <TableCustom
        columns={tableColumns}
        dataSource={listReader}
        scroll={{ x: 1500 }}
        // scroll={{ x: 1500, y: 500 }}
        rowKey={(record: any) => record.id}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          onChange: onChangePage,
          total: pagination.totalItem,
          current: pagination.currentPage,
        }}
        loading={loading}
      />
      <ModalCreateUpdateReader
        refetchData={() => getListReader(paramsSearch)}
        editMode={editMode}
        open={showModal}
        onCloseModal={() => {
          setShowModal(false);
          setDataDetailReader(null);
        }}
        dataDetailReader={dataDetailReader}
      />
      <ModalConfirmDelete
        loading={loadingDelete}
        open={showConfirmDelete}
        content={recordDelete?.name}
        onCancel={() => {
          setShowConfirmDelete(false);
          setRecordDelete(null);
        }}
        onDelete={deleteRecord}
      />
    </div>
  );
};

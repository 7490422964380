import React, { useEffect, useState } from 'react';

import { Col, Form, Input, Radio, Row, Select } from 'antd';

import { MESSAGE, PATTERN_NOT_ONLY_SPACE } from 'src/const';
import { FormItemMember } from 'src/pages/Member/components/config';
import { useAppSelector } from 'src/redux/hook';

import styles from './styles.module.scss';

const { Option } = Select;

interface IProps {
  hamletId?: number;
  address?: string;
}

const HandleAddress = ({ hamletId, address }: IProps) => {
  const { listHamlet } = useAppSelector((state) => state.app);
  const [atDuongLieu, setAtDuongLieu] = useState(true);
  useEffect(() => {
    setAtDuongLieu(!!hamletId || !address?.length);
  }, [hamletId, address]);

  return (
    <Row gutter={24}>
      <Col xs={24} sm={5} className={styles.radioAddress}>
        <p>Địa chỉ</p>
        <Radio.Group onChange={() => setAtDuongLieu((prev) => !prev)} value={atDuongLieu}>
          <Radio value={true}>Dương Liễu</Radio>
          <Radio value={false}>Nơi khác</Radio>
        </Radio.Group>
      </Col>
      {atDuongLieu && (
        <Col xs={24} sm={7}>
          <Form.Item name={FormItemMember.HAMLET_ID} label="Thôn" rules={[{ required: true }]}>
            <Select>
              {listHamlet.map((item) => (
                <Option key={`HAMLET_ID_${item.id}`} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Col xs={24} sm={atDuongLieu ? 12 : 19}>
        <Form.Item
          name={FormItemMember.ADDRESS}
          label="Địa chỉ chi tiết"
          rules={[{ required: !atDuongLieu }, { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace }]}
        >
          <Input type="text" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default React.memo(HandleAddress);

import React, { useMemo, useState } from 'react';

import { DatePicker, Form, Select } from 'antd';

import { SearchWhiteIcon } from 'src/assets/icons';
import { BoxSearchWrapper } from 'src/components/BoxSearchWrapper';
import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { InputAllowClear } from 'src/components/InputAllowClear';
import { DATE_TIME_FORMAT, FIRST_PAGE } from 'src/const';
import { formatDateRequestAPI, messageError } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListBookAction } from 'src/redux/slices/book.slice';
import { IParamsSearchBook } from 'src/services/book';

import { FormItemBook, ORIGIN_BOOK_CONFIG } from '../config';

const { Option } = Select;

type TSearchBookProps = {
  paramsSearch: IParamsSearchBook;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsSearchBook>>;
};
export const SearchBook = ({ paramsSearch, setParamsSearch }: TSearchBookProps) => {
  const { listAuthor } = useAppSelector((state) => state.author);
  const { listCategory } = useAppSelector((state) => state.category);
  const { listPublisher } = useAppSelector((state) => state.publisher);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

  const optionsSelectPublisher = useMemo(() => {
    return listPublisher.map((item) => ({ value: item.id, label: item.name }));
  }, [listPublisher]);
  const optionsSelectAuthor = useMemo(() => {
    return listAuthor.map((item) => ({ value: item.id, label: item.name }));
  }, [listAuthor]);

  const onFinish = async (values: any) => {
    setLoadingSearch(true);
    const fromDate = formatDateRequestAPI(values.fromDate);
    const toDate = formatDateRequestAPI(values.toDate);
    const newParams: IParamsSearchBook = {
      ...values,
      page: FIRST_PAGE,
      limit: paramsSearch.limit,
      fromDate,
      toDate,
    };
    setParamsSearch(newParams);
    const error: any = await dispatch(getListBookAction(newParams));
    if (error?.length) {
      messageError(error);
    }
    setLoadingSearch(false);
  };

  return (
    <BoxSearchWrapper title="Tìm kiếm sách">
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="search" className="w-80">
          <InputAllowClear type="text" placeholder="Mã sách, tên sách" />
        </Form.Item>
        <Form.Item name="categoryId" className="w-72">
          <Select placeholder="Thể loại" allowClear>
            {listCategory.map((item, index) => (
              <Option key={`CATEGORY_${index}`} value={item.id}>
                <div className="flex items-center">
                  <img src={item.logo} alt="the-loai-sach" className="h-10 mr-2" />
                  <p>{item.name}</p>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={FormItemBook.ORIGIN_BOOK} className="w-40">
          <Select placeholder="Nguồn gốc" allowClear>
            {ORIGIN_BOOK_CONFIG.map((item, index) => (
              <Option key={`ORIGIN_BOOK_${index}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={FormItemBook.PUBLISHER_ID} className="w-64">
          <Select
            placeholder="Nhà xuất bản"
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={optionsSelectPublisher}
          />
        </Form.Item>
        {/* <Form.Item name={FormItemBook.POSITION_ID} className="w-64">
          <Select placeholder="Vị trí để sách" allowClear>
            {listBookPosition.map((item, index) => (
              <Option key={`BOOK_POSITION_${index}`} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item name={FormItemBook.AUTHOR_ID} className="w-64">
          <Select
            placeholder="Tác giả"
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={optionsSelectAuthor}
          />
        </Form.Item>
        <Form.Item name="fromDate" className="w-44">
          <DatePicker placeholder="Nhập sách từ ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item name="toDate" className="w-44">
          <DatePicker placeholder="Đến ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item className="btn-search">
          <ButtonSecondary htmlType="submit" loading={loadingSearch}>
            <SearchWhiteIcon />
            <span>Search</span>
          </ButtonSecondary>
        </Form.Item>
      </Form>
    </BoxSearchWrapper>
  );
};

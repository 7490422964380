import httpClient from 'src/configs/http-client';
import { formDataConfig, jsonConfig } from 'src/const';
import { IList } from 'src/types';

import { ICategory } from './category.interface';

export const getListCategory = () => {
  return httpClient.get<IList<ICategory>>('/category/getList');
};
export const createCategory = (body: ICategory) => {
  return httpClient.post<ICategory>('/category/create', body, formDataConfig);
};
export const updateCategory = (body: ICategory) => {
  return httpClient.post<ICategory>('/category/update', body, formDataConfig);
};
export const deleteCategory = (id: number) => {
  return httpClient.post<void>('/category/delete', { id }, jsonConfig);
};

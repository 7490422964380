import { TableCustom } from 'src/components/TableCustom';
import { useAppSelector } from 'src/redux/hook';

export const ListRank = () => {
  const { listRank } = useAppSelector((state) => state.app);

  const tableColumns: any = [
    {
      title: 'Tên hạng',
      dataIndex: 'name',
    },
    {
      title: 'Số điểm tối thiểu',
      dataIndex: 'point',
    },
    {
      title: 'Tổng số quyển được mượn',
      dataIndex: 'maxBorrowed',
    },
    {
      title: 'Số truyện tranh được mượn',
      dataIndex: 'maxComics',
    },
    {
      title: 'Số ngày mượn tối đa',
      dataIndex: 'maxDateBorrowed',
    },
  ];

  return (
    <div className="list-rank">
      <p className="text-title mb-1">Phân hạng bạn đọc</p>
      <TableCustom
        columns={tableColumns}
        dataSource={listRank}
        scroll={{ x: 700, y: 500 }}
        rowKey={(record: any) => record.id}
      />
    </div>
  );
};

import axios from 'axios';

import { localStorageHelper } from 'src/helpers';
import { SideBarRoute } from 'src/layouts/MainLayout/main.type';
import { logout } from 'src/services/auth';

import { httpStatus } from './http-status';

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};
const httpClient = axios.create(config);

httpClient.interceptors.request.use(
  async (config) => {
    if (localStorageHelper.isLogin()) {
      config.headers['token'] = localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN!);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
httpClient.interceptors.response.use(
  async (response) => {
    if ([httpStatus.StatusUnauthorized, httpStatus.StatusForbidden].includes(response.data?.code)) {
      await logout();
      window.location.href = SideBarRoute.LOGIN;
    }
    return response.data;
  },
  async (error) => {
    if (error.response) {
      switch (error.response.status) {
        case httpStatus.StatusUnauthorized:
          await logout();
          window.location.href = SideBarRoute.LOGIN;
          return Promise.reject(error);

        case httpStatus.StatusForbidden:
          // window.location.href = '/403';
          return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export default httpClient;

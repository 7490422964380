import React from 'react';

import { Form } from 'antd';
import { useHistory } from 'react-router-dom';

import { ButtonSecondary, InputPrimary } from 'src/components';
import { InputPassword } from 'src/components/InputPassword';
import { RoleIdEnum } from 'src/const';
import { SideBarRoute } from 'src/layouts/MainLayout/main.type';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { updateRoleConfig } from 'src/redux/slices/app.slices';
import { handleLogin } from 'src/redux/slices/auth.slice';
import { setUserAuth } from 'src/redux/slices/user.slice';
import { IParamsLogin } from 'src/services/auth';
import { getProfile } from 'src/services/user';
import './styles.scss';

export const Login: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { error, loading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const onFinish = async (values: IParamsLogin) => {
    try {
      const token: any = await dispatch(handleLogin(values));
      if (token) {
        const userInfo = await getProfile();
        if (userInfo?.data) {
          dispatch(updateRoleConfig(userInfo.data.role || RoleIdEnum.USER));
          dispatch(setUserAuth(userInfo.data));
        }
        history.push(SideBarRoute.HOME);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div className="login-page flex flex-col items-center pb-16 pt-16 rounded-2xl shadow-2xl h-fit">
      <img
        className="login-logo h-16 sm:h-20 lg:h-24"
        src={process.env.PUBLIC_URL + '/primary_logo.png'}
        alt="loginLogo"
      />
      <Form form={form} onFinish={onFinish} className="login-form flex flex-col items-center">
        <Form.Item
          className="w-full"
          name="account"
          rules={[{ required: true, message: 'Please input your account!' }]}
        >
          <InputPrimary placeholder="Account" />
        </Form.Item>

        <Form.Item
          className="w-full my-2"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <InputPassword placeholder="Password" />
        </Form.Item>

        <Form.Item>
          <ButtonSecondary htmlType="submit" className="h-12 w-36 mt-5" loading={loading}>
            ĐĂNG NHẬP
          </ButtonSecondary>
        </Form.Item>

        {error && <p className="error-color">{error}</p>}
      </Form>
    </div>
  );
};

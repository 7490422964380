import React, { useState } from 'react';

import { CloudDownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import * as XLSX from 'xlsx';

import { ButtonPrimary, ButtonSecondary } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { ModalCustom } from 'src/components/ModalCustom';
import { MESSAGE, ResponseStatusEnum } from 'src/const';
import { messageError, notificationSuccessAndScrollToTop } from 'src/helpers';
import { usePermission } from 'src/hooks/usePermission';
import { useAppSelector } from 'src/redux/hook';
import {
  createRentedBook,
  exportRentedBook,
  IBodyCreateRentedBook,
  IBookForRentedBook,
  IParamsSearchRentedBook,
  IReaderForRentedBook,
} from 'src/services/rentedBook';
import { MayBe } from 'src/types';

import SelectBook from './SelectBook';
import SelectReader from './SelectReader';

import './styles.scss';

interface IProps {
  refetchData: () => void;
  paramsSearch: IParamsSearchRentedBook;
}

export const ModalCreateRentedBook = ({ refetchData, paramsSearch }: IProps) => {
  const { isAccessBook } = usePermission();
  const { pagination } = useAppSelector((state) => state.rentedBook);
  const [loading, setLoading] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);

  const [readerSelected, setReaderSelected] = useState<MayBe<IReaderForRentedBook>>(null);
  const [bookSelected, setBookSelected] = useState<IBookForRentedBook[]>([]);
  const noteRef: any = React.useRef(null);

  const closeModal = () => {
    readerSelected && setReaderSelected(null);
    bookSelected.length && setBookSelected([]);
    setOpenModalCreate(false);
  };

  const submit = async () => {
    if (!readerSelected) {
      messageError(MESSAGE.createRentedRequireReader);
      return;
    }
    if (!bookSelected.length) {
      messageError(MESSAGE.createRentedRequireBook);
      return;
    }
    setLoading(true);
    const body: IBodyCreateRentedBook = {
      readerId: readerSelected.readerId,
      listBookId: bookSelected.map((item) => item.id),
      note: noteRef.current?.resizableTextArea?.textArea?.value,
    };
    const response: any = await createRentedBook(body);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      setLoading(false);
      return;
    }
    setLoading(false);
    closeModal();
    notificationSuccessAndScrollToTop();
    refetchData();
  };

  const handleExport = (jsonData: any[]) => {
    try {
      // convert JSON to Worksheet
      const worksheet = XLSX.utils.json_to_sheet(jsonData);

      // create new Workbook
      const workbook = XLSX.utils.book_new();

      // add Worksheet into Workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // convert Workbook to binary array
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // create file Blob from binary array
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

      // download file
      const outputFilename = `Sach chua tra - export ngay ${moment().utc().format('DD-MM-YYYY')}.xlsx`;
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('ERROR EXPORT', error);
    }
  };

  const exportListRentedBook = async () => {
    setLoading(true);
    const response = await exportRentedBook({
      fromDate: paramsSearch.fromDate || '',
      toDate: paramsSearch.toDate || '',
    });
    const formatData = response.data.map((item) => {
      return {
        so_the_ban_doc: item.cardNumber || item.account,
        ten_ban_doc: item.readerName,
        ma_sach: item.bookCode,
        ten_sach: item.bookName,
        ngay_muon: moment(item.borrowedDate).utc().format('DD/MM/YYYY'),
        thoi_gian_muon: moment(item.borrowedDate).utc().format('HH:mm'),
        ghi_chu: item.note,
      };
    });
    handleExport(formatData);
    setLoading(false);
  };

  return (
    <>
      <div className="flex justify-between">
        <p className="text-title mb-1">
          Có tất cả <span className="secondary-color">{pagination?.totalItem}</span> lượt mượn sách
        </p>
        <div className="btn-create-export-rented">
          {isAccessBook && (
            <Tooltip
              placement="top"
              title="Mặc định sẽ export tất cả bản ghi chưa trả sách. Có thể chọn khoảng thời gian để export. Lưu ý: chỉ áp dụng bộ lọc ngày tháng, không áp dụng các bộ lọc tìm kiếm khác"
            >
              <ButtonPrimary onClick={exportListRentedBook} className="btn-export" loading={loading} disabled={loading}>
                <CloudDownloadOutlined /> Export
              </ButtonPrimary>
            </Tooltip>
          )}
          <ButtonSecondary id="btn-add" onClick={() => setOpenModalCreate(true)}>
            <PlusOutlined /> Cho mượn sách
          </ButtonSecondary>
        </div>
      </div>
      {openModalCreate && (
        <ModalCustom
          destroyOnClose={true}
          open={openModalCreate}
          title="Tạo lượt mượn sách"
          className="modal-create-rented-book"
          width={800}
          loading={loading}
          onCancel={closeModal}
        >
          <Row gutter={24}>
            <Col xs={24} md={10}>
              <SelectReader readerSelected={readerSelected} setReaderSelected={setReaderSelected} />
            </Col>
            <Col xs={24} md={14}>
              <SelectBook bookSelected={bookSelected} setBookSelected={setBookSelected} />
            </Col>
          </Row>
          <TextArea ref={noteRef} className="mt-8" placeholder="Ghi chú" rows={3} maxLength={500} showCount />
          <div className="flex mt-8">
            <ButtonCancel id="btn-cancel" className="w-full mr-2" onClick={closeModal}>
              Thoát
            </ButtonCancel>
            <ButtonPrimary id="btn-save" className="w-full ml-2" onClick={submit}>
              Lưu
            </ButtonPrimary>
          </div>
        </ModalCustom>
      )}
    </>
  );
};

import { useState } from 'react';

import { DropdownActionTable } from 'src/components/DropdownActionTable';
import { DropdownMenuCustom, MenuItem } from 'src/components/DropdownMenuCustom';
import { usePermission } from 'src/hooks/usePermission';

import './styles.scss';

type TProps = {
  editRecord: () => void;
  showDeleteConfirm: () => void;
};
export const ActionTable = ({ editRecord, showDeleteConfirm }: TProps) => {
  const { isAccessBook } = usePermission();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleShowDropdown = (show: boolean) => {
    setShowMenu(show);
  };

  const _dropdownMenu = (
    <DropdownMenuCustom>
      <MenuItem
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        }
        text="Sửa"
        onClick={() => {
          setShowMenu(false);
          editRecord();
        }}
      />
      {isAccessBook && (
        <MenuItem
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          }
          text="Xoá"
          className="delete-color"
          onClick={() => {
            setShowMenu(false);
            showDeleteConfirm();
          }}
        />
      )}
    </DropdownMenuCustom>
  );

  return (
    <DropdownActionTable dropdownMenu={_dropdownMenu} openMenu={showMenu} handleShowDropdown={handleShowDropdown} />
  );
};

import React from 'react';

import { DatePicker, Form, Select } from 'antd';

import { SearchWhiteIcon } from 'src/assets/icons';
import { BoxSearchWrapper } from 'src/components/BoxSearchWrapper';
import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { InputAllowClear } from 'src/components/InputAllowClear';
import { DATE_TIME_FORMAT, FIRST_PAGE, GENDER_CONFIG } from 'src/const';
import { formatDateRequestAPI, formatDateSetFieldValue, messageError } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListReaderActiveAction } from 'src/redux/slices/report.slice';
import { IParamsGetReaderActive } from 'src/services/report';

const { Option } = Select;

type TProps = {
  paramsSearch: IParamsGetReaderActive;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsGetReaderActive>>;
};
export const SearchReaderActive = ({ paramsSearch, setParamsSearch }: TProps) => {
  const { loadingReaderActive } = useAppSelector((state) => state.report);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const fromDate = formatDateRequestAPI(values.fromDate);
    const toDate = formatDateRequestAPI(values.toDate);
    const newParams: IParamsGetReaderActive = {
      ...values,
      page: FIRST_PAGE,
      limit: paramsSearch.limit,
      fromDate,
      toDate,
    };
    setParamsSearch(newParams);
    const error: any = await dispatch(getListReaderActiveAction(newParams));
    if (error?.length) {
      messageError(error);
    }
  };

  return (
    <BoxSearchWrapper title="Tìm kiếm bạn đọc mượn trả sách gần đây theo khoảng thời gian">
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          fromDate: formatDateSetFieldValue(paramsSearch.fromDate!),
          toDate: formatDateSetFieldValue(paramsSearch.toDate!),
        }}
      >
        <Form.Item name="search" className="w-56">
          <InputAllowClear type="text" placeholder="Số thẻ bạn đọc, họ tên" />
        </Form.Item>
        <Form.Item name="gender" className="w-32">
          <Select placeholder="Giới tính" allowClear>
            {GENDER_CONFIG.map((item) => (
              <Option key={`READER_GENDER_${item.value}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="fromDate" className="w-36" rules={[{ required: true }]}>
          <DatePicker placeholder="Từ ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item name="toDate" className="w-36" rules={[{ required: true }]}>
          <DatePicker placeholder="Đến ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item className="btn-search">
          <ButtonSecondary htmlType="submit" loading={loadingReaderActive}>
            <SearchWhiteIcon />
            <span>Search</span>
          </ButtonSecondary>
        </Form.Item>
      </Form>
    </BoxSearchWrapper>
  );
};

import { message, notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';
import moment, { Moment } from 'moment';

import SOUND_ERROR from 'src/assets/sound/sound_error.mp3';
import SOUND_SUCCESS from 'src/assets/sound/sound_success.mp3';
import { DATE_TIME_FORMAT, DATE_TIME_HH_MM_FORMAT, MESSAGE } from 'src/const';
import { ICategory } from 'src/services/category/category.interface';

export const delay = (millisecond = 1000) => new Promise((resolve) => setTimeout(resolve, millisecond));
export const getFullImageUrl = (path: string) => {
  if (process.env.REACT_APP_API_URL?.includes('/api') && path?.includes('/api')) {
    return `${process.env.REACT_APP_API_URL}${path.replace('/api', '')}`;
  }
  return `${process.env.REACT_APP_API_URL}${path}`;
};
export const parseListCategoryStringToJson = (dataCategoryString: string): ICategory[] => {
  if (!dataCategoryString) return [];
  return JSON.parse(dataCategoryString);
};
export const getRandomNumber = (min = 1, max = 100) => Math.round(Math.random() * (max - min) + min);

////////////////////////////
export const formatStringDate = (date: string) => {
  // vd: 2022-07-10T23:35:11.000Z
  if (!date) return '';
  return moment(date.split('T')[0]).format(DATE_TIME_FORMAT);
};
export const formatStringDateTime_hh_mm = (date: string) => {
  // vd: 2022-07-10T23:35:11.000Z
  if (!date) return '';
  return moment(date).utc().format(DATE_TIME_HH_MM_FORMAT);
};
export const formatDateRequestAPI = (date: Moment) => {
  if (!date) return '';
  return date.format('YYYY-MM-DD');
};
export const formatDateSetFieldValue = (date: string) => {
  if (!date) return '';
  return moment(date.split('T')[0]);
};
////////////////////////////

////////////////////////////
export function calculateOverdueDate(expectedReturnDate = '', returnedDate = '') {
  if (!expectedReturnDate || !returnedDate) return 0;
  const returnedDay = formatDateSetFieldValue(returnedDate) as Moment;
  const expectedReturnDay = formatDateSetFieldValue(expectedReturnDate) as Moment;
  return returnedDay.diff(expectedReturnDay, 'days');
}
////////////////////////////

////////////////////////////
export const playSoundSuccess = () => {
  // const audio = new Audio(SOUND_SUCCESS);
  // audio.play();
};
export const playSoundError = () => {
  // const audio = new Audio(SOUND_ERROR);
  // audio.play();
};
export const notificationSuccessAndScrollToTop = () => {
  notification.destroy();
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  playSoundSuccess();
  notification.success({
    message: 'Thành công!',
  });
};
export const notificationSuccess = () => {
  notification.destroy();
  playSoundSuccess();
  notification.success({
    message: 'Thành công!',
  });
};
export const notificationError = (message = MESSAGE.serverError) => {
  notification.destroy();
  playSoundError();
  notification.error({ message });
};
export const notificationWarning = (message: string, description = '', placement: NotificationPlacement = 'top') => {
  notification.warning({ message, description, placement });
};
export const notificationInfo = (message: string, description = '', placement: NotificationPlacement = 'top') => {
  notification.info({ message, description, placement });
};
export const messageError = (messageError = MESSAGE.serverError) => {
  message.destroy();
  playSoundError();
  message.error({
    content: messageError,
    className: 'custom-message custom-message-error',
  });
};
export const messageSuccess = (messageSuccess = MESSAGE.success) => {
  message.destroy();
  message.success({
    content: messageSuccess,
    className: 'custom-message',
  });
};
////////////////////////////

import React, { useEffect, useMemo, useState } from 'react';

import { Col, DatePicker, Form, Input, Row, Select } from 'antd';

import { ButtonPrimary } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { ModalCustom } from 'src/components/ModalCustom';
import {
  DATE_TIME_FORMAT,
  MESSAGE,
  PATTERN_NOT_ONLY_SPACE,
  PATTERN_NUMBER,
  ResponseStatusEnum,
  RoleIdEnum,
} from 'src/const';
import {
  formatDateRequestAPI,
  formatDateSetFieldValue,
  messageError,
  notificationSuccessAndScrollToTop,
  parseListCategoryStringToJson,
} from 'src/helpers';
import { usePermission } from 'src/hooks/usePermission';
import { useAppSelector } from 'src/redux/hook';
import { createBook, IBodyCreateUpdateBook, IBook, updateBook } from 'src/services/book';
import { MayBe } from 'src/types';

import { FormItemBook, ORIGIN_BOOK_CONFIG } from '../config';

import './styles.scss';

const { Option } = Select;
const { TextArea } = Input;

interface IProps {
  refetchData: () => void;
  onCloseModal: () => void;
  editMode: boolean;
  open: boolean;
  dataDetailBook: MayBe<IBook>;
}

export const ModalCreateUpdateBook = ({ refetchData, editMode, open, onCloseModal, dataDetailBook }: IProps) => {
  const { listAuthor } = useAppSelector((state) => state.author);
  const { listCategory } = useAppSelector((state) => state.category);
  const { listPublisher } = useAppSelector((state) => state.publisher);
  const { isAdmin, isAccessBook } = usePermission();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const optionsSelectPublisher = useMemo(() => {
    return listPublisher.map((item) => ({ value: item.id, label: item.name }));
  }, [listPublisher]);
  const optionsSelectAuthor = useMemo(() => {
    return listAuthor.map((item) => ({ value: item.id, label: item.name }));
  }, [listAuthor]);

  useEffect(() => {
    loading && setLoading(false);
    if (!dataDetailBook) {
      _resetForm();
      return;
    }
    form.setFieldsValue({
      ...dataDetailBook,
      entryDate: formatDateSetFieldValue(dataDetailBook.entryDate),
      listCategoryId: parseListCategoryStringToJson(dataDetailBook.dataCategory).map((item) => item.id),
    });
  }, [dataDetailBook]);

  const onFinish = async (values: any) => {
    if (values?.role === RoleIdEnum.ADMIN && !isAdmin) {
      messageError(MESSAGE.noPermissionError);
      return;
    }
    setLoading(true);
    const body: IBodyCreateUpdateBook = {
      ...values,
      id: dataDetailBook?.id,
      entryDate: formatDateRequestAPI(values.entryDate),
    };
    const response: any = editMode ? await updateBook(body) : await createBook(body);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      setLoading(false);
      return;
    }
    onCloseModal();
    notificationSuccessAndScrollToTop();
    refetchData();
    setLoading(false);
    _resetForm();
  };

  const _resetForm = () => {
    form.resetFields();
  };

  return (
    <ModalCustom
      open={open}
      className="modal-book"
      title={editMode ? 'Sửa thông tin sách' : 'Thêm sách'}
      loading={loading}
      width={750}
      onCancel={() => {
        onCloseModal();
        _resetForm();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          qty: 1,
        }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={14} md={14}>
            <Form.Item name={FormItemBook.LIST_CATEGORY_ID} label="Thể loại" rules={[{ required: true }]}>
              <Select
                popupClassName="modal-book-dropdown-style"
                className="modal-book-multiple-select"
                mode="multiple"
                optionLabelProp="label"
                maxTagCount="responsive"
              >
                {listCategory.map((item) => (
                  <Option key={`CATEGORY${item.id}`} value={item.id} label={item.code}>
                    <div className="flex items-center">
                      <img src={item.logo} alt="the-loai-sach" className="h-10 mr-2" />
                      <p>{item.name}</p>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={10} md={10}>
            <Form.Item
              name={FormItemBook.CODE}
              label="Mã sách"
              rules={[{ required: true }, { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace }]}
            >
              <Input maxLength={20} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name={FormItemBook.NAME}
              label="Tên sách"
              rules={[{ required: true }, { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace }]}
            >
              <Input maxLength={200} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={10}>
            <Form.Item name={FormItemBook.PUBLISHER_ID} label="Nhà xuất bản">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={optionsSelectPublisher}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={4}>
            <Form.Item
              name={FormItemBook.PUBLISH_YEAR}
              label="Năm xuất bản"
              rules={[{ pattern: PATTERN_NUMBER, message: MESSAGE.requiredNumberGte0 }]}
            >
              <Input maxLength={4} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={10}>
            <Form.Item name={FormItemBook.AUTHOR_ID} label="Tác giả">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={optionsSelectAuthor}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={7}>
            <Form.Item name={FormItemBook.ORIGIN_BOOK} label="Nguồn gốc">
              <Select>
                {ORIGIN_BOOK_CONFIG.map((item) => (
                  <Option key={`ORIGIN_BOOK_${item.value}`} value={item.value}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={7}>
            <Form.Item
              name={FormItemBook.QTY}
              label="Số lượng nhập vào"
              rules={[{ pattern: PATTERN_NUMBER, message: MESSAGE.requiredNumberGte0 }]}
            >
              <Input maxLength={6} />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={12} md={6}>
            <Form.Item
              name={FormItemBook.AVAILABLE}
              label="Số lượng có sẵn ở TV"
              hasFeedback
              rules={[
                { required: true },
                { pattern: PATTERN_NUMBER, message: MESSAGE.requiredNumberGte0 },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || Number(getFieldValue(FormItemBook.QTY)) >= Number(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(MESSAGE.qtyAvailableError));
                  },
                }),
              ]}
            >
              <Input maxLength={6} />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={12} md={10}>
            <Form.Item name={FormItemBook.ENTRY_DATE} label="Ngày nhập sách">
              <DatePicker placeholder="" format={DATE_TIME_FORMAT} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name={FormItemBook.NOTE} label="Ghi chú">
              <Input maxLength={200} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={FormItemBook.DESCRIPTION} label="Giới thiệu ngắn về sách">
          <TextArea rows={3} maxLength={500} showCount />
        </Form.Item>
        {isAccessBook && (
          <div className="flex mt-8">
            <ButtonCancel id="btn-cancel" className="w-full mr-2" onClick={onCloseModal}>
              Thoát
            </ButtonCancel>
            <ButtonPrimary id="btn-save" className="w-full ml-2" htmlType="submit">
              Lưu
            </ButtonPrimary>
          </div>
        )}
      </Form>
    </ModalCustom>
  );
};

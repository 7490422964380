import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MESSAGE, ResponseStatusEnum } from 'src/const';
import { getListAuthor, IAuthor } from 'src/services/author';

import { AppThunk } from '../store';

type AuthorState = {
  loading: boolean;
  error: string;
  listAuthor: IAuthor[];
};

const initialState: AuthorState = {
  loading: false,
  error: '',
  listAuthor: [],
};

export const slice = createSlice({
  name: 'author',
  initialState,
  reducers: {
    setLoading: (state: AuthorState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: AuthorState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setListAuthor: (state: AuthorState, action: PayloadAction<IAuthor[]>) => {
      state.listAuthor = action.payload;
    },
  },
});

export const { setLoading, setError, setListAuthor } = slice.actions;

export const getListAuthorAction = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  const response = await getListAuthor();
  if (response.status === ResponseStatusEnum.SUCCESS) {
    const { items } = response.data;
    dispatch(setListAuthor(items));
  } else {
    dispatch(setLoading(false));
    dispatch(setListAuthor([]));
    return MESSAGE.getListAuthorError;
  }
  dispatch(setLoading(false));
};

export default slice.reducer;

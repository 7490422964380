import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { ButtonPrimary, ButtonSecondary } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { ModalCustom } from 'src/components/ModalCustom';
import { MESSAGE, ResponseStatusEnum } from 'src/const';
import { messageError, notificationSuccess } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setRentedBookSelected } from 'src/redux/slices/rentedBook.slice';
import { addBookToRentedBook, IBodyAddBookToRentedBook, IBookForRentedBook } from 'src/services/rentedBook';

import SelectBook from '../ModalCreateRentedBook/SelectBook';
import { useHandleUpdateRentedBook } from '../ModalRentedBookDetail/useHandleUpdateRentedBook';

interface IProps {
  refetchData: () => void;
}

export const ModalAddBookToRentedBook = ({ refetchData }: IProps) => {
  const { rentedBookSelected } = useAppSelector((state) => state.rentedBook);
  const dispatch = useAppDispatch();
  const { countItemNotReturn } = useHandleUpdateRentedBook();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [bookSelected, setBookSelected] = useState<IBookForRentedBook[]>([]);

  const closeModal = () => {
    bookSelected.length && setBookSelected([]);
    setOpenModal(false);
  };

  const submit = async () => {
    if (!rentedBookSelected) return;
    if (countItemNotReturn < rentedBookSelected.rentedBookDetail.length) {
      messageError(MESSAGE.addBookToRentedBookError);
      closeModal();
      return;
    }
    if (!bookSelected.length) {
      messageError(MESSAGE.createRentedRequireBook);
      return;
    }
    setLoading(true);
    const body: IBodyAddBookToRentedBook = {
      rentedBookId: rentedBookSelected.id,
      readerId: rentedBookSelected.readerId,
      listBookId: bookSelected.map((item) => item.id),
    };
    const response: any = await addBookToRentedBook(body);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      setLoading(false);
      return;
    }
    dispatch(setRentedBookSelected(response.data));
    setLoading(false);
    closeModal();
    notificationSuccess();
    refetchData();
  };

  const pastDay = moment(rentedBookSelected?.borrowedDate).diff(moment(), 'days');
  if (pastDay !== 0) return null;

  return (
    <>
      <div className="flex justify-between items-center mt-8">
        <p className="italic text-gray-400">Nếu bạn đọc muốn mượn thêm sách cùng lượt mượn này. Bấm nút thêm</p>
        <ButtonSecondary className="w-24 h-10 ml-3" onClick={() => setOpenModal(true)}>
          <PlusOutlined /> Thêm
        </ButtonSecondary>
      </div>
      {!!rentedBookSelected && openModal && (
        <ModalCustom
          destroyOnClose={true}
          open={openModal}
          title="Thêm sách vào lượt mượn"
          width={500}
          loading={loading}
          onCancel={closeModal}
        >
          <SelectBook bookSelected={bookSelected} setBookSelected={setBookSelected} />
          <div className="flex mt-8">
            <ButtonCancel id="btn-cancel" className="w-full mr-2" onClick={closeModal}>
              Thoát
            </ButtonCancel>
            <ButtonPrimary id="btn-save" className="w-full ml-2" onClick={submit}>
              Lưu
            </ButtonPrimary>
          </div>
        </ModalCustom>
      )}
    </>
  );
};

import { Modal } from 'antd';

import { ButtonCancel } from 'src/components/Button/ButtonCancel';

import { ButtonPrimary } from '../Button';

import './styles.scss';

interface IProps {
  onConfirm: () => void;
  onCancel: () => void;
  loading: boolean;
  open: boolean;
  title: string;
  content?: string;
  width?: number;
}

export const ModalConfirm = ({ loading, open, title, content = '', width = 500, onCancel, onConfirm }: IProps) => {
  return (
    <Modal open={open} className="modal-confirm" width={width} onCancel={onCancel} centered>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12 primary-color animate-bounce"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={1}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        />
      </svg>
      <p>{title}</p>
      {!!content.length && <span className="content">{content}</span>}
      <div className="flex justify-end mt-10">
        <ButtonCancel id="btn-cancel" className="mr-2" onClick={onCancel}>
          Huỷ
        </ButtonCancel>
        <ButtonPrimary id="btn-save" disabled={loading} loading={loading} onClick={onConfirm}>
          OK
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

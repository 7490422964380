import React from 'react';

import { DatePicker, Form, Select } from 'antd';

import { SearchWhiteIcon } from 'src/assets/icons';
import { BoxSearchWrapper } from 'src/components/BoxSearchWrapper';
import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { InputAllowClear } from 'src/components/InputAllowClear';
import { DATE_TIME_FORMAT, FIRST_PAGE } from 'src/const';
import { formatDateRequestAPI, formatDateSetFieldValue, messageError } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListBookBorrowedAction } from 'src/redux/slices/report.slice';
import { IParamsGetBookBorrowed } from 'src/services/report';

const { Option } = Select;

type TProps = {
  paramsSearch: IParamsGetBookBorrowed;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsGetBookBorrowed>>;
};
export const SearchBookBorrowed = ({ paramsSearch, setParamsSearch }: TProps) => {
  const { listCategory } = useAppSelector((state) => state.category);
  const { loadingBookBorrowed } = useAppSelector((state) => state.report);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const fromDate = formatDateRequestAPI(values.fromDate);
    const toDate = formatDateRequestAPI(values.toDate);
    const newParams: IParamsGetBookBorrowed = {
      ...values,
      page: FIRST_PAGE,
      limit: paramsSearch.limit,
      fromDate,
      toDate,
    };
    setParamsSearch(newParams);
    const error: any = await dispatch(getListBookBorrowedAction(newParams));
    if (error?.length) {
      messageError(error);
    }
  };

  return (
    <BoxSearchWrapper title="Tìm kiếm những quyển sách được mượn gần đây theo khoảng thời gian">
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          fromDate: formatDateSetFieldValue(paramsSearch.fromDate!),
          toDate: formatDateSetFieldValue(paramsSearch.toDate!),
        }}
      >
        <Form.Item name="search" className="w-64">
          <InputAllowClear type="text" placeholder="Mã sách, tên sách" />
        </Form.Item>
        <Form.Item name="categoryId" className="w-72">
          <Select placeholder="Thể loại" allowClear>
            {listCategory.map((item) => (
              <Option key={item.id} value={item.id}>
                <div className="flex items-center">
                  <img src={item.logo} alt="the-loai-sach" className="h-10 mr-2" />
                  <p>{item.name}</p>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="fromDate" className="w-36" rules={[{ required: true }]}>
          <DatePicker placeholder="Từ ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item name="toDate" className="w-36" rules={[{ required: true }]}>
          <DatePicker placeholder="Đến ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item className="btn-search">
          <ButtonSecondary htmlType="submit" loading={loadingBookBorrowed}>
            <SearchWhiteIcon />
            <span>Search</span>
          </ButtonSecondary>
        </Form.Item>
      </Form>
    </BoxSearchWrapper>
  );
};

import { useEffect, useState } from 'react';

import { Form, Input } from 'antd';

import { ButtonPrimary } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { ModalCustom } from 'src/components/ModalCustom';
import { MESSAGE, PATTERN_NOT_ONLY_SPACE, ResponseStatusEnum } from 'src/const';
import { messageError, notificationSuccess } from 'src/helpers';
import { usePermission } from 'src/hooks/usePermission';
import { IAuthor, createAuthor, updateAuthor } from 'src/services/author';
import { MayBe } from 'src/types';

interface IProps {
  refetchData: () => void;
  onCloseModal: () => void;
  editMode: boolean;
  open: boolean;
  dataDetailAuthor: MayBe<IAuthor>;
}

export const ModalCreateUpdateAuthor = ({ refetchData, editMode, open, onCloseModal, dataDetailAuthor }: IProps) => {
  const [form] = Form.useForm();
  const { isAccessBook } = usePermission();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loading && setLoading(false);
    if (!dataDetailAuthor) {
      form.resetFields();
      return;
    }
    form.setFieldsValue({ ...dataDetailAuthor });
  }, [dataDetailAuthor]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const body: IAuthor = {
      ...values,
      id: dataDetailAuthor?.id,
    };
    const response: any = editMode ? await updateAuthor(body) : await createAuthor(body);
    setLoading(false);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      return;
    }
    onCloseModal();
    notificationSuccess();
    refetchData();
    form.resetFields();
  };

  return (
    <ModalCustom
      open={open}
      className="modal-book-position"
      title={editMode ? 'Sửa tên tác giả' : 'Thêm tác giả'}
      loading={loading}
      width={500}
      onCancel={onCloseModal}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Tên tác giả"
          rules={[{ required: true }, { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace }]}
        >
          <Input type="text" />
        </Form.Item>
        {isAccessBook && (
          <div className="flex mt-8">
            <ButtonCancel id="btn-cancel" className="w-full mr-2" onClick={onCloseModal}>
              Thoát
            </ButtonCancel>
            <ButtonPrimary id="btn-save" className="w-full ml-2" htmlType="submit">
              Lưu
            </ButtonPrimary>
          </div>
        )}
      </Form>
    </ModalCustom>
  );
};

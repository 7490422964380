import React, { useState } from 'react';

import { DatePicker, Form, Select } from 'antd';

import { SearchWhiteIcon } from 'src/assets/icons';
import { BoxSearchWrapper } from 'src/components/BoxSearchWrapper';
import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { InputAllowClear } from 'src/components/InputAllowClear';
import { ADDRESS_TYPE_CONFIG, DATE_TIME_FORMAT, FIRST_PAGE, GENDER_CONFIG } from 'src/const';
import { formatDateRequestAPI, messageError } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListReaderAction } from 'src/redux/slices/reader.slice';
import { IParamsSearchReader } from 'src/services/reader';

import { ORDER_BY_READER, READER_STATUS_CONFIG } from '../config';

const { Option } = Select;

type TSearchReaderProps = {
  paramsSearch: IParamsSearchReader;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsSearchReader>>;
};
export const SearchReader = ({ paramsSearch, setParamsSearch }: TSearchReaderProps) => {
  const { listHamlet, listRank } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoadingSearch(true);
    const fromDate = formatDateRequestAPI(values.fromDate);
    const toDate = formatDateRequestAPI(values.toDate);
    const fromDob = formatDateRequestAPI(values.fromDob);
    const toDob = formatDateRequestAPI(values.toDob);
    const newParams: IParamsSearchReader = {
      ...values,
      page: FIRST_PAGE,
      limit: paramsSearch.limit,
      fromDate,
      toDate,
      fromDob,
      toDob,
    };
    setParamsSearch(newParams);
    const error: any = await dispatch(getListReaderAction(newParams));
    if (error?.length) {
      messageError(error);
    }
    setLoadingSearch(false);
  };

  return (
    <BoxSearchWrapper title="Tìm kiếm bạn đọc">
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="search" className="w-72">
          <InputAllowClear type="text" placeholder="Số thẻ bạn đọc, họ tên, địa chỉ" />
        </Form.Item>
        <Form.Item name="status" className="w-36">
          <Select placeholder="Trạng thái" allowClear>
            {READER_STATUS_CONFIG.map((item) => (
              <Option key={`READER_STATUS_${item.value}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="gender" className="w-36">
          <Select placeholder="Giới tính" allowClear>
            {GENDER_CONFIG.map((item) => (
              <Option key={`READER_GENDER_${item.value}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="rankId" className="w-36">
          <Select placeholder="Phân hạng" allowClear>
            {listRank.map((item) => (
              <Select.Option key={`RANK_ID_${item.id}`} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="addressType" className="w-36">
          <Select placeholder="Địa chỉ" allowClear>
            {ADDRESS_TYPE_CONFIG.map((item) => (
              <Option key={`ADDRESS_TYPE_${item.value}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="hamletId" className="w-36">
          <Select placeholder="Thôn" allowClear>
            {listHamlet.map((item) => (
              <Option key={`HAMLET_${item.id}`} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="orderBy" className="w-56">
          <Select placeholder="Sắp xếp thứ tự" allowClear>
            {ORDER_BY_READER.map((item, index) => (
              <Option key={`READER_ORDER_BY_${index}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="fromDob" className="w-44">
          <DatePicker placeholder="Sinh nhật từ ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item name="toDob" className="w-44">
          <DatePicker placeholder="Đến ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item name="fromDate" className="w-44">
          <DatePicker placeholder="Được tạo từ ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item name="toDate" className="w-44">
          <DatePicker placeholder="Đến ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item className="btn-search">
          <ButtonSecondary htmlType="submit" loading={loadingSearch}>
            <SearchWhiteIcon />
            <span>Search</span>
          </ButtonSecondary>
        </Form.Item>
      </Form>
    </BoxSearchWrapper>
  );
};

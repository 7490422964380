import React from 'react';

import { Form } from 'antd';
import moment from 'moment';

import { ResponseStatusEnum } from 'src/const';
import { formatDateRequestAPI, formatDateSetFieldValue, messageError } from 'src/helpers/fileHelper';
import { getTopBorrowedBook, IParamsGetTopBook, ITopBook } from 'src/services/report';

import { ReportListTop, TOP_OPTIONS } from '../ReportListTop';

const INIT_PARAMS = {
  limit: TOP_OPTIONS[0],
  fromDate: formatDateRequestAPI(moment().startOf('month')),
  toDate: formatDateRequestAPI(moment().endOf('day')),
};
export const TopBook: React.FC = () => {
  const [form] = Form.useForm();
  const [topBooks, setTopBooks] = React.useState<ITopBook[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getTopBook(INIT_PARAMS);
  }, []);

  async function getTopBook(params: IParamsGetTopBook) {
    const response: any = await getTopBorrowedBook(params);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
    } else {
      setTopBooks(response.data || []);
    }
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    const fromDate = formatDateRequestAPI(values.fromDate);
    const toDate = formatDateRequestAPI(values.toDate);
    const params: IParamsGetTopBook = {
      limit: values.limit,
      fromDate,
      toDate,
    };
    await getTopBook(params);
    setLoading(false);
  };

  return (
    <ReportListTop
      form={form}
      onFinish={onFinish}
      loading={loading}
      titleBoxSearch="TOP SÁCH được mượn nhiều nhất"
      initialValuesForm={{
        limit: INIT_PARAMS.limit,
        fromDate: formatDateSetFieldValue(INIT_PARAMS.fromDate),
        toDate: formatDateSetFieldValue(INIT_PARAMS.toDate),
      }}
    >
      <table>
        <thead>
          <tr>
            <th>TOP</th>
            <th>TÊN SÁCH</th>
            <th>MÃ SÁCH</th>
            <th>TÁC GIẢ</th>
            <th>LƯỢT MƯỢN</th>
          </tr>
        </thead>
        <tbody>
          {topBooks.map((reader, index) => (
            <tr key={reader.bookId}>
              <td>{index + 1}</td>
              <td>{reader.bookName}</td>
              <td>{reader.bookCode}</td>
              <td>{reader.author}</td>
              <td>{reader.totalBorrowed}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ReportListTop>
  );
};

import React from 'react';

import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { validateMessages } from './configs/message-error';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './index.css';

ReactDOM.render(
  <ConfigProvider form={{ validateMessages }}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root'),
);

reportWebVitals();

import React, { useState } from 'react';

import { initParamsSearch } from 'src/const';
import { IParamsSearchUser } from 'src/services/user';

import Card from '../../components/Card';

import { SearchMember, ListMember } from './components';

import './styles.scss';

export const Member: React.FC = () => {
  const [paramsSearch, setParamsSearch] = useState<IParamsSearchUser>(initParamsSearch);
  return (
    <div className="member-page">
      <Card>
        <SearchMember paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      </Card>
      <Card className="mt-10">
        <ListMember paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      </Card>
    </div>
  );
};

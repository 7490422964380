import httpClient from 'src/configs/http-client';
import { jsonConfig } from 'src/const';
import { localStorageHelper } from 'src/helpers';

import { IResponseLogin, IParamsLogin, IParamsChangePassword, IConfigData } from './auth.interface';

export const login = async (params: IParamsLogin) => {
  return httpClient.post<IResponseLogin>(`/home/login`, params, jsonConfig);
};

export const logout = async () => {
  try {
    localStorageHelper.removeItem(process.env.REACT_APP_ACCESS_TOKEN!);
    localStorageHelper.removeItem(process.env.REACT_APP_REFRESH_TOKEN!);
    localStorage.clear();
  } catch (error: any) {
    console.log(error);
  }
};

export const changePassword = async (params: IParamsChangePassword) => {
  return httpClient.post<any>(`/home/changePassword`, params, jsonConfig);
};

export const getConfigData = async () => {
  return httpClient.get<IConfigData>(`/home/getConfigData`);
};

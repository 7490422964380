import React from 'react';

import { Col, Row } from 'antd';

import Card from '../../components/Card';

import { ListAuthor, ListBookPosition, ListPublisher, ListRank } from './components';

import './styles.scss';

export const Setting: React.FC = () => {
  return (
    <div className="setting-page">
      <Card>
        <ListRank />
      </Card>
      <Row gutter={24}>
        {/* <Col lg={24} xl={24} xxl={8}>
          <Card>
            <ListBookPosition />
          </Card>
        </Col> */}
        <Col xl={24} xxl={12}>
          <Card>
            <ListPublisher />
          </Card>
        </Col>
        <Col xl={24} xxl={12}>
          <Card>
            <ListAuthor />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

import { useEffect, useState } from 'react';

import { Col, Form, Input, Radio, Row, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import clsx from 'clsx';

import { ButtonPrimary } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { ModalCustom } from 'src/components/ModalCustom';
import { CommonStatusEnum, MESSAGE, PATTERN_NOT_ONLY_SPACE, PATTERN_NUMBER, ResponseStatusEnum } from 'src/const';
import { messageError } from 'src/helpers';
import { usePermission } from 'src/hooks/usePermission';
import { createCategory, ICategory, updateCategory } from 'src/services/category';
import { MayBe } from 'src/types';

import './styles.scss';

interface IProps {
  refetchData: () => void;
  onCloseModal: () => void;
  editMode: boolean;
  open: boolean;
  dataDetailCategory: MayBe<ICategory>;
}

export const ModalCreateUpdateCategory = ({
  refetchData,
  editMode,
  open,
  onCloseModal,
  dataDetailCategory,
}: IProps) => {
  const [form] = Form.useForm();
  const { isAccessBook } = usePermission();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState<any>();

  useEffect(() => {
    loading && setLoading(false);
    if (!dataDetailCategory) {
      _resetForm();
      return;
    }
    form.setFieldsValue({ ...dataDetailCategory });
    dataDetailCategory?.logo && setImageUrl(dataDetailCategory.logo);
  }, [dataDetailCategory]);

  const _resetForm = () => {
    imageUrl && setImageUrl('');
    imageFile && setImageFile(null);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    if (!editMode && !imageFile) {
      messageError(MESSAGE.createCategoryRequireLogo);
      return;
    }
    setLoading(true);
    const body: ICategory = {
      ...values,
      id: dataDetailCategory?.id,
      description: values.description || '',
    };
    if (imageFile) {
      body.logo = imageFile;
    }
    const response: any = editMode ? await updateCategory(body) : await createCategory(body);
    setLoading(false);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      return;
    }
    window.location.reload();
  };

  const _handleRemoveFile = (e: any) => {
    setImageFile(null);
    setImageUrl(dataDetailCategory?.logo || '');
  };

  return (
    <ModalCustom
      open={open}
      className="modal-book-category"
      title={editMode ? 'Sửa thông tin thể loại sách' : 'Thêm thể loại sách'}
      loading={loading}
      width={550}
      onCancel={() => {
        onCloseModal();
        _resetForm();
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} sm={13}>
            <Form.Item
              name="name"
              label="Tên thể loại sách"
              rules={[{ required: true }, { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace }]}
            >
              <Input maxLength={100} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item
              name="code"
              label="Mã thể loại"
              rules={[{ required: true }, { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace }]}
            >
              <Input maxLength={10} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={5}>
            <Form.Item
              name="point"
              label="Số điểm"
              rules={[{ required: true }, { pattern: PATTERN_NUMBER, message: MESSAGE.requiredNumberGte0 }]}
            >
              <Input maxLength={2} className="text-center" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={13}>
            <Form.Item name="canBorrow" label="Cho phép mượn về nhà hay không?" rules={[{ required: true }]}>
              <Radio.Group>
                <Radio value={CommonStatusEnum.ACTIVE}>Có</Radio>
                <Radio value={CommonStatusEnum.INACTIVE}>Không</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={9}>
            <Form.Item label="Logo thể loại" className="item-logo">
              <Upload
                listType="picture-card"
                className={clsx('category-logo', imageUrl ? 'category-logo-selected' : 'require-upload-logo')}
                showUploadList={false}
                accept="image/*"
                beforeUpload={(file: any) => {
                  setImageFile(file);
                  const reader = new FileReader();
                  reader.onload = (r: any) => {
                    setImageUrl(r.target.result);
                  };
                  reader.readAsDataURL(file);
                  return false;
                }}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="logo" className="object-cover w-full h-full rounded-md" />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    />
                  </svg>
                )}
              </Upload>
              {imageFile && (
                <div
                  onClick={_handleRemoveFile}
                  className="remove-file h-8 w-8 p-2 rounded-full flex items-center justify-center absolute bg-gray-800 bg-opacity-60 duration-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-200"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="description" label="Mô tả về thể loại">
          <TextArea rows={3} maxLength={500} showCount />
        </Form.Item>
        {isAccessBook && (
          <div className="flex mt-8">
            <ButtonCancel id="btn-cancel" className="w-full mr-2" onClick={onCloseModal}>
              Thoát
            </ButtonCancel>
            <ButtonPrimary id="btn-save" className="w-full ml-2" htmlType="submit">
              Lưu
            </ButtonPrimary>
          </div>
        )}
      </Form>
    </ModalCustom>
  );
};

import React, { useState } from 'react';

import { Layout, Menu } from 'antd';
import clsx from 'clsx';
import { useLocation, Link, useHistory } from 'react-router-dom';

import { ExpandArrow } from 'src/assets/icons';
import './styles.scss';

import { SideBarMenu, SideBarRoute } from '../main.type';

const { SubMenu } = Menu;

export const SideBar: React.FC = () => {
  const { Sider } = Layout;
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div className={clsx('select-none sidebar-container', !collapsed && 'sidebar-container_expand')}>
      <Sider width={270} breakpoint="lg" collapsed={collapsed} collapsible>
        {collapsed && (
          <div className="menu-top-logo">
            <img
              onClick={() => history.push(SideBarRoute.HOME)}
              className={clsx('menu-logo cursor-pointer', { isCollapsed: collapsed })}
              src={process.env.PUBLIC_URL + '/white_logo.png'}
              alt={'logoTVDL'}
            />
          </div>
        )}
        <div
          className={clsx('btn', {
            'btn-collapse': collapsed,
          })}
        >
          <div className="sidebar-logo">
            <div className="handle-collapse-icon" onClick={onCollapse}>
              <ExpandArrow className={clsx('expand', { isCollapsed: collapsed })} />
            </div>
            <img
              onClick={() => history.push(SideBarRoute.HOME)}
              className={clsx('menu-logo', { isCollapsed: collapsed })}
              src={process.env.PUBLIC_URL + '/white_logo.png'}
              alt={'logoTVDL'}
            />
          </div>
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]} className={clsx(collapsed && 'collapsed')}>
          {SideBarMenu.map((item: any) => {
            if (item.children) {
              return (
                <SubMenu
                  className={clsx(collapsed ? 'collapsed' : 'expanded')}
                  key={item.link}
                  icon={<item.icon />}
                  title={item.name}
                >
                  {item.children.map((subItem: any) => (
                    <Menu.Item key={subItem.link}>
                      <Link to={subItem.link}>{subItem.name}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            }
            return (
              <Menu.Item
                className={clsx(
                  collapsed ? 'collapsed' : 'expanded',
                  [
                    SideBarRoute.HOME,
                    SideBarRoute.BOOK,
                    SideBarRoute.CATEGORY,
                    SideBarRoute.MEMBER,
                    SideBarRoute.SETTING,
                  ].includes(item.link) && 'sidebar-other-icon',
                )}
                key={item.link}
                icon={<item.icon />}
              >
                <Link to={item.link}>{item.name}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
        <div
          className={clsx('wetech-menu', {
            'wetech-menu-hide-sidebar': collapsed,
          })}
        >
          <img className="wetech-logo" src={process.env.PUBLIC_URL + '/wetech_logo_white.png'} alt="wetech-logo" />
          <span
            className={clsx({
              hidden: collapsed,
            })}
          >
            © Duonglieu Library x WeTech 2024
          </span>
        </div>
      </Sider>
    </div>
  );
};

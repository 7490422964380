import React from 'react';

import { Table } from 'antd';
import './styles.scss';

export const TableCustom = (props: any) => {
  return (
    <div className="custom-table-antd">
      <Table rowClassName={(record, index) => (index % 2 === 0 ? 'even' : 'odd')} {...props} />
    </div>
  );
};

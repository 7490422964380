import { AddressTypeEnum, CommonStatusEnum, GenderEnum, RoleIdEnum } from 'src/const';
import { IsLeaderEnum } from 'src/pages/Member/components/config';

export interface IUser {
  id: number;
  account: string;
  name: string;
  phone: string;
  email: string;
  hamletId?: number;
  address: string;
  dob: string;
  gender: GenderEnum;
  title?: string;
  note?: string;
  status: number;
  joinedDate?: string;
  role: RoleIdEnum;
  isLeader: IsLeaderEnum;
  createdMemberAccount: string;
  createdMemberName: string;
}
export interface IParamsSearchUser {
  page: number;
  limit: number;
  fromDate?: string;
  toDate?: string;
  search?: string;
  status?: CommonStatusEnum;
  gender?: GenderEnum;
  dobMonth?: number;
  orderBy?: OrderByMemberEnum;
  addressType?: AddressTypeEnum;
  hamletId?: number;
}
export enum OrderByMemberEnum {
  ID_ASC = 1,
  ID_DESC = 2,
  DOB_ASC = 3,
  DOB_DESC = 4,
  MONTH_ASC = 5,
  MONTH_DESC = 6,
}
export interface IBodyCreateUpdateMember extends IUser {}

import React from 'react';

import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { SideBarRoute } from 'src/layouts/MainLayout/main.type';

export const Page404 = () => {
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button onClick={() => history.push(SideBarRoute.HOME)} size="large" type="primary">
          Back Home
        </Button>
      }
    />
  );
};

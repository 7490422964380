import React, { useEffect, useState } from 'react';

import { Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';

import { ButtonPrimary } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import HandleAddress from 'src/components/HandleAddress';
import { ModalCustom } from 'src/components/ModalCustom';
import {
  CommonStatusEnum,
  DATE_TIME_FORMAT,
  GenderEnum,
  GENDER_CONFIG,
  MESSAGE,
  PATTERN_NOT_ONLY_SPACE,
  PATTERN_PHONE,
  ResponseStatusEnum,
  RoleIdEnum,
  STATUS_CONFIG,
} from 'src/const';
import {
  formatDateRequestAPI,
  formatDateSetFieldValue,
  messageError,
  notificationSuccessAndScrollToTop,
} from 'src/helpers';
import { usePermission } from 'src/hooks/usePermission';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { updateRoleConfig } from 'src/redux/slices/app.slices';
import { setUserAuth } from 'src/redux/slices/user.slice';
import { createMember, IBodyCreateUpdateMember, IUser, updateMember } from 'src/services/user';
import { MayBe } from 'src/types';

import { FormItemMember, IS_LEADER_CONFIG, ROLE_CONFIG } from '../config';

import './styles.scss';

const { Option } = Select;

interface IProps {
  refetchData: () => void;
  onCloseModal: () => void;
  editMode: boolean;
  open: boolean;
  dataDetailMember: MayBe<IUser>;
}

export const ModalCreateUpdateMember = ({ refetchData, editMode, open, onCloseModal, dataDetailMember }: IProps) => {
  const dispatch = useAppDispatch();
  const { userAuth } = useAppSelector((state) => state.user);
  const { isAdmin } = usePermission();
  const { isAccessMember } = usePermission();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const watchRole = Form.useWatch(FormItemMember.ROLE, form);
  const watchIsAutoAccount = Form.useWatch('isAutoAccount', form);

  useEffect(() => {
    loading && setLoading(false);
    if (!dataDetailMember) {
      form.resetFields();
      return;
    }
    form.setFieldsValue({
      ...dataDetailMember,
      dob: formatDateSetFieldValue(dataDetailMember.dob),
      joinedDate: formatDateSetFieldValue(dataDetailMember.joinedDate || ''),
    });
  }, [dataDetailMember]);

  const onFinish = async (values: any) => {
    if (values?.role === RoleIdEnum.ADMIN && !isAdmin) {
      messageError(MESSAGE.noPermissionError);
      return;
    }
    setLoading(true);
    const body: IBodyCreateUpdateMember = {
      ...values,
      id: dataDetailMember?.id,
      account: values.account?.toUpperCase(),
      dob: formatDateRequestAPI(values.dob),
      joinedDate: formatDateRequestAPI(values.joinedDate),
    };
    const response: any = editMode ? await updateMember(body) : await createMember(body);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      setLoading(false);
      return;
    }
    onCloseModal();
    notificationSuccessAndScrollToTop();
    refetchData();
    if (editMode && userAuth && dataDetailMember?.id === userAuth.id) {
      userAuth.role !== body.role && dispatch(updateRoleConfig(body.role));
      dispatch(setUserAuth({ ...body }));
    }
    setLoading(false);
    form.resetFields();
  };

  return (
    <ModalCustom
      open={open}
      className="modal-member"
      title={editMode ? 'Sửa thông tin TNV' : 'Thêm TNV'}
      loading={loading}
      width={650}
      onCancel={onCloseModal}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          gender: GenderEnum.FEMALE,
          status: CommonStatusEnum.ACTIVE,
          role: RoleIdEnum.USER,
          isAutoAccount: true,
        }}
      >
        {!editMode && (
          <Form.Item name="isAutoAccount">
            <Radio.Group>
              <Radio value={true}>Mã TNV tự động tăng dần</Radio>
              <Radio value={false}>Mã TNV nhập tay</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {(editMode || !watchIsAutoAccount) && (
          <Form.Item
            name={FormItemMember.ACCOUNT}
            label="Mã TNV"
            rules={[
              { required: true, max: 6 },
              { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        )}
        <Row gutter={24}>
          <Col xs={24} sm={17}>
            <Form.Item name={FormItemMember.ROLE} label="Vai trò" rules={[{ required: true }]}>
              <Select>
                {ROLE_CONFIG.map((items) => (
                  <Option key={`MEMBER_ROLE_${items.value}`} value={items.value}>
                    {items.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {![RoleIdEnum.ADMIN, RoleIdEnum.USER].includes(watchRole) && (
            <Col xs={24} sm={7}>
              <Form.Item name={FormItemMember.IS_LEADER} rules={[{ required: true }]} className="pt-7">
                <Radio.Group>
                  {IS_LEADER_CONFIG.map((item) => (
                    <Radio key={`IS_LEADER_${item.value}`} value={item.value}>
                      {item.text}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={FormItemMember.NAME}
              label="Họ tên"
              rules={[{ required: true }, { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace }]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={FormItemMember.DOB} label="Ngày sinh">
              <DatePicker placeholder="" format={DATE_TIME_FORMAT} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={FormItemMember.PHONE}
              label="SĐT"
              rules={[
                // { required: true },
                {
                  pattern: PATTERN_PHONE,
                  message: 'Vui lòng nhập đúng định dạng SĐT',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={FormItemMember.EMAIL} label="Email">
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <HandleAddress hamletId={dataDetailMember?.hamletId} address={dataDetailMember?.address} />
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item name={FormItemMember.GENDER} label="Giới tính">
              <Radio.Group>
                {GENDER_CONFIG.map((items) => (
                  <Radio key={`MEMBER_GENDER_${items.value}`} value={items.value}>
                    {items.text}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={FormItemMember.STATUS} label="Trạng thái">
              <Radio.Group>
                {STATUS_CONFIG.map((items) => (
                  <Radio key={`MEMBER_STATUS_${items.value}`} value={items.value}>
                    {items.text}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={FormItemMember.NOTE} label="Ghi chú">
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={FormItemMember.JOINED_DATE} label="Ngày tham gia">
              <DatePicker placeholder="" format={DATE_TIME_FORMAT} />
            </Form.Item>
          </Col>
        </Row>

        {isAccessMember && (
          <div className="flex mt-8">
            <ButtonCancel id="btn-cancel" className="w-full mr-2" onClick={onCloseModal}>
              Thoát
            </ButtonCancel>
            <ButtonPrimary
              disabled={dataDetailMember?.role === RoleIdEnum.ADMIN && !isAdmin}
              id="btn-save"
              className="w-full ml-2"
              htmlType="submit"
            >
              Lưu
            </ButtonPrimary>
          </div>
        )}
      </Form>
    </ModalCustom>
  );
};

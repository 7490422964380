import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RoleIdEnum } from 'src/const';
import { IHamlet, IRank } from 'src/services/auth';

import { RootState } from '../store';

export interface AppState {
  roleConfig: RoleIdEnum;
  loadingAppState: boolean;
  listHamlet: IHamlet[];
  listRank: IRank[];
}

const initialState: AppState = {
  roleConfig: RoleIdEnum.ADMIN,
  loadingAppState: true,
  listHamlet: [],
  listRank: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateRoleConfig: (state: AppState, action: PayloadAction<number>) => {
      state.roleConfig = action.payload;
    },
    setLoadingAppState: (state: AppState, action: PayloadAction<boolean>) => {
      state.loadingAppState = action.payload;
    },
    setListHamlet: (state: AppState, action: PayloadAction<IHamlet[]>) => {
      state.listHamlet = action.payload;
    },
    setListRank: (state: AppState, action: PayloadAction<IRank[]>) => {
      state.listRank = action.payload;
    },
  },
});

export const { updateRoleConfig, setLoadingAppState, setListHamlet, setListRank } = appSlice.actions;

export const roleConfig = (state: RootState) => state.app.roleConfig;

export default appSlice.reducer;

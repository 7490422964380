import { RoleIdEnum } from 'src/const';
import { useAppSelector } from 'src/redux/hook';
import { roleConfig } from 'src/redux/slices/app.slices';

export const usePermission = () => {
  const appRoleConfig = useAppSelector(roleConfig);
  return {
    isAdmin: RoleIdEnum.ADMIN === appRoleConfig,
    isAccessMember: [RoleIdEnum.ADMIN, RoleIdEnum.HR].includes(appRoleConfig),
    isAccessBook: [RoleIdEnum.ADMIN, RoleIdEnum.BOOK_AND_READER].includes(appRoleConfig),
  };
};

import { AddressTypeEnum, CommonStatusEnum, GenderEnum } from 'src/const';

export interface IReader {
  id: number;
  cardNumber: string;
  name: string;
  rankId: number;
  point: number;
  hamletId?: number;
  address: string;
  dob: string;
  parentPhone: string;
  parentName: string;
  gender: GenderEnum;
  status: CommonStatusEnum;
  lockToDate: string;
  lastActiveDate: string;
  lost: number;
  totalRented: number;
  totalBorrowed: number;
  totalOverdueBook: number;
  totalOverdueDate: number;
  createdDate: string;
  note?: string;
}
export interface IParamsSearchReader {
  page: number;
  limit: number;
  fromDate?: string;
  toDate?: string;
  fromDob?: string;
  toDob?: string;
  search?: string;
  status?: CommonStatusEnum;
  gender?: GenderEnum;
  addressType?: AddressTypeEnum;
  hamletId?: number;
  rankId?: number;
}
export enum OrderByReaderEnum {
  CARD_NUMBER_ASC = 1,
  CARD_NUMBER_DESC = 2,
  DOB_ASC = 3,
  DOB_DESC = 4,
  LOST_DESC = 5,
}
export interface IBodyCreateUpdateReader extends IReader {}
export interface IBodyCheckExistReader {
  name: string;
  dob: string;
  parentPhone: string;
}

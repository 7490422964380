import { useCallback, useEffect, useState } from 'react';

import { ResponseStatusEnum } from 'src/const';
import { localStorageHelper } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setListHamlet, setListRank, setLoadingAppState, updateRoleConfig } from 'src/redux/slices/app.slices';
import { setListAuthor } from 'src/redux/slices/author.slice';
import { setListBookPosition } from 'src/redux/slices/bookPosition.slice';
import { setListCategory } from 'src/redux/slices/category.slice';
import { setListPublisher } from 'src/redux/slices/publisher.slice';
import { setUserAuth } from 'src/redux/slices/user.slice';
import { getConfigData, logout } from 'src/services/auth';
import { getProfile } from 'src/services/user/user.service';

export const useAppData = () => {
  const [loading, setLoading] = useState(true);
  const { userAuth } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const getAppData = useCallback(async () => {
    setLoading(true);
    dispatch(setLoadingAppState(true));
    try {
      const userInfo = localStorageHelper.isLogin() ? await getProfile() : null;
      if (userInfo?.data) {
        dispatch(updateRoleConfig(userInfo.data.role));
        dispatch(setUserAuth(userInfo.data));
      } else {
        logout();
      }
    } catch (error) {
      logout();
    }
    setLoading(false);
    dispatch(setLoadingAppState(false));
  }, [dispatch]);

  useEffect(() => {
    getAppData();
  }, [getAppData]);

  useEffect(() => {
    (async () => {
      if (!userAuth) return;
      // get all config/init data
      const response = await getConfigData();
      if (response.status === ResponseStatusEnum.SUCCESS) {
        const { listRank, listHamlet, listPublisher, listAuthor, listCategory, listBookPosition } = response.data;
        dispatch(setListRank(listRank));
        dispatch(setListHamlet(listHamlet));
        dispatch(setListPublisher(listPublisher));
        dispatch(setListAuthor(listAuthor));
        dispatch(setListCategory(listCategory));
        dispatch(setListBookPosition(listBookPosition));
      }
    })();
  }, [userAuth]);

  return { loading };
};

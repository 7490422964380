import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './loading.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export const LoadingComponent: React.FC = () => {
  return (
    <div className="loader">
      <Spin indicator={antIcon} />
    </div>
  );
};

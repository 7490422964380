import React from 'react';

import { Modal, Spin } from 'antd';
import clsx from 'clsx';

import './styles.scss';
import { CloseIcon } from 'src/assets/icons';

export const ModalCustom: React.FC<any> = ({ children, ...rest }) => {
  const className = clsx('modal-custom', rest.className && rest.className);
  return (
    <Modal {...rest} className={className} closable={false} centered forceRender destroyOnClose>
      <Spin spinning={!!rest?.loading}>
        <div className="modal-custom-header">
          <p>{rest?.title}</p>
          <CloseIcon className="close-icon" onClick={rest?.onCancel} />
        </div>
        <div className="modal-custom-body">{children}</div>
      </Spin>
    </Modal>
  );
};

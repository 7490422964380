import {
  AnalyticsIcon,
  BookIcon,
  // MemberIcon,
  ReaderIcon,
  CategoryIcon,
  SettingIcon,
  RentedBookIcon,
  UsersIcon,
} from 'src/assets/icons';

export enum SideBarMenuName {
  HOME = 'Mượn trả',
  MEMBER = 'TNV',
  READER = 'Bạn đọc',
  BOOK = 'Sách',
  CATEGORY = 'Thể loại sách',
  RENTED_BOOK = 'Mượn trả',
  SETTING = 'Cài đặt',

  REPORT = 'Thống kê',
  REPORT_TOP = 'Thống kê TOP',
  REPORT_RENTED = 'Thống kê mượn trả',
}
export enum SideBarRoute {
  HOME = '/',
  LOGIN = '/dang-nhap',
  MEMBER = '/tnv',
  READER = '/ban-doc',
  BOOK = '/sach',
  CATEGORY = '/the-loai-sach',
  RENTED_BOOK = '/muon-tra',
  SETTING = '/cai-dat',

  REPORT = '/thong-ke',
  REPORT_TOP = '/thong-ke/top',
  REPORT_RENTED = '/thong-ke/muon-tra',
}

export const SideBarMenu = [
  {
    name: SideBarMenuName.HOME,
    icon: RentedBookIcon,
    link: SideBarRoute.HOME,
  },
  {
    name: SideBarMenuName.MEMBER,
    icon: UsersIcon,
    // icon: MemberIcon,
    link: SideBarRoute.MEMBER,
  },
  {
    name: SideBarMenuName.READER,
    icon: ReaderIcon,
    link: SideBarRoute.READER,
  },
  {
    name: SideBarMenuName.BOOK,
    icon: BookIcon,
    link: SideBarRoute.BOOK,
  },
  {
    name: SideBarMenuName.CATEGORY,
    icon: CategoryIcon,
    link: SideBarRoute.CATEGORY,
  },
  // {
  //   name: SideBarMenuName.RENTED_BOOK,
  //   icon: RentedBookIcon,
  //   link: SideBarRoute.RENTED_BOOK,
  // },
  {
    name: SideBarMenuName.SETTING,
    icon: SettingIcon,
    link: SideBarRoute.SETTING,
  },
  {
    name: SideBarMenuName.REPORT,
    icon: AnalyticsIcon,
    link: SideBarRoute.REPORT,
    children: [
      {
        name: SideBarMenuName.REPORT_TOP,
        link: SideBarRoute.REPORT_TOP,
      },
      {
        name: SideBarMenuName.REPORT_RENTED,
        link: SideBarRoute.REPORT_RENTED,
      },
    ],
  },
];

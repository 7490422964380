import React from 'react';

import { Input, InputProps } from 'antd';
import clsx from 'clsx';
import './styles.scss';

export const InputPrimary: React.FC<InputProps> = ({ ...rest }) => {
  const classNameInputPrimary = clsx('input-primary', rest.className && rest.className);
  return <Input {...rest} className={classNameInputPrimary} id="input-primary" />;
};

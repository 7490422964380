import React, { Suspense } from 'react';

import { LoadingComponent } from './components/Loading/index';
import { useAppData } from './hooks/useAppData';
import { AppRoutes } from './routes';
import './styles/app.scss';

function App() {
  useAppData();
  return (
    <Suspense fallback={<LoadingComponent />}>
      <AppRoutes />
    </Suspense>
  );
}

export default App;

import React, { useState } from 'react';

import { Select } from 'antd';
import debounce from 'lodash/debounce';

import { SelectPrimary } from 'src/components';
import { getReaderForRented, IReaderForRentedBook } from 'src/services/rentedBook';
import { MayBe } from 'src/types';

type Props = {
  readerSelected: MayBe<IReaderForRentedBook>;
  setReaderSelected: React.Dispatch<React.SetStateAction<MayBe<IReaderForRentedBook>>>;
};
const SelectReader = ({ readerSelected, setReaderSelected }: Props) => {
  const [loading, setLoading] = useState(false);
  const [dataOptions, setDataOptions] = useState<IReaderForRentedBook[]>([]);

  const fetchReader = async (search: string) => {
    if (
      !search?.trim() ||
      [readerSelected?.cardNumber?.toString(), readerSelected?.account].includes(search?.trim()?.toUpperCase())
    ) {
      dataOptions.length && setDataOptions([]);
      return;
    }
    setLoading(true);
    const response: any = await getReaderForRented(search.trim());
    setDataOptions(response.data ? [response.data] : []);
    setLoading(false);
  };

  const onSelect = (value: any) => {
    setReaderSelected(dataOptions[0]);
    setDataOptions([]);
  };
  return (
    <div className="w-full mr-5">
      <p>Chọn bạn đọc: Nhập số thẻ bạn đọc</p>
      <SelectPrimary
        className="w-full"
        loading={loading}
        showSearch
        filterOption={false}
        onChange={onSelect}
        onSearch={debounce(fetchReader, 500)}
      >
        {dataOptions.map((item) => (
          <Select.Option value={Date.now()} key={item.readerId}>
            {[item.cardNumber || item.account, item.name].join(' - ')}
          </Select.Option>
        ))}
      </SelectPrimary>
      {readerSelected && (
        <div className="mt-3">
          <p>
            Số thẻ:{' '}
            <span className="font-semi-bold primary-color">{readerSelected.cardNumber || readerSelected.account}</span>
          </p>
          <p>
            Họ tên: <span className="font-semi-bold primary-color">{readerSelected.name}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default React.memo(SelectReader);

import React from 'react';

import { Select } from 'antd';
import { Line } from 'react-chartjs-2';

import { ResponseStatusEnum } from 'src/const';
import { getRandomNumber, messageError } from 'src/helpers/fileHelper';
import { getChartDataRentedBook, IChartData } from 'src/services/report';

import { LINE_CHART_COLOR, LINE_CHART_LABEL, LINE_CHART_OPTION } from './config';

export const LineChartRented: React.FC = () => {
  const [dataChart, setDataChart] = React.useState<IChartData[]>([]);

  React.useEffect(() => {
    getDataCurrentYearWithYearsAgo();
  }, []);

  async function getDataCurrentYearWithYearsAgo(numberYearsAgo = 1) {
    const currentYear = new Date().getFullYear();
    const listYearsAgo = Array.from(Array(numberYearsAgo)).map((_, index) => currentYear - ++index);
    const response: any = await getChartDataRentedBook([currentYear, ...listYearsAgo]);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      return;
    }
    if (response?.data) {
      setDataChart([...response.data].slice(0, 4));
    }
  }

  const chartDatasets = dataChart?.map((e, index) => {
    return {
      label: e.year.toString(),
      data: e.data?.map((dataItem) => {
        return dataItem.total || 0;
        // if (dataItem.total) return dataItem.total;
        // return getRandomNumber(10, 90);
      }), // for test: du lieu muon tra chua co du nen nhin line chart khong dep nen phai fake data
      fill: index === 0,
      borderColor: LINE_CHART_COLOR[index]?.borderColor,
      backgroundColor: LINE_CHART_COLOR[index]?.backgroundColor,
    };
  });

  const onChange = (value: number) => {
    getDataCurrentYearWithYearsAgo(value);
  };

  return (
    <div className="line-chart-rented flex flex-col items-center m-auto w-full xl:w-4/5">
      <div className="flex justify-center items-center text-center mb-3">
        <p>So sánh số lượt mượn sách của năm hiện tại với</p>
        <Select className="w-32 ml-2 select-year" filterOption={false} onChange={onChange} defaultValue={1}>
          <Select.Option value={1}>1 năm trước</Select.Option>
          <Select.Option value={2}>2 năm trước</Select.Option>
          <Select.Option value={3}>3 năm trước</Select.Option>
        </Select>
      </div>
      {!!dataChart.length && (
        <Line
          data={{
            labels: LINE_CHART_LABEL,
            datasets: chartDatasets,
          }}
          options={LINE_CHART_OPTION}
        />
      )}
    </div>
  );
};

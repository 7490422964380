import { useEffect, useState } from 'react';

import { Form, Input } from 'antd';

import { ButtonPrimary } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { ModalCustom } from 'src/components/ModalCustom';
import { MESSAGE, PATTERN_NOT_ONLY_SPACE, ResponseStatusEnum } from 'src/const';
import { messageError, notificationSuccess } from 'src/helpers';
import { usePermission } from 'src/hooks/usePermission';
import { createPublisher, IPublisher, updatePublisher } from 'src/services/publisher';
import { MayBe } from 'src/types';

interface IProps {
  refetchData: () => void;
  onCloseModal: () => void;
  editMode: boolean;
  open: boolean;
  dataDetailPublisher: MayBe<IPublisher>;
}

export const ModalCreateUpdatePublisher = ({
  refetchData,
  editMode,
  open,
  onCloseModal,
  dataDetailPublisher,
}: IProps) => {
  const [form] = Form.useForm();
  const { isAccessBook } = usePermission();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loading && setLoading(false);
    if (!dataDetailPublisher) {
      form.resetFields();
      return;
    }
    form.setFieldsValue({ ...dataDetailPublisher });
  }, [dataDetailPublisher]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const body: IPublisher = {
      ...values,
      id: dataDetailPublisher?.id,
    };
    const response: any = editMode ? await updatePublisher(body) : await createPublisher(body);
    setLoading(false);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      return;
    }
    onCloseModal();
    notificationSuccess();
    refetchData();
    form.resetFields();
  };

  return (
    <ModalCustom
      open={open}
      className="modal-book-position"
      title={editMode ? 'Sửa tên nhà xuất bản' : 'Thêm nhà xuất bản'}
      loading={loading}
      width={500}
      onCancel={onCloseModal}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Tên nhà xuất bản"
          rules={[{ required: true }, { pattern: PATTERN_NOT_ONLY_SPACE, message: MESSAGE.stringNotOnlySpace }]}
        >
          <Input type="text" />
        </Form.Item>
        {isAccessBook && (
          <div className="flex mt-8">
            <ButtonCancel id="btn-cancel" className="w-full mr-2" onClick={onCloseModal}>
              Thoát
            </ButtonCancel>
            <ButtonPrimary id="btn-save" className="w-full ml-2" htmlType="submit">
              Lưu
            </ButtonPrimary>
          </div>
        )}
      </Form>
    </ModalCustom>
  );
};

import httpClient from 'src/configs/http-client';
import { jsonConfig } from 'src/const';
import { IList } from 'src/types';

import { IBodyCheckExistReader, IBodyCreateUpdateReader, IParamsSearchReader, IReader } from './reader.interface';

export const getListReader = (param: IParamsSearchReader) => {
  let url = `/reader/getListReader?status=${param.status}&`;
  for (const [key, value] of Object.entries(param)) {
    if (value && key !== 'status') {
      url += `${key}=${value}&`;
    }
  }
  return httpClient.get<IList<IReader>>(url);
};
export const getReaderInfo = (id: number) => {
  return httpClient.get<IReader>('/reader/getReaderInfo', { params: { id } });
};
export const createReader = (body: IBodyCreateUpdateReader) => {
  return httpClient.post<IReader>('/reader/createReader', body, jsonConfig);
};
export const updateReader = (body: IBodyCreateUpdateReader) => {
  return httpClient.post<IReader>('/reader/updateReader', body, jsonConfig);
};
export const deleteReader = (id: number) => {
  return httpClient.post<void>('/reader/deleteReader', { id }, jsonConfig);
};
export const checkExistReaderInfo = (body: IBodyCheckExistReader) => {
  return httpClient.post<void>('/reader/checkExistReaderInfo', body, jsonConfig);
};

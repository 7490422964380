import React from 'react';

import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { SideBarRoute } from 'src/layouts/MainLayout/main.type';

export const PageError = () => {
  const history = useHistory();
  return (
    <Result
      status="500"
      title="Something wrong"
      subTitle="Sorry, something went wrong."
      extra={
        <Button onClick={() => history.push(SideBarRoute.HOME)} size="large" type="primary">
          Back Home
        </Button>
      }
    />
  );
};

import { TPagination } from './types';

export enum RoleIdEnum {
  ADMIN = 1,
  USER = 2,
  HR = 3,
  BOOK_AND_READER = 4,
  COM = 5,
  LOGISTIC = 6,
  FOREIGN_AND_DEVELOP = 7,
}
export enum ResponseStatusEnum {
  SUCCESS = 1,
  ERROR = 0,
}
export enum CommonStatusEnum {
  ACTIVE = 1,
  INACTIVE = 0,
  LOCK = 2,
}
export enum GenderEnum {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}
export enum AddressTypeEnum {
  DUONG_LIEU = 1,
  OTHERS = 2,
}

export const FIRST_PAGE = 1;
export const DEFAULT_PAGE_SIZE_RENTED_BOOK = 10;
export const DEFAULT_PAGE_SIZE = 50;
export const PAGE_SIZE_OPTIONS = [DEFAULT_PAGE_SIZE, 100, 200];
export const defaultPagination: TPagination = {
  itemPerPage: DEFAULT_PAGE_SIZE,
  totalItem: 0,
  currentPage: FIRST_PAGE,
};
export const initParamsSearch = { page: FIRST_PAGE, limit: DEFAULT_PAGE_SIZE };
export const GENDER_CONFIG = [
  {
    text: 'Nam',
    value: GenderEnum.MALE,
  },
  {
    text: 'Nữ',
    value: GenderEnum.FEMALE,
  },
  {
    text: 'Khác',
    value: GenderEnum.OTHER,
  },
];
export const STATUS_CONFIG = [
  {
    text: 'Hoạt động',
    value: CommonStatusEnum.ACTIVE,
  },
  {
    text: 'Tạm dừng',
    value: CommonStatusEnum.INACTIVE,
  },
];
export const ADDRESS_TYPE_CONFIG = [
  {
    text: 'Dương Liễu',
    value: AddressTypeEnum.DUONG_LIEU,
  },
  {
    text: 'Nơi khác',
    value: AddressTypeEnum.OTHERS,
  },
];

// eslint-disable-next-line no-useless-escape
export const PATTERN_PHONE = /^0[1-9]{1}[0-9]{8}$/;
export const PATTERN_NUMBER = /^\d+$/;
export const PATTERN_ONLY_NUMBER_AND_STRING = /^[a-zA-Z0-9]+$/;
export const PATTERN_NOT_ONLY_SPACE = /^.*[^ ].*$/;

export const DATE_TIME_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_HH_MM_FORMAT = 'DD/MM/YYYY HH:mm';

export const jsonConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    function (dataRequest: any) {
      return JSON.stringify(dataRequest);
    },
  ],
};
export const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  transformRequest: [
    function (dataRequest: any) {
      const keys = Object.keys(dataRequest);
      const formData = new FormData();
      keys.forEach((key) => {
        formData.append(key, dataRequest[key]);
      });
      return formData;
    },
  ],
};

export enum MESSAGE {
  success = 'Thành công!',
  passwordCopied = 'Đã copy mật khẩu!',

  loginError = 'Sai tài khoản hoặc mật khẩu',
  serverError = 'Lỗi hệ thống!',
  getListUserError = 'Không thể tải thông tin TNV',
  getListReaderError = 'Không thể tải thông tin bạn đọc',
  getListBookError = 'Không thể tải thông tin sách',
  getListCategoryError = 'Không thể tải thông tin thể loại sách',
  getListBookPositionError = 'Không thể tải thông tin giá sách',
  getListPublisherError = 'Không thể tải thông tin nhà xuất bản',
  getListAuthorError = 'Không thể tải thông tin tác giả',
  getListRentedBookError = 'Không thể tải lịch sử mượn trả sách',
  confirmDelete = 'Bạn có chắc chắn muốn xoá?',
  confirmChangePassword = 'Bạn có chắc chắn muốn đổi mật khẩu?',
  noPermissionError = 'Bạn không có quyền thực hiện chức năng này',
  deleteYourselfError = 'Không được phép xoá chính mình',
  resetPasswordYourselfError = 'Không nên đặt lại mật khẩu của chính mình. Hãy dùng chức năng ĐỔI MẬT KHẨU',
  qtyAvailableError = 'Số lượng có sẵn vượt quá số lượng nhập vào',
  requiredNumberGte0 = 'Vui lòng nhập số nguyên dương',
  stringNotOnlySpace = 'Không được phép nhập toàn bộ dấu cách',
  onlyNumberAndString = 'Chỉ được phép nhập số hoặc chữ',
  createCategoryRequireLogo = 'Vui lòng chọn logo cho thể loại',
  createRentedRequireReader = 'Vui lòng chọn bạn đọc',
  createRentedRequireBook = 'Vui lòng chọn ít nhất 1 quyển sách',
  deleteRentedBookDetailError = 'Không thể xoá vì lượt mượn này đã có thao tác trả sách',
  addBookToRentedBookError = 'Không thể thêm sách vì lượt mượn này đã có thao tác trả sách',
  onlyViewReaderInfo = 'Chỉ có thể xem thông tin của bạn đọc. Bản ghi của TNV thì không',
}

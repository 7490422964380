import { RoleIdEnum } from 'src/const';
import { OrderByMemberEnum } from 'src/services/user/user.interface';

export const ORDER_BY_MEMBER = [
  // {
  //   text: 'TNV tham gia lâu nhât',
  //   value: OrderByMemberEnum.ID_ASC,
  // },
  // {
  //   text: 'TNV tham gia gần nhất',
  //   value: OrderByMemberEnum.ID_DESC,
  // },
  {
    text: 'Số tuổi lớn => nhỏ',
    value: OrderByMemberEnum.DOB_ASC,
  },
  {
    text: 'Số tuổi nhỏ => lớn',
    value: OrderByMemberEnum.DOB_DESC,
  },
  {
    text: 'Tháng sinh nhỏ => lớn',
    value: OrderByMemberEnum.MONTH_ASC,
  },
  {
    text: 'Tháng sinh lớn => nhỏ',
    value: OrderByMemberEnum.MONTH_DESC,
  },
];

export enum FormItemMember {
  ACCOUNT = 'account',
  PHONE = 'phone',
  EMAIL = 'email',
  NAME = 'name',
  HAMLET_ID = 'hamletId',
  ADDRESS = 'address',
  GENDER = 'gender',
  DOB = 'dob',
  ROLE = 'role',
  IS_LEADER = 'isLeader',
  STATUS = 'status',
  NOTE = 'note',
  JOINED_DATE = 'joinedDate',
}
export enum IsLeaderEnum {
  YES = 1,
  NO = 0,
}
export const ROLE_CONFIG = [
  {
    text: 'Ban Quản trị',
    value: RoleIdEnum.ADMIN,
  },
  {
    text: 'Ban Truyền thông & Quan hệ công chúng',
    value: RoleIdEnum.COM,
  },
  {
    text: 'Ban Nhân sự',
    value: RoleIdEnum.HR,
  },
  {
    text: 'Ban Sách & Bạn đọc',
    value: RoleIdEnum.BOOK_AND_READER,
  },
  {
    text: 'Ban Hậu cần & Tổ chức',
    value: RoleIdEnum.LOGISTIC,
  },
  {
    text: 'Ban Đối ngoại & Phát triển',
    value: RoleIdEnum.FOREIGN_AND_DEVELOP,
  },
  {
    text: 'Tình nguyện viên',
    value: RoleIdEnum.USER,
  },
];
export const IS_LEADER_CONFIG = [
  {
    text: 'Trưởng ban',
    value: IsLeaderEnum.YES,
  },
  {
    text: 'Thành viên',
    value: IsLeaderEnum.NO,
  },
];

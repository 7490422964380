import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGE_SIZE, MESSAGE, ResponseStatusEnum } from 'src/const';
import { getListBook, IBook, IParamsSearchBook } from 'src/services/book';
import { TPagination } from 'src/types';

import { AppThunk, RootState } from '../store';

type BookState = {
  loading: boolean;
  error: string;
  pagination: TPagination;
  listBook: IBook[];
};

const initialState: BookState = {
  loading: false,
  error: '',
  pagination: {
    totalItem: 0,
    itemPerPage: DEFAULT_PAGE_SIZE,
    totalPage: 0,
    currentPage: 1,
  },
  listBook: [],
};

export const slice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    setLoading: (state: BookState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: BookState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setListBook: (state: BookState, action: PayloadAction<IBook[]>) => {
      state.listBook = action.payload;
    },
    setPagination: (state: BookState, action: PayloadAction<TPagination>) => {
      state.pagination = action.payload;
    },
  },
});

export const { setLoading, setError, setListBook, setPagination } = slice.actions;

export const listUser = (state: RootState) => state.user.users;

export const getListBookAction =
  (params: IParamsSearchBook): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const response = await getListBook(params);
    if (response.status === ResponseStatusEnum.SUCCESS) {
      const { totalItem, itemPerPage, totalPage, currentPage, items } = response.data;
      dispatch(setListBook(items));
      dispatch(
        setPagination({
          totalItem,
          itemPerPage,
          totalPage,
          currentPage,
        }),
      );
    } else {
      dispatch(setLoading(false));
      return MESSAGE.getListBookError;
    }
    dispatch(setLoading(false));
  };

export default slice.reducer;

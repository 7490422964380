import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { MESSAGE } from 'src/const';

import './styles.scss';

interface IProps {
  onDelete: () => void;
  onCancel: () => void;
  loading: boolean;
  open: boolean;
  content?: string;
}

export const ModalConfirmDelete = ({ loading, open, content = '', onCancel, onDelete }: IProps) => {
  return (
    <Modal open={open} className="modal-confirm-delete" width={600} onCancel={onCancel} centered>
      <div className="flex mb-3">
        <ExclamationCircleOutlined />
        <p>{MESSAGE.confirmDelete}</p>
      </div>
      {content.length > 0 && <span className="flex ml-11 content">{content}</span>}
      <div className="flex justify-end mt-10">
        <ButtonCancel id="btn-cancel" className="mr-2" onClick={onCancel}>
          Huỷ
        </ButtonCancel>
        <Button id="btn-delete" loading={loading} onClick={onDelete}>
          Xoá
        </Button>
      </div>
    </Modal>
  );
};

import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { ModalConfirmDelete } from 'src/components/ModalConfirmDelete';
import { TableCustom } from 'src/components/TableCustom';
import { ResponseStatusEnum } from 'src/const';
import { messageError, notificationSuccess } from 'src/helpers/fileHelper';
import { usePermission } from 'src/hooks/usePermission';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListAuthorAction } from 'src/redux/slices/author.slice';
import { IAuthor, deleteAuthor } from 'src/services/author';
import { MayBe } from 'src/types';

import { ModalCreateUpdateAuthor } from '../ModalCreateUpdate/ModalCreateUpdateAuthor';

import { ActionTable } from './ActionTable';

export const ListAuthor = () => {
  const { loading, listAuthor } = useAppSelector((state) => state.author);
  const { isAccessBook } = usePermission();
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [showModalCreateUpdate, setShowModalCreateUpdate] = useState(false);
  const [dataDetailAuthor, setDataDetailAuthor] = useState<MayBe<IAuthor>>(null);

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [recordDelete, setRecordDelete] = useState<MayBe<IAuthor>>(null);

  const tableColumns: any = [
    {
      title: 'Tên tác giả',
      dataIndex: 'name',
      width: 400,
      render: (name: string, record: IAuthor) => {
        return (
          <p className="font-semi-bold cursor-pointer" onClick={() => editRecord(record)}>
            {name}
          </p>
        );
      },
    },
  ];

  if (isAccessBook) {
    tableColumns.push({
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: IAuthor) => {
        return (
          <ActionTable editRecord={() => editRecord(record)} showDeleteConfirm={() => showDeleteConfirm(record)} />
        );
      },
    });
  }

  useEffect(() => {
    !listAuthor?.length && getListAuthor();
  }, []);

  async function getListAuthor() {
    const error: any = await dispatch(getListAuthorAction());
    if (error?.length) {
      messageError(error);
    }
  }

  function addRecord() {
    setEditMode(false);
    setShowModalCreateUpdate(true);
  }
  function editRecord(record: IAuthor) {
    setEditMode(true);
    setDataDetailAuthor(record);
    setShowModalCreateUpdate(true);
  }
  async function deleteRecord() {
    if (!recordDelete) {
      setShowConfirmDelete(false);
      return;
    }
    setLoadingDelete(true);
    const response: any = await deleteAuthor(recordDelete.id);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
    } else {
      getListAuthor();
      notificationSuccess();
    }
    setLoadingDelete(false);
    setShowConfirmDelete(false);
  }

  const showDeleteConfirm = (recordDelete: IAuthor) => {
    if (!recordDelete) return;
    setRecordDelete(recordDelete);
    setShowConfirmDelete(true);
  };

  return (
    <>
      <div className="flex justify-between">
        <p className="text-title mb-1">
          Tổng số tác giả: <span className="secondary-color">{listAuthor?.length}</span>
        </p>
        {isAccessBook && (
          <ButtonSecondary id="btn-add" onClick={addRecord}>
            <PlusOutlined /> Thêm
          </ButtonSecondary>
        )}
      </div>
      <TableCustom
        columns={tableColumns}
        dataSource={listAuthor}
        scroll={{ x: 300, y: 500 }}
        pagination={false}
        rowKey={(record: any) => record.id}
        loading={loading}
      />
      {showModalCreateUpdate && (
        <ModalCreateUpdateAuthor
          refetchData={() => getListAuthor()}
          editMode={editMode}
          open={showModalCreateUpdate}
          onCloseModal={() => {
            setShowModalCreateUpdate(false);
            setDataDetailAuthor(null);
          }}
          dataDetailAuthor={dataDetailAuthor}
        />
      )}
      {showConfirmDelete && (
        <ModalConfirmDelete
          loading={loadingDelete}
          open={showConfirmDelete}
          content={recordDelete?.name}
          onCancel={() => {
            setShowConfirmDelete(false);
            setRecordDelete(null);
          }}
          onDelete={deleteRecord}
        />
      )}
    </>
  );
};

import React from 'react';

import Card from 'src/components/Card';

import { ListReaderActive } from './components';

export const ReaderActive: React.FC = () => {
  return (
    <Card className="mt-20">
      <ListReaderActive />
    </Card>
  );
};

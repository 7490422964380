import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGE_SIZE, MESSAGE, ResponseStatusEnum } from 'src/const';
import { getListReader, IParamsSearchReader, IReader } from 'src/services/reader';
import { TPagination } from 'src/types';

import { AppThunk, RootState } from '../store';

type ReaderState = {
  loading: boolean;
  error: string;
  pagination: TPagination;
  readers: IReader[];
};

const initialState: ReaderState = {
  loading: false,
  error: '',
  pagination: {
    totalItem: 0,
    itemPerPage: DEFAULT_PAGE_SIZE,
    totalPage: 0,
    currentPage: 1,
  },
  readers: [],
};

export const slice = createSlice({
  name: 'reader',
  initialState,
  reducers: {
    setLoading: (state: ReaderState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: ReaderState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setListReader: (state: ReaderState, action: PayloadAction<IReader[]>) => {
      state.readers = action.payload;
    },
    setPagination: (state: ReaderState, action: PayloadAction<TPagination>) => {
      state.pagination = action.payload;
    },
  },
});

export const { setLoading, setError, setListReader, setPagination } = slice.actions;

export const listReader = (state: RootState) => state.reader.readers;

export const getListReaderAction =
  (params: IParamsSearchReader): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const response = await getListReader(params);
    if (response.status === ResponseStatusEnum.SUCCESS) {
      const { totalItem, itemPerPage, totalPage, currentPage, items } = response.data;
      dispatch(setListReader(items));
      dispatch(
        setPagination({
          totalItem,
          itemPerPage,
          totalPage,
          currentPage,
        }),
      );
    } else {
      dispatch(setLoading(false));
      return MESSAGE.getListReaderError;
    }
    dispatch(setLoading(false));
  };

export default slice.reducer;

import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { TableCustom } from 'src/components/TableCustom';
import { DEFAULT_PAGE_SIZE, GenderEnum, GENDER_CONFIG, initParamsSearch, PAGE_SIZE_OPTIONS } from 'src/const';
import {
  formatDateRequestAPI,
  formatStringDate,
  formatStringDateTime_hh_mm,
  messageError,
} from 'src/helpers/fileHelper';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListReaderActiveAction } from 'src/redux/slices/report.slice';
import { IParamsGetReaderActive } from 'src/services/report';

import { SearchReaderActive } from '../SearchReaderActive';

import './styles.scss';

const INIT_PARAMS: IParamsGetReaderActive = {
  ...initParamsSearch,
  fromDate: formatDateRequestAPI(moment().startOf('month')),
  toDate: formatDateRequestAPI(moment().endOf('day')),
};

export const ListReaderActive = () => {
  const { loadingReaderActive, readersActive, paginationReaderActive } = useAppSelector((state) => state.report);
  const { listRank } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const [paramsSearch, setParamsSearch] = useState<IParamsGetReaderActive>(INIT_PARAMS);

  const tableColumns: any = [
    {
      title: 'Số thẻ',
      dataIndex: 'cardNumber',
      width: 60,
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: 'Sinh nhật',
      dataIndex: 'dob',
      width: 110,
      render: (dob: string) => formatStringDate(dob),
    },
    {
      title: 'Giới tính',
      dataIndex: 'gender',
      width: 100,
      render: (gender: GenderEnum) => GENDER_CONFIG.find((item) => item.value === gender)?.text || '',
    },
    {
      title: 'Hạng',
      dataIndex: 'rankId',
      width: 100,
      render: (rankId: number) => listRank.find((item) => item.id === rankId)?.name || '',
    },
    {
      title: 'Điểm',
      dataIndex: 'point',
      width: 80,
    },
    {
      title: 'Hoạt động cuối',
      dataIndex: 'lastActiveDate',
      width: 120,
      render: (lastActiveDate: string) => {
        const dateTime = formatStringDateTime_hh_mm(lastActiveDate);
        return <p>{dateTime}</p>;
      },
    },
  ];

  useEffect(() => {
    getListReader(INIT_PARAMS);
  }, []);

  function onChangePage(page: any, pageSize: any) {
    getListReader({ ...paramsSearch, page, limit: pageSize });
  }

  async function getListReader(params: IParamsGetReaderActive) {
    setParamsSearch(params);
    const error: any = await dispatch(getListReaderActiveAction(params));
    if (error?.length) {
      messageError(error);
    }
  }

  return (
    <div className="list-reader-active">
      <SearchReaderActive paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      <div className="flex justify-between">
        <p className="text-base">
          Có tất cả <span className="secondary-color">{paginationReaderActive?.totalItem}</span> bạn đọc
        </p>
      </div>
      <TableCustom
        columns={tableColumns}
        dataSource={readersActive}
        scroll={{ x: 1000, y: 500 }}
        rowKey={(record: any) => record.cardNumber}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          onChange: onChangePage,
          total: paginationReaderActive.totalItem,
          current: paginationReaderActive.currentPage,
        }}
        loading={loadingReaderActive}
      />
    </div>
  );
};

import { useState } from 'react';

import { Form, Input } from 'antd';

import { ButtonPrimary } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { ModalCustom } from 'src/components/ModalCustom';
import { ResponseStatusEnum } from 'src/const';
import { messageError } from 'src/helpers';
import { changePassword } from 'src/services/auth';

import './styles.scss';

interface IProps {
  onCloseModal: () => void;
  open: boolean;
}

export const ModalChangePassword = ({ open, onCloseModal }: IProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    const response: any = await changePassword(values);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      setLoading(false);
      return;
    }
    setLoading(false);
    onCancel();
  };

  const onCancel = () => {
    onCloseModal();
    form.resetFields();
  };

  return (
    <ModalCustom
      open={open}
      title="Đổi mật khẩu"
      className="modal-change-password"
      width={600}
      loading={loading}
      onCancel={onCancel}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label="Mật khẩu hiện tại" name="currentPassword" rules={[{ required: true }]}>
          <Input.Password type="password" className="custom-input-password" />
        </Form.Item>
        <Form.Item label="Mật khẩu mới" name="newPassword" validateFirst={true} rules={[{ required: true }]}>
          <Input.Password type="password" className="custom-input-password" />
        </Form.Item>
        <Form.Item
          label="Nhập lại mật khẩu mới"
          name="confirmPassword"
          hasFeedback
          validateFirst={true}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Mật khẩu nhập lại không khớp!'));
              },
            }),
          ]}
        >
          <Input.Password type="password" className="custom-input-password" />
        </Form.Item>
        <div className="flex mt-8">
          <ButtonCancel id="btn-cancel" className="w-full mr-2" onClick={onCancel}>
            Thoát
          </ButtonCancel>
          <ButtonPrimary id="btn-save" className="w-full ml-2" htmlType="submit">
            Lưu
          </ButtonPrimary>
        </div>
      </Form>
    </ModalCustom>
  );
};

import React, { useState } from 'react';

import { DEFAULT_PAGE_SIZE_RENTED_BOOK, FIRST_PAGE } from 'src/const';
import { IParamsSearchRentedBook } from 'src/services/rentedBook';

import Card from '../../components/Card';

import { ListRentedBook, SearchRentedBook } from './components';
import { EStatusRented } from './components/config';

export const RentedBook: React.FC = () => {
  const [paramsSearch, setParamsSearch] = useState<IParamsSearchRentedBook>({
    page: FIRST_PAGE,
    limit: DEFAULT_PAGE_SIZE_RENTED_BOOK,
    status: EStatusRented.NOT_RETURN,
  });
  return (
    <>
      <Card>
        <SearchRentedBook paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      </Card>
      <Card className="mt-10">
        <ListRentedBook paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      </Card>
    </>
  );
};

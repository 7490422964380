import httpClient from 'src/configs/http-client';
import { jsonConfig } from 'src/const';
import { IList } from 'src/types';

import { IBodyCreateUpdateMember, IParamsSearchUser, IUser } from './user.interface';

export const getListUser = (param: IParamsSearchUser) => {
  let url = `/member/getListMember?status=${param.status}&`;
  for (const [key, value] of Object.entries(param)) {
    if (value && key !== 'status') {
      url += `${key}=${value}&`;
    }
  }
  return httpClient.get<IList<IUser>>(url);
};
export const createMember = (body: IBodyCreateUpdateMember) => {
  return httpClient.post<IUser>('/member/createMember', body, jsonConfig);
};
export const updateMember = (body: IBodyCreateUpdateMember) => {
  return httpClient.post<IUser>('/member/updateMember', body, jsonConfig);
};
export const deleteMember = (id: number) => {
  return httpClient.post<void>('/member/deleteMember', { id }, jsonConfig);
};
export const resetPasswordMember = (memberId: number) => {
  return httpClient.post<string>('/home/resetPassword', { id: memberId }, jsonConfig);
};
export const getProfile = async () => {
  return httpClient.get<IUser>('/member/profile');
};

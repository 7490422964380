import React, { useEffect, useMemo, useState } from 'react';

import { CheckCircleOutlined, EyeOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { ButtonPrimary, ModalConfirm } from 'src/components';
import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { TableCustom } from 'src/components/TableCustom';
import { DEFAULT_PAGE_SIZE_RENTED_BOOK, MESSAGE, ResponseStatusEnum } from 'src/const';
import { calculateOverdueDate, formatStringDateTime_hh_mm, messageError } from 'src/helpers/fileHelper';
import { ModalCreateUpdateReader } from 'src/pages/Reader/components/ModalCreateUpdateReader';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListRentedBookAction, setRentedBookSelected } from 'src/redux/slices/rentedBook.slice';
import { IReader } from 'src/services/reader';
import { getReaderInfo } from 'src/services/reader/reader.service';
import { IParamsSearchRentedBook, IRentedBook, IRentedBookDetail, updateRentedBook } from 'src/services/rentedBook';
import { MayBe } from 'src/types';

import './styles.scss';

import { ModalCreateRentedBook } from '../Modal/ModalCreateRentedBook';
import { ModalRentedBookDetail } from '../Modal/ModalRentedBookDetail';
import { useHandleUpdateRentedBook } from '../Modal/ModalRentedBookDetail/useHandleUpdateRentedBook';

import { BookItem } from './BookItem';

type TListRentedBookProps = {
  paramsSearch: IParamsSearchRentedBook;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsSearchRentedBook>>;
};
enum EActionOpenModal {
  UPDATE = 1,
  VIEW = 2,
}
function getBookCodeByRecordRentedBook(recordRentedBook?: IRentedBook) {
  if (!recordRentedBook?.rentedBookDetail?.length) return '';
  const arrayBookCode = recordRentedBook.rentedBookDetail.reduce((bookCodeArr: string[], item) => {
    if (!item.returnedDate) {
      bookCodeArr.push(item.bookCode);
    }
    return bookCodeArr;
  }, []);
  return arrayBookCode.join(' - ');
}
export const ListRentedBook = ({ paramsSearch, setParamsSearch }: TListRentedBookProps) => {
  const { loading, listRentedBook, pagination, rentedBookSelected } = useAppSelector((state) => state.rentedBook);
  const dispatch = useAppDispatch();
  const { handleMessageUpdateRentedBook } = useHandleUpdateRentedBook();

  const [loadingUpdateRented, setLoadingUpdateRented] = useState(false);
  const [actionOpenModal, setActionOpenModal] = useState<MayBe<EActionOpenModal>>(null);
  const [dataReaderInfo, setDataReaderInfo] = useState<MayBe<IReader>>(null);

  const tableColumns: any = useMemo(
    () => [
      {
        title: 'Số thẻ',
        width: 80,
        render: (record: IRentedBook) => {
          return (
            <p
              className={clsx('text-base cursor-pointer font-semi-bold', !record.cardNumber && 'primary-color')}
              onClick={() => openModalReaderDetail(record)}
            >
              {record.cardNumber || record.account}
            </p>
          );
        },
      },
      {
        title: 'Họ tên',
        dataIndex: 'readerName',
        width: 200,
        render: (readerName: string, record: IRentedBook) => {
          return (
            <p className="text-base cursor-pointer font-semi-bold" onClick={() => openModalReaderDetail(record)}>
              {readerName}
            </p>
          );
        },
      },
      {
        title: 'Ngày mượn',
        dataIndex: 'borrowedDate',
        width: 100,
        render: (borrowedDate: string) => {
          const dateTime = formatStringDateTime_hh_mm(borrowedDate);
          return dateTime.split(' ').map((item, index) => <p key={index}>{item}</p>);
        },
      },
      // {
      //   title: 'Ngày dự kiến trả',
      //   dataIndex: 'expectedReturnDate',
      //   width: 130,
      //   render: (expectedReturnDate: string) => formatStringDateTime_hh_mm(expectedReturnDate),
      // },
      {
        title: 'Ngày trả',
        dataIndex: 'returnedDate',
        width: 130,
        render: (returnedDate: string, record: IRentedBook) => {
          if (!returnedDate) return;
          const countOverdueDate = calculateOverdueDate(record.expectedReturnDate, returnedDate);
          const dateTime = formatStringDateTime_hh_mm(returnedDate);
          return (
            <>
              <div className={clsx(countOverdueDate > 0 && 'error-color font-semi-bold')}>
                {dateTime.split(' ').map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
              {countOverdueDate > 0 && <p className="error-color font-semi-bold">Quá hạn {countOverdueDate} ngày</p>}
            </>
          );
        },
      },
      {
        title: 'Thông tin sách',
        width: 300,
        render: (record: IRentedBook) => {
          return record.rentedBookDetail.map((item: IRentedBookDetail, index: number) => (
            <BookItem key={index} item={item} />
          ));
        },
      },
      {
        title: 'Ghi chú',
        dataIndex: 'note',
        width: 150,
      },
      {
        title: '',
        width: 100,
        fixed: 'right',
        render: (record: IRentedBook) => {
          return (
            <div className="flex items-center flex-col">
              {!record.returnedDate ? (
                <ButtonPrimary
                  className="btn-update-rented"
                  onClick={() => {
                    if (record.returnedDate) return;
                    dispatch(setRentedBookSelected(record));
                    setActionOpenModal(EActionOpenModal.UPDATE);
                  }}
                >
                  <CheckCircleOutlined /> Trả toàn bộ
                </ButtonPrimary>
              ) : null}
              <ButtonCancel
                className="mt-2 btn-view-rented"
                onClick={() => {
                  dispatch(setRentedBookSelected(record));
                  setActionOpenModal(EActionOpenModal.VIEW);
                }}
              >
                <EyeOutlined className="text-base" /> Xem chi tiết
              </ButtonCancel>
            </div>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    getListRentedBook(paramsSearch);
  }, []);

  function onChangePage(page: any, pageSize: any) {
    getListRentedBook({ ...paramsSearch, page, limit: pageSize });
  }

  async function getListRentedBook(params: IParamsSearchRentedBook) {
    setParamsSearch(params);
    const error: any = await dispatch(getListRentedBookAction(params));
    if (error?.length) {
      messageError(error);
    }
  }

  async function handleUpdateRentedBook() {
    if (actionOpenModal !== EActionOpenModal.UPDATE || !rentedBookSelected) return;
    setLoadingUpdateRented(true);
    const response: any = await updateRentedBook({
      readerId: rentedBookSelected.readerId,
      rentedBookId: rentedBookSelected.rentedBookId,
    });
    const isContinueHandle = handleMessageUpdateRentedBook({
      response,
      setLoading: setLoadingUpdateRented,
      refetchData: getListRentedBook(paramsSearch),
    });
    if (!isContinueHandle) return;
    setActionOpenModal(null);
  }

  async function openModalReaderDetail(record: IRentedBook) {
    if (!record.cardNumber) {
      messageError(MESSAGE.onlyViewReaderInfo);
      return;
    }
    const response: any = await getReaderInfo(record.readerId);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message as MESSAGE);
      return;
    }
    setDataReaderInfo(response.data);
  }

  return (
    <div className="list-rentedBook">
      <ModalCreateRentedBook paramsSearch={paramsSearch} refetchData={() => getListRentedBook(paramsSearch)} />
      <TableCustom
        columns={tableColumns}
        dataSource={listRentedBook}
        scroll={{ x: 1500, y: 1000 }}
        rowKey={(record: any) => record.id}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE_RENTED_BOOK,
          showSizeChanger: false,
          // showSizeChanger: true,
          // pageSizeOptions: [DEFAULT_PAGE_SIZE_RENTED_BOOK, 10],
          onChange: onChangePage,
          total: pagination.totalItem,
          current: pagination.currentPage,
        }}
        loading={loading}
      />
      {rentedBookSelected && actionOpenModal === EActionOpenModal.VIEW && (
        <ModalRentedBookDetail
          refetchData={() => getListRentedBook(paramsSearch)}
          open={rentedBookSelected && actionOpenModal === EActionOpenModal.VIEW}
          onCloseModal={() => setActionOpenModal(null)}
        />
      )}
      {rentedBookSelected && actionOpenModal === EActionOpenModal.UPDATE && (
        <ModalConfirm
          loading={loadingUpdateRented}
          open={actionOpenModal === EActionOpenModal.UPDATE}
          title="Xác nhận bạn đọc trả hết sách của lượt mượn"
          content={getBookCodeByRecordRentedBook(rentedBookSelected)}
          onCancel={() => setActionOpenModal(null)}
          onConfirm={handleUpdateRentedBook}
        />
      )}
      {!!dataReaderInfo && (
        <ModalCreateUpdateReader
          refetchData={() => getListRentedBook(paramsSearch)}
          editMode={true}
          open={!!dataReaderInfo}
          onCloseModal={() => setDataReaderInfo(null)}
          dataDetailReader={dataReaderInfo}
        />
      )}
    </div>
  );
};

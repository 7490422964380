import { Modal } from 'antd';

import { ButtonCancel } from 'src/components/Button/ButtonCancel';

import './styles.scss';

interface IProps {
  onCancel: () => void;
  open: boolean;
  content: any;
}

export const ModalMessage = ({ open, content, onCancel }: IProps) => {
  return (
    <Modal open={open} className="modal-message" width={500} onCancel={onCancel} centered>
      {content}
      <ButtonCancel id="btn-cancel" className="mt-10" onClick={onCancel}>
        Đóng
      </ButtonCancel>
    </Modal>
  );
};

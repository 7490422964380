import React from 'react';

import { Button } from 'antd';
import clsx from 'clsx';

import './button-cancel.scss';
import { TButton } from '../../../types';

export const ButtonCancel: React.FC<TButton> = ({ children, ...rest }) => {
  const classButtonCancel = clsx('button-cancel', rest.className && rest.className);
  return (
    <Button {...rest} className={classButtonCancel} type="text">
      {children}
    </Button>
  );
};

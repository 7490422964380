import React from 'react';

import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import clsx from 'clsx';
import './styles.scss';

const { TextArea } = Input;
export const TextAreaPrimary: React.FC<TextAreaProps> = ({ ...rest }) => {
  const classNameInputPrimary = clsx('text-area-primary', rest.className && rest.className);
  return <TextArea {...rest} className={classNameInputPrimary} />;
};

import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import Popover from 'antd/lib/popover';
import clsx from 'clsx';

import { IRentedBookDetail } from 'src/services/rentedBook';

import './styles.scss';

type TProps = {
  item: IRentedBookDetail;
};
export const BookItem = ({ item }: TProps) => {
  return (
    <div className="my-1 flex items-center p-2 rounded-md book-item">
      {!!item.lost && (
        <Popover content="Làm mất">
          <WarningOutlined className="text-yellow-500 text-base pb-1 mr-2" />
        </Popover>
      )}
      {!!item.returnedDate && !item.lost && (
        <Popover content="Đã trả">
          <CheckCircleOutlined className="text-green-600 text-base pb-1 mr-2" />
        </Popover>
      )}
      <p
        className={clsx({
          'ml-6': !item.returnedDate && !item.lost,
        })}
      >
        <span className="font-semi-bold">{item.bookCode}</span> - <span>{item.bookName}</span>
      </p>
    </div>
  );
};

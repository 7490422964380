import React from 'react';

import { Input, InputProps } from 'antd';
import clsx from 'clsx';

import './styles.scss';

export const InputAllowClear: React.FC<InputProps> = ({ ...rest }) => {
  const classNameInputAllowClear = clsx('input-clear', rest.className && rest.className);
  return <Input {...rest} className={classNameInputAllowClear} allowClear />;
};

export const LINE_CHART_COLOR = [
  {
    borderColor: '#2BB673',
    backgroundColor: '#2bb67317',
  },
  {
    borderColor: '#742774',
    backgroundColor: '#742774',
  },
  {
    borderColor: '#eab409',
    backgroundColor: '#eab409',
  },
  {
    borderColor: '#0988ea',
    backgroundColor: '#0988ea',
  },
];
export const LINE_CHART_LABEL = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const LINE_CHART_OPTION: any = {
  parsing: {
    xAxisKey: 'x',
    yAxisKey: 'y',
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    legend: {
      // display: false,
      labels: {
        font: {
          family: 'Nunito',
          size: 12,
        },
        boxHeight: 10,
        usePointStyle: true,
      },
    },
    tooltip: {
      usePointStyle: true,
      backgroundColor: 'rgb(21,44,52)',
      titleFont: {
        family: 'Nunito',
        size: 14,
      },
      titleAlign: 'center',
      bodyFont: {
        family: 'Nunito',
      },
      bodySpacing: 4,
    },
    // subtitle: {
    //   display: true,
    //   text: 'Biểu đồ so sánh số lượt mượn sách',
    //   font: {
    //     family: 'Nunito',
    //     size: 14,
    //   },
    // },
  },
  elements: {
    line: {
      tension: 0.5,
      borderWidth: 1.5,
    },
    point: {
      radius: 0,
      hoverRadius: 1,
      hoverBorderWidth: 5,
    },
  },
  animation: {
    duration: 2000,
  },
  scales: {
    yAxes: {
      grid: {
        color: '#ddd',
        borderColor: 'transparent',
        tickColor: 'transparent',
      },
      title: {
        display: true,
        text: 'Số lượt mượn',
        color: '#2BB673',
      },
      ticks: {
        font: {
          size: 12,
        },
      },
    },
    xAxes: {
      grid: {
        color: 'transparent',
        borderColor: '#ddd',
        tickColor: 'transparent',
      },
      title: {
        display: true,
        text: 'Tháng',
        color: '#2BB673',
      },
      ticks: {
        font: {
          size: 12,
        },
      },
    },
  },
};

import httpClient from 'src/configs/http-client';
import { jsonConfig } from 'src/const';
import { IList } from 'src/types';

import { IPublisher } from './publisher.interface';

export const getListPublisher = () => {
  return httpClient.get<IList<IPublisher>>('/publisher/getListPublisher');
};
export const createPublisher = (body: IPublisher) => {
  return httpClient.post<IPublisher>('/publisher/create', body, jsonConfig);
};
export const updatePublisher = (body: IPublisher) => {
  return httpClient.post<IPublisher>('/publisher/update', body, jsonConfig);
};
export const deletePublisher = (id: number) => {
  return httpClient.post<void>('/publisher/delete', { id }, jsonConfig);
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MESSAGE, ResponseStatusEnum } from 'src/const';
import { getListPublisher, IPublisher } from 'src/services/publisher';

import { AppThunk } from '../store';

type PublisherState = {
  loading: boolean;
  error: string;
  listPublisher: IPublisher[];
};

const initialState: PublisherState = {
  loading: false,
  error: '',
  listPublisher: [],
};

export const slice = createSlice({
  name: 'publisher',
  initialState,
  reducers: {
    setLoading: (state: PublisherState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: PublisherState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setListPublisher: (state: PublisherState, action: PayloadAction<IPublisher[]>) => {
      state.listPublisher = action.payload;
    },
  },
});

export const { setLoading, setError, setListPublisher } = slice.actions;

export const getListPublisherAction = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  const response = await getListPublisher();
  if (response.status === ResponseStatusEnum.SUCCESS) {
    const { items } = response.data;
    dispatch(setListPublisher(items));
  } else {
    dispatch(setLoading(false));
    dispatch(setListPublisher([]));
    return MESSAGE.getListPublisherError;
  }
  dispatch(setLoading(false));
};

export default slice.reducer;

import React, { useEffect, useState } from 'react';

import { Col, DatePicker, Form, Radio, Row } from 'antd';

import { CommonStatusEnum, DATE_TIME_FORMAT } from 'src/const';
import { FormItemReader, READER_STATUS_CONFIG } from 'src/pages/Reader/components/config';

interface IProps {
  status?: CommonStatusEnum;
}

const HandleReaderStatus = ({ status }: IProps) => {
  const [isLock, setIsLock] = useState(status === CommonStatusEnum.LOCK || false);
  useEffect(() => {
    setIsLock(status === CommonStatusEnum.LOCK);
  }, [status]);

  return (
    <Row gutter={24}>
      <Col xs={24}>
        <Form.Item name={FormItemReader.STATUS} label="Trạng thái" rules={[{ required: true }]}>
          <Radio.Group onChange={() => setIsLock((prev) => !prev)}>
            {READER_STATUS_CONFIG.map((items) => (
              <Radio key={`READER_STATUS_${items.value}`} value={items.value}>
                {items.text}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Col>
      {isLock && (
        <Col xs={24}>
          <Form.Item name={FormItemReader.LOCK_TO_DATE} label="Khoá thẻ đến ngày" rules={[{ required: true }]}>
            <DatePicker placeholder="" format={DATE_TIME_FORMAT} />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default React.memo(HandleReaderStatus);

export enum FormItemRentedBook {
  READER_ID = 'readerId',
  LIST_BOOK_ID = 'listBookId',
}
export enum EStatusRented {
  RETURNED = 1,
  NOT_RETURN = 2,
}
export const STATUS_RENTED = [
  {
    text: 'Đã trả',
    value: EStatusRented.RETURNED,
  },
  {
    text: 'Chưa trả',
    value: EStatusRented.NOT_RETURN,
  },
];

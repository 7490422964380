import React, { useState } from 'react';

import { initParamsSearch } from 'src/const';
import { IParamsSearchReader } from 'src/services/reader';

import Card from '../../components/Card';

import { ListReader, SearchReader } from './components';

import './styles.scss';

export const Reader: React.FC = () => {
  const [paramsSearch, setParamsSearch] = useState<IParamsSearchReader>(initParamsSearch);
  return (
    <div className="reader-page">
      <Card>
        <SearchReader paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      </Card>
      <Card className="mt-10">
        <ListReader paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      </Card>
    </div>
  );
};

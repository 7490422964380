import React, { useState } from 'react';

import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { Select } from 'antd';
import debounce from 'lodash/debounce';

import { SelectPrimary } from 'src/components';
import { ResponseStatusEnum } from 'src/const';
import { messageError } from 'src/helpers';
import { getBookForRented, IBookForRentedBook } from 'src/services/rentedBook';
import './styles.scss';

type Props = {
  bookSelected: IBookForRentedBook[];
  setBookSelected: React.Dispatch<React.SetStateAction<IBookForRentedBook[]>>;
};
const SelectBook = ({ bookSelected, setBookSelected }: Props) => {
  const [loading, setLoading] = useState(false);
  const [dataOptions, setDataOptions] = useState<IBookForRentedBook[]>([]);

  const fetchReader = async (search: string) => {
    if (!search) {
      setDataOptions([]);
      return;
    }
    setLoading(true);
    const response: any = await getBookForRented(search);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
    } else {
      setDataOptions(response.data?.length ? response.data : []);
    }
    setLoading(false);
  };

  const selectBookItem = (value: number) => {
    const itemSelected = dataOptions.find((item) => item.id === value);
    const categoryCannotBorrow = itemSelected?.bookCategories?.find((item) => !item?.canBorrow);
    if (categoryCannotBorrow) {
      messageError(`Thể loại sách ${categoryCannotBorrow.code} không được phép mượn về nhà` as any);
      return;
    }
    itemSelected && setBookSelected([...bookSelected, itemSelected]);
    setDataOptions([]);
  };

  const deleteBookItem = (itemIndex: number) => {
    setBookSelected(bookSelected.filter((_, index) => index !== itemIndex));
  };
  return (
    <div className="w-full select-book-for-rented">
      <p>Chọn sách: Nhập mã sách</p>
      <SelectPrimary
        className="w-full"
        loading={loading}
        showSearch
        filterOption={false}
        onChange={selectBookItem}
        onSearch={debounce(fetchReader, 500)}
        value={0}
      >
        {dataOptions.map((item) => (
          <Select.Option value={item.id} key={item.id}>
            {[item.code, item.name].join(' - ')}
          </Select.Option>
        ))}
      </SelectPrimary>
      {bookSelected?.map((item, index) => (
        <div key={index} className="book-item-selected relative flex items-center mt-3 pr-14 duration-300 rounded-lg">
          <div className="mr-2 w-20">
            {item.bookCategories.map((category) => (
              <img src={category.logo} key={category.code} alt="the-loai-sach" className="object-contain my-1" />
            ))}
          </div>
          <div className="flex-1">
            <p className="font-semi-bold primary-color">{item.code}</p>
            <p>{item.name}</p>
          </div>
          <DeleteOutlined
            onClick={() => deleteBookItem(index)}
            className="absolute right-3 text-white rounded-full p-3 cursor-pointer duration-300 bg-gray-400 hover:bg-gray-500"
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(SelectBook);

import { useState } from 'react';

import { CopyOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

import { ModalConfirm, ModalMessage } from 'src/components';
import { DropdownActionTable } from 'src/components/DropdownActionTable';
import { DropdownMenuCustom, MenuItem } from 'src/components/DropdownMenuCustom';
import { MESSAGE, ResponseStatusEnum, RoleIdEnum } from 'src/const';
import { messageError, messageSuccess, playSoundSuccess } from 'src/helpers';
import { usePermission } from 'src/hooks/usePermission';
import { useAppSelector } from 'src/redux/hook';
import { IUser, resetPasswordMember } from 'src/services/user';

import './styles.scss';

type TProps = {
  record: IUser;
  editRecord: () => void;
  showDeleteConfirm: () => void;
};
export const ActionTable = ({ record, editRecord, showDeleteConfirm }: TProps) => {
  const { isAdmin } = usePermission();
  const { userAuth } = useAppSelector((state) => state.user);
  const [showMenu, setShowMenu] = useState(false);

  const [newPassword, setNewPassword] = useState('');

  const [showResetPassword, setShowResetPassword] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);

  const handleShowDropdown = (show: boolean) => {
    setShowMenu(show);
  };

  async function handleResetPassword() {
    if (!record?.id) return;
    setLoadingResetPassword(true);
    setShowResetPassword(false);
    const response: any = await resetPasswordMember(record.id);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      setLoadingResetPassword(false);
      return;
    }
    playSoundSuccess();
    setNewPassword(response.data);
    setLoadingResetPassword(false);
  }

  async function clickMenuResetPassword() {
    setShowMenu(false);
    if (record.role === RoleIdEnum.ADMIN && !isAdmin) {
      messageError(MESSAGE.noPermissionError);
      return;
    }
    if (record.id === userAuth?.id) {
      messageError(MESSAGE.resetPasswordYourselfError);
      return;
    }
    setShowResetPassword(true);
  }

  const _messageResetPassword = (
    <>
      <p className="text-lg font-bold pt-3">{`Mật khẩu mới của ${record.name.toUpperCase()}`}</p>
      <p className="flex items-start mt-5">
        <span className="text-4xl font-bold primary-color mr-5">{newPassword}</span>
        <Popover content="Copy">
          <CopyOutlined
            className="cursor-pointer text-2xl"
            onClick={() =>
              navigator.clipboard.writeText(newPassword).then(() => {
                messageSuccess(MESSAGE.passwordCopied);
                setNewPassword('');
              })
            }
          />
        </Popover>
      </p>
    </>
  );

  const _dropdownMenu = (
    <DropdownMenuCustom>
      <MenuItem
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        }
        text="Sửa"
        onClick={() => {
          setShowMenu(false);
          editRecord();
        }}
      />
      <MenuItem
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
            />
          </svg>
        }
        text="Đặt lại mật khẩu"
        onClick={clickMenuResetPassword}
      />
      <MenuItem
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        }
        text="Xoá"
        className="delete-color"
        onClick={() => {
          setShowMenu(false);
          showDeleteConfirm();
        }}
      />
    </DropdownMenuCustom>
  );

  return (
    <>
      <DropdownActionTable dropdownMenu={_dropdownMenu} openMenu={showMenu} handleShowDropdown={handleShowDropdown} />
      <ModalConfirm
        width={record.name?.length > 18 ? 600 : 500}
        loading={loadingResetPassword}
        open={showResetPassword}
        title={`Đặt lại mật khẩu cho ${record.name?.toUpperCase()}`}
        content="Mật khẩu mới sẽ được tạo ngẫu nhiên"
        onCancel={() => setShowResetPassword(false)}
        onConfirm={handleResetPassword}
      />
      <ModalMessage open={!!newPassword} content={_messageResetPassword} onCancel={() => setNewPassword('')} />
    </>
  );
};

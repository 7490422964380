import React from 'react';

import { RouteProps } from 'react-router-dom';

import { LoginLayout } from 'src/layouts/LoginLayout';
import { MainLayout } from 'src/layouts/MainLayout';
import { SideBarRoute } from 'src/layouts/MainLayout/main.type';
import { Book } from 'src/pages/Book';
import { Category } from 'src/pages/Category';
import { Login } from 'src/pages/Login';
import { Member } from 'src/pages/Member';
import { PageError } from 'src/pages/PageError';
import { Page403 } from 'src/pages/PageError/Page403';
import { Reader } from 'src/pages/Reader';
import { RentedBook } from 'src/pages/RentedBook';
import { ReportRented } from 'src/pages/Report/ReportRented';
import { ReportTop } from 'src/pages/Report/ReportTop';
import { Setting } from 'src/pages/Setting';

export interface IRoute extends RouteProps {
  restricted?: boolean;
  layout?: any;
}

interface IConfigRoutes {
  appRoutes: IRoute[];
  isPrivate?: boolean;
  layout?: React.ComponentType;
}

const privateRoutes: IRoute[] = [
  {
    children: <RentedBook />,
    exact: true,
    path: SideBarRoute.HOME,
  },
  {
    children: <Member />,
    exact: true,
    path: SideBarRoute.MEMBER,
  },
  {
    children: <Reader />,
    exact: true,
    path: SideBarRoute.READER,
  },
  {
    children: <Book />,
    exact: true,
    path: SideBarRoute.BOOK,
  },
  {
    children: <Category />,
    exact: true,
    path: SideBarRoute.CATEGORY,
  },
  // {
  //   children: <RentedBook />,
  //   exact: true,
  //   path: SideBarRoute.RENTED_BOOK,
  // },
  {
    children: <Setting />,
    exact: true,
    path: SideBarRoute.SETTING,
  },
  {
    children: <ReportRented />,
    exact: true,
    path: SideBarRoute.REPORT_RENTED,
  },
  {
    children: <ReportTop />,
    exact: true,
    path: SideBarRoute.REPORT_TOP,
  },
];
const publicRoutes: IRoute[] = [
  {
    children: <Login />,
    path: SideBarRoute.LOGIN,
    layout: LoginLayout,
    restricted: true,
  },
];
const noLayoutRoutes: IRoute[] = [
  {
    children: <Page403 />,
    path: '/403',
    restricted: false,
  },
  {
    children: <PageError />,
    path: '/error',
    restricted: false,
  },
];

export const configRoutes: IConfigRoutes[] = [
  {
    appRoutes: privateRoutes,
    isPrivate: true,
    layout: MainLayout,
  },
  {
    appRoutes: publicRoutes,
    layout: MainLayout,
  },
  {
    appRoutes: noLayoutRoutes,
  },
];

import React, { useState } from 'react';

import { DatePicker, Form, Select } from 'antd';

import { SearchWhiteIcon } from 'src/assets/icons';
import { BoxSearchWrapper } from 'src/components/BoxSearchWrapper';
import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { InputAllowClear } from 'src/components/InputAllowClear';
import { ADDRESS_TYPE_CONFIG, DATE_TIME_FORMAT, FIRST_PAGE, GENDER_CONFIG, STATUS_CONFIG } from 'src/const';
import { formatDateRequestAPI, messageError } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListUserAction } from 'src/redux/slices/user.slice';
import { IParamsSearchUser } from 'src/services/user';

import { ORDER_BY_MEMBER } from '../config';

const { Option } = Select;

type TSearchMemberProps = {
  paramsSearch: IParamsSearchUser;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsSearchUser>>;
};
export const SearchMember = ({ paramsSearch, setParamsSearch }: TSearchMemberProps) => {
  const { listHamlet } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoadingSearch(true);
    const fromDate = formatDateRequestAPI(values.fromDate);
    const toDate = formatDateRequestAPI(values.toDate);
    const newParams: IParamsSearchUser = {
      ...values,
      page: FIRST_PAGE,
      limit: paramsSearch.limit,
      fromDate,
      toDate,
    };
    setParamsSearch(newParams);
    const error: any = await dispatch(getListUserAction(newParams));
    if (error?.length) {
      messageError(error);
    }
    setLoadingSearch(false);
  };

  return (
    <BoxSearchWrapper title="Tìm kiếm TNV">
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="search" className="w-72">
          <InputAllowClear type="text" placeholder="Mã TNV, tên, sđt, email, địa chỉ" />
        </Form.Item>
        <Form.Item name="status" className="w-36">
          <Select placeholder="Trạng thái" allowClear>
            {STATUS_CONFIG.map((items) => (
              <Option key={`MEMBER_STATUS_${items.value}`} value={items.value}>
                {items.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="dobMonth" className="w-36">
          <Select placeholder="Sinh nhật" allowClear>
            {Array.from(Array(12).keys()).map((i) => (
              <Option key={`MEMBER_DOB_MONTH_${i}`} value={i + 1}>
                Tháng {i + 1}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="gender" className="w-36">
          <Select placeholder="Giới tính" allowClear>
            {GENDER_CONFIG.map((item) => (
              <Option key={`MEMBER_GENDER_${item.value}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="addressType" className="w-36">
          <Select placeholder="Địa chỉ" allowClear>
            {ADDRESS_TYPE_CONFIG.map((item) => (
              <Option key={`ADDRESS_TYPE_${item.value}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="hamletId" className="w-36">
          <Select placeholder="Thôn" allowClear>
            {listHamlet.map((item) => (
              <Option key={`HAMLET_${item.id}`} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="orderBy" className="w-60">
          <Select placeholder="Sắp xếp thứ tự" allowClear>
            {ORDER_BY_MEMBER.map((item, index) => (
              <Option key={`MEMBER_ORDER_BY_${index}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="fromDate" className="w-44">
          <DatePicker placeholder="Tham gia từ ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item name="toDate" className="w-44">
          <DatePicker placeholder="Đến ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item className="btn-search">
          <ButtonSecondary htmlType="submit" loading={loadingSearch}>
            <SearchWhiteIcon />
            <span>Search</span>
          </ButtonSecondary>
        </Form.Item>
      </Form>
    </BoxSearchWrapper>
  );
};

import React from 'react';

import { Select, SelectProps } from 'antd';
import clsx from 'clsx';
import './styles.scss';

export const SelectPrimary: React.FC<SelectProps> = ({ ...rest }) => {
  const classNameSelectPrimary = clsx('select-primary', rest.className && rest.className);
  return <Select {...rest} className={classNameSelectPrimary} />;
};

import httpClient from 'src/configs/http-client';
import { jsonConfig } from 'src/const';
import { IList } from 'src/types';

import { IBodyCreateUpdateBook, IBook, IParamsSearchBook } from './book.interface';

export const getListBook = (params: IParamsSearchBook) => {
  return httpClient.get<IList<IBook>>('/book/getListBook', { params });
};
export const createBook = (body: IBodyCreateUpdateBook) => {
  return httpClient.post<IBook>('/book/create', body, jsonConfig);
};
export const updateBook = (body: IBodyCreateUpdateBook) => {
  return httpClient.post<IBook>('/book/update', body, jsonConfig);
};
export const deleteBook = (id: number) => {
  return httpClient.post<void>('/book/delete', { id }, jsonConfig);
};

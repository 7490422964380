import React from 'react';

import { Input, InputProps } from 'antd';
import clsx from 'clsx';
import './styles.scss';

export const InputPassword: React.FC<InputProps> = ({ ...rest }) => {
  const classNameInputPrimary = clsx('input-password', rest.className && rest.className);
  return (
    <div id="input-password">
      <Input.Password {...rest} className={classNameInputPrimary} />
    </div>
  );
};

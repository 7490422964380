import { useAppSelector } from 'src/redux/hook';

export const useHandleAddress = () => {
  const { listHamlet } = useAppSelector((state) => state.app);

  const getAddress = (hamletId?: number, address = '') => {
    if (!hamletId) return address;
    const hamlet = listHamlet.find((item) => item.id === hamletId);
    return [hamlet ? `Thôn ${hamlet?.name}` : '', address]?.filter(Boolean)?.join(' - ');
  };

  return {
    getAddress,
  };
};

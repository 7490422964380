import React, { useMemo, useState } from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import moment from 'moment';

import { ButtonPrimary, ModalConfirm, ModalConfirmDelete } from 'src/components';
import { MESSAGE, ResponseStatusEnum } from 'src/const';
import {
  calculateOverdueDate,
  formatStringDate,
  getFullImageUrl,
  messageError,
  notificationSuccess,
  parseListCategoryStringToJson,
} from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setRentedBookSelected } from 'src/redux/slices/rentedBook.slice';
import {
  IBodyUpdateRentedBookDetail,
  IRentedBook,
  IRentedBookDetail,
  removeRentedBookDetail,
  updateRentedBookDetail,
} from 'src/services/rentedBook';
import { MayBe } from 'src/types';

import './styles.scss';
import { useHandleUpdateRentedBook } from './useHandleUpdateRentedBook';

interface IProps {
  itemRentedBookDetail: IRentedBookDetail;
  refetchData: () => void;
}
enum EConfirmReturnedBook {
  RETURN = 1,
  LOST = 2,
}
export const BookDetail = ({ itemRentedBookDetail, refetchData }: IProps) => {
  const { rentedBookSelected } = useAppSelector((state) => state.rentedBook);
  const dispatch = useAppDispatch();
  const { countItemNotReturn, handleMessageUpdateRentedBook } = useHandleUpdateRentedBook();
  const [loading, setLoading] = useState(false);
  const [openConfirmReturnedBook, setOpenConfirmReturnedBook] = useState<MayBe<EConfirmReturnedBook>>(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const countOverdueDate = useMemo(() => {
    return calculateOverdueDate(rentedBookSelected?.expectedReturnDate, itemRentedBookDetail.returnedDate);
  }, [rentedBookSelected]);

  async function handleUpdateRentedBookDetail() {
    if (!rentedBookSelected) return;
    setLoading(true);
    const body: IBodyUpdateRentedBookDetail = {
      readerId: rentedBookSelected.readerId,
      rentedBookId: rentedBookSelected.rentedBookId,
      rentedBookDetailId: itemRentedBookDetail.rentedBookDetailId,
      lost: openConfirmReturnedBook === EConfirmReturnedBook.LOST ? 1 : 0,
    };
    const response: any = await updateRentedBookDetail(body);
    const isContinueHandle = handleMessageUpdateRentedBook({
      response,
      setLoading,
      refetchData,
    });
    if (!isContinueHandle) return;

    const newRentedBook: IRentedBook = {
      ...rentedBookSelected,
      returnedDate: countItemNotReturn === 1 ? moment().add(7, 'h').toISOString() : undefined,
      rentedBookDetail: rentedBookSelected.rentedBookDetail.map((item) => {
        if (item.rentedBookDetailId === body.rentedBookDetailId) {
          return {
            ...item,
            returnedDate: moment().add(7, 'h').toISOString(),
            lost: body.lost,
          };
        }
        return item;
      }),
    };
    dispatch(setRentedBookSelected(newRentedBook));
    setOpenConfirmReturnedBook(null);
  }

  async function handleDeleteBook() {
    if (!rentedBookSelected) return;
    if (countItemNotReturn < rentedBookSelected.rentedBookDetail.length) {
      messageError(MESSAGE.deleteRentedBookDetailError);
      setOpenConfirmDelete(false);
      return;
    }
    setLoading(true);
    const response: any = await removeRentedBookDetail({
      readerId: rentedBookSelected.readerId,
      rentedBookId: rentedBookSelected.rentedBookId,
      rentedBookDetailId: itemRentedBookDetail.rentedBookDetailId,
    });
    if (response?.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
      setLoading(false);
      return;
    }
    notificationSuccess();
    const newRentedBook: IRentedBook = {
      ...rentedBookSelected,
      rentedBookDetail: rentedBookSelected.rentedBookDetail.filter(
        (item) => item.rentedBookDetailId !== itemRentedBookDetail.rentedBookDetailId,
      ),
    };
    dispatch(setRentedBookSelected(rentedBookSelected.rentedBookDetail.length === 1 ? null : newRentedBook));
    setOpenConfirmDelete(false);
    refetchData();
  }

  const dataCategoryOfBook = parseListCategoryStringToJson(itemRentedBookDetail.dataCategory);
  return (
    <>
      <div className="flex justify-between items-center flex-wrap rounded-lg mb-2 book-detail">
        <div className="flex items-center">
          <div className="mr-2">
            {dataCategoryOfBook.map((category) => (
              <img src={getFullImageUrl(category.logo)} key={category.code} alt="the-loai-sach" className="h-12 my-1" />
            ))}
          </div>
          <div className="mr-5 book-name">
            <p className="font-semi-bold primary-color">{itemRentedBookDetail.bookCode}</p>
            <p>{itemRentedBookDetail.bookName}</p>
          </div>
        </div>
        {itemRentedBookDetail.returnedDate && (
          <div className="flex items-center">
            {!!itemRentedBookDetail.lost && (
              <Popover content="Làm mất">
                <WarningOutlined className="text-yellow-500 text-xl pb-2 mr-2" />
              </Popover>
            )}
            <div>
              <p>
                Ngày trả: <span className="font-semi-bold">{formatStringDate(itemRentedBookDetail.returnedDate)}</span>
              </p>
              {countOverdueDate > 0 && (
                <p className="error-color">
                  Số ngày quá hạn: <span className="font-semi-bold">{countOverdueDate}</span>
                </p>
              )}
            </div>
          </div>
        )}
        {!itemRentedBookDetail.returnedDate && (
          <div className="flex">
            <ButtonPrimary className="w-14" onClick={() => setOpenConfirmReturnedBook(EConfirmReturnedBook.RETURN)}>
              Trả
            </ButtonPrimary>
            <Button
              className="w-14 mx-1 duration-200 btn-lost-book"
              onClick={() => setOpenConfirmReturnedBook(EConfirmReturnedBook.LOST)}
            >
              Mất
            </Button>
            {countItemNotReturn === (rentedBookSelected?.rentedBookDetail?.length || 0) && (
              <Button className="w-14 duration-200 btn-delete" onClick={() => setOpenConfirmDelete(true)}>
                Xoá
              </Button>
            )}
          </div>
        )}
      </div>
      {!!openConfirmReturnedBook && (
        <ModalConfirm
          loading={loading}
          open={!!openConfirmReturnedBook}
          title={`Xác nhận bạn đọc ${
            openConfirmReturnedBook === EConfirmReturnedBook.RETURN ? 'trả sách' : 'làm mất sách'
          } ${itemRentedBookDetail.bookCode}`}
          onCancel={() => setOpenConfirmReturnedBook(null)}
          onConfirm={handleUpdateRentedBookDetail}
        />
      )}
      {openConfirmDelete && (
        <ModalConfirmDelete
          loading={loading}
          open={openConfirmDelete}
          content={`${itemRentedBookDetail.bookCode} - ${itemRentedBookDetail.bookName}`}
          onCancel={() => setOpenConfirmDelete(false)}
          onDelete={handleDeleteBook}
        />
      )}
    </>
  );
};

import React from 'react';

import { Layout } from 'antd';
import clsx from 'clsx';

import { useAppSelector } from 'src/redux/hook';

import { Header } from './components/LayoutHeader';
import { SideBar } from './components/LayoutSideBar';

import './styles.scss';

export const MainLayout: React.FC = ({ children }) => {
  const { Content } = Layout;
  const { loadingAppState } = useAppSelector((state) => state.app);
  return (
    <div className="container-page">
      <Layout>
        <SideBar />
        <Layout>
          <Header />
          <Content className="m-0">
            <div className={clsx('h-full min-h-full', !loadingAppState && 'p-6')}>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

import httpClient from 'src/configs/http-client';
import { jsonConfig } from 'src/const';

import {
  IParamsGetBookBorrowed,
  IParamsGetReaderActive,
  IParamsGetTopBook,
  IParamsGetTopReader,
} from './report.interface';

export const getChartDataRentedBook = (listYear: number[]) => {
  return httpClient.post<any>('/report/getChartDataRentedBook', { listYear }, jsonConfig);
};
export const getTopBorrowedReader = (params: IParamsGetTopReader) => {
  return httpClient.get<any>('/report/getTopBorrowedReader', { params });
};
export const getTopBorrowedBook = (params: IParamsGetTopBook) => {
  return httpClient.get<any>('/report/getTopBorrowedBook', { params });
};
export const getReadersActiveByTime = (params: IParamsGetReaderActive) => {
  return httpClient.get<any>('/report/getReadersActiveByTime', { params });
};
export const getListBookBorrowed = (params: IParamsGetBookBorrowed) => {
  return httpClient.get<any>('/report/getListBookBorrowed', { params });
};

import React from 'react';

import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { SideBarRoute } from 'src/layouts/MainLayout/main.type';

export const Page403 = () => {
  const history = useHistory();
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page"
      extra={
        <Button key="home" onClick={() => history.push(SideBarRoute.HOME)} size="large" type="primary">
          Back Home
        </Button>
      }
    />
  );
};

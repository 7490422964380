import React from 'react';

export const SkeletonDefault: React.FC = () => {
  const _renderSkeleton = (
    <>
      <div className="p-3">
        <div className="grid grid-cols-3 gap-4 mt-2">
          <div className="h-8 bg-gray-200 rounded animate-pulse" />
          <div className="h-8 bg-gray-200 rounded animate-pulse" />
          <div className="h-8 bg-gray-200 rounded animate-pulse" />
          <div className="h-8 bg-gray-200 rounded animate-pulse col-span-2" />
          <div className="h-8 bg-gray-200 rounded animate-pulse" />
        </div>
      </div>
      <div className="px-3 mb-5 mt-1">
        <div className="bg-gray-200 h-48 overflow-hidden animate-pulse" />
      </div>
    </>
  );
  return (
    <div className="bg-white rounded w-full h-full mx-auto rounded-2xl shadow-lg">
      {_renderSkeleton}
      {_renderSkeleton}
      {_renderSkeleton}
    </div>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MESSAGE } from 'src/const';
import { localStorageHelper } from 'src/helpers';
import { IParamsLogin, login } from 'src/services/auth';

import { AppThunk } from '../store';

type AuthState = {
  loading: boolean;
  error: string;
};

const initialState: AuthState = {
  loading: false,
  error: '',
};

export const loginAction = createAsyncThunk('auth/login', async (body: IParamsLogin) => {
  const data = await login(body);
  return data;
});

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: AuthState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setError } = slice.actions;

export const handleLogin =
  (body: IParamsLogin): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const data: any = await login(body);
      if (data.data?.accessToken) {
        localStorageHelper.setItem(process.env.REACT_APP_ACCESS_TOKEN!, data.data.accessToken);
      } else {
        dispatch(setError(data.message || MESSAGE.loginError));
      }
      return data.data?.accessToken || '';
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

export default slice.reducer;

export const httpStatus = {
  StatusOK: 200,

  StatusBadRequest: 400,
  StatusUnauthorized: 401,
  StatusForbidden: 403,
  StatusNotFound: 404,
  AccountDeactivated: 11, //backend custom

  StatusInternalServerError: 500,
};

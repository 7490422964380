import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGE_SIZE, MESSAGE, ResponseStatusEnum } from 'src/const';
import { getListRentedBook, IParamsSearchRentedBook, IRentedBook } from 'src/services/rentedBook';
import { MayBe, TPagination } from 'src/types';

import { AppThunk } from '../store';

type RentedBookState = {
  loading: boolean;
  error: string;
  pagination: TPagination;
  listRentedBook: IRentedBook[];
  rentedBookSelected: MayBe<IRentedBook>;
};

const initialState: RentedBookState = {
  loading: false,
  error: '',
  pagination: {
    totalItem: 0,
    itemPerPage: DEFAULT_PAGE_SIZE,
    totalPage: 0,
    currentPage: 1,
  },
  listRentedBook: [],
  rentedBookSelected: null,
};

export const slice = createSlice({
  name: 'rentedBook',
  initialState,
  reducers: {
    setLoading: (state: RentedBookState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: RentedBookState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setPagination: (state: RentedBookState, action: PayloadAction<TPagination>) => {
      state.pagination = action.payload;
    },
    setListRentedBook: (state: RentedBookState, action: PayloadAction<IRentedBook[]>) => {
      state.listRentedBook = action.payload;
    },
    setRentedBookSelected: (state: RentedBookState, action: PayloadAction<MayBe<IRentedBook>>) => {
      state.rentedBookSelected = action.payload;
    },
  },
});

export const { setLoading, setError, setPagination, setListRentedBook, setRentedBookSelected } = slice.actions;

export const getListRentedBookAction =
  (params: IParamsSearchRentedBook): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const response = await getListRentedBook(params);
    if (response.status === ResponseStatusEnum.SUCCESS) {
      const { totalItem, itemPerPage, totalPage, currentPage, items } = response.data;
      dispatch(setListRentedBook(items));
      dispatch(
        setPagination({
          totalItem,
          itemPerPage,
          totalPage,
          currentPage,
        }),
      );
    } else {
      dispatch(setLoading(false));
      return MESSAGE.getListRentedBookError;
    }
    dispatch(setLoading(false));
  };

export default slice.reducer;

import React from 'react';

import { Form } from 'antd';
import moment from 'moment';

import { ResponseStatusEnum } from 'src/const';
import { formatDateRequestAPI, formatDateSetFieldValue, formatStringDate, messageError } from 'src/helpers/fileHelper';
import { getTopBorrowedReader, IParamsGetTopReader, ITopReader } from 'src/services/report';

import { ReportListTop, TOP_OPTIONS } from '../ReportListTop';

const INIT_PARAMS = {
  limit: TOP_OPTIONS[0],
  fromDate: formatDateRequestAPI(moment().startOf('month')),
  toDate: formatDateRequestAPI(moment().endOf('day')),
};
export const TopReader: React.FC = () => {
  const [form] = Form.useForm();
  const [topReaders, setTopReaders] = React.useState<ITopReader[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getTopReader(INIT_PARAMS);
  }, []);

  async function getTopReader(params: IParamsGetTopReader) {
    const response: any = await getTopBorrowedReader(params);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
    } else {
      setTopReaders(response.data || []);
    }
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    const fromDate = formatDateRequestAPI(values.fromDate);
    const toDate = formatDateRequestAPI(values.toDate);
    const params: IParamsGetTopReader = {
      limit: values.limit,
      fromDate,
      toDate,
    };
    await getTopReader(params);
    setLoading(false);
  };

  return (
    <ReportListTop
      form={form}
      onFinish={onFinish}
      loading={loading}
      className="mt-14"
      titleBoxSearch="TOP BẠN ĐỌC mượn sách nhiều nhất (Đã trả)"
      initialValuesForm={{
        limit: INIT_PARAMS.limit,
        fromDate: formatDateSetFieldValue(INIT_PARAMS.fromDate),
        toDate: formatDateSetFieldValue(INIT_PARAMS.toDate),
      }}
    >
      <table>
        <thead>
          <tr>
            <th>TOP</th>
            <th>HỌ TÊN</th>
            <th>SỐ THẺ</th>
            <th>NGÀY SINH</th>
            <th>LƯỢT MƯỢN</th>
          </tr>
        </thead>
        <tbody>
          {topReaders.map((reader, index) => (
            <tr key={reader.readerId}>
              <td>{index + 1}</td>
              <td>{reader.readerName}</td>
              <td>{reader.cardNumber}</td>
              <td>{formatStringDate(reader.dob)}</td>
              <td>{reader.totalBorrowed}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ReportListTop>
  );
};

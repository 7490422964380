import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MESSAGE, ResponseStatusEnum } from 'src/const';
import { getListCategory, ICategory } from 'src/services/category';

import { AppThunk } from '../store';

type CategoryState = {
  loading: boolean;
  error: string;
  listCategory: ICategory[];
};

const initialState: CategoryState = {
  loading: false,
  error: '',
  listCategory: [],
};

export const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setLoading: (state: CategoryState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: CategoryState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setListCategory: (state: CategoryState, action: PayloadAction<ICategory[]>) => {
      state.listCategory = action.payload;
    },
  },
});

export const { setLoading, setError, setListCategory } = slice.actions;

export const getListCategoryAction = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  const response = await getListCategory();
  if (response.status === ResponseStatusEnum.SUCCESS) {
    const { items } = response.data;
    dispatch(setListCategory(items));
  } else {
    dispatch(setLoading(false));
    dispatch(setListCategory([]));
    return MESSAGE.getListCategoryError;
  }
  dispatch(setLoading(false));
};

export default slice.reducer;

import httpClient from 'src/configs/http-client';
import { jsonConfig } from 'src/const';
import { IList, IResponse } from 'src/types';

import {
  IBodyAddBookToRentedBook,
  IBodyCreateRentedBook,
  IBodyDeleteRentedBookDetail,
  IBodyUpdateRentedBook,
  IBodyUpdateRentedBookDetail,
  IBookForRentedBook,
  IParamsExportRentedBook,
  IParamsSearchRentedBook,
  IReaderForRentedBook,
  IRentedBook,
  IRentedBookExported,
} from './rentedBook.interface';

export const getListRentedBook = (params: IParamsSearchRentedBook) => {
  return httpClient.get<IList<IRentedBook>>('/rented/getRentedBookHistory', { params });
};
export const createRentedBook = (body: IBodyCreateRentedBook) => {
  return httpClient.post<IRentedBook>('/rented/createRentedBook', body, jsonConfig);
};
export const updateRentedBook = (body: IBodyUpdateRentedBook) => {
  return httpClient.post<any>('/rented/updateRentedBook', body, jsonConfig);
};
export const updateRentedBookDetail = (body: IBodyUpdateRentedBookDetail) => {
  return httpClient.post<any>('/rented/updateRentedBookDetail', body, jsonConfig);
};
export const addBookToRentedBook = (body: IBodyAddBookToRentedBook) => {
  return httpClient.post<IResponse<IRentedBook>>('/rented/addBookToRentedBook', body, jsonConfig);
};
export const removeRentedBookDetail = (body: IBodyDeleteRentedBookDetail) => {
  return httpClient.post<any>('/rented/removeRentedBookDetail', body, jsonConfig);
};
export const getReaderForRented = (search: string) => {
  return httpClient.get<IReaderForRentedBook>('/rented/getReaderForRented', { params: { search } });
};
export const getBookForRented = (search: string) => {
  return httpClient.get<IBookForRentedBook>('/rented/getBookForRented', { params: { search } });
};
export const exportRentedBook = (params: IParamsExportRentedBook) => {
  return httpClient.get<IRentedBookExported[]>('/rented/export', { params });
};

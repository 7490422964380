import React, { useState } from 'react';

import { Form, Select, DatePicker } from 'antd';

import { SearchWhiteIcon } from 'src/assets/icons';
import { BoxSearchWrapper } from 'src/components/BoxSearchWrapper';
import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { InputAllowClear } from 'src/components/InputAllowClear';
import { DATE_TIME_FORMAT, FIRST_PAGE } from 'src/const';
import { formatDateRequestAPI, messageError } from 'src/helpers';
import { useAppDispatch } from 'src/redux/hook';
import { getListRentedBookAction } from 'src/redux/slices/rentedBook.slice';
import { IParamsSearchRentedBook } from 'src/services/rentedBook';

import { EStatusRented, STATUS_RENTED } from '../config';

const { Option } = Select;

type TSearchReaderProps = {
  paramsSearch: IParamsSearchRentedBook;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsSearchRentedBook>>;
};
export const SearchRentedBook = ({ paramsSearch, setParamsSearch }: TSearchReaderProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoadingSearch(true);
    const fromDate = formatDateRequestAPI(values.fromDate);
    const toDate = formatDateRequestAPI(values.toDate);
    const newParams: IParamsSearchRentedBook = {
      ...values,
      page: FIRST_PAGE,
      limit: paramsSearch.limit,
      fromDate,
      toDate,
    };
    setParamsSearch(newParams);
    const error: any = await dispatch(getListRentedBookAction(newParams));
    if (error?.length) {
      messageError(error);
    }
    setLoadingSearch(false);
  };

  const handleChangeFromDate = (date: any) => {
    setParamsSearch((pre) => ({
      ...pre,
      fromDate: formatDateRequestAPI(date),
    }));
  };

  const handleChangeToDate = (date: any) => {
    setParamsSearch((pre) => ({
      ...pre,
      toDate: formatDateRequestAPI(date),
    }));
  };

  return (
    <BoxSearchWrapper title="Lịch sử mượn trả sách">
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          status: EStatusRented.NOT_RETURN,
        }}
      >
        <Form.Item name="cardNumber" className="w-64">
          <InputAllowClear type="text" placeholder="Thẻ bạn đọc hoặc mã TNV" />
        </Form.Item>
        <Form.Item name="bookCode" className="w-40">
          <InputAllowClear type="text" placeholder="Mã sách" />
        </Form.Item>
        <Form.Item name="status" className="w-40">
          <Select placeholder="Trạng thái" allowClear>
            {STATUS_RENTED.map((item, index) => (
              <Option key={`STATUS_RENTED_BOOK_${index}`} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="fromDate" className="w-40">
          <DatePicker onChange={handleChangeFromDate} placeholder="Ngày mượn: Từ" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item name="toDate" className="w-40">
          <DatePicker onChange={handleChangeToDate} placeholder="Đến ngày" format={DATE_TIME_FORMAT} />
        </Form.Item>
        <Form.Item className="btn-search">
          <ButtonSecondary htmlType="submit" loading={loadingSearch}>
            <SearchWhiteIcon />
            <span>Search</span>
          </ButtonSecondary>
        </Form.Item>
      </Form>
    </BoxSearchWrapper>
  );
};

import React from 'react';

import { DatePicker, Form, FormInstance, Select } from 'antd';
import clsx from 'clsx';

import { SearchWhiteIcon } from 'src/assets/icons';
import { ButtonSecondary } from 'src/components';
import { BoxSearchWrapper } from 'src/components/BoxSearchWrapper';
import { DATE_TIME_FORMAT } from 'src/const';

import './styles.scss';

export const TOP_OPTIONS = [5, 10, 20];

type Props = {
  form: FormInstance;
  onFinish: any;
  loading?: boolean;
  initialValuesForm?: any;
  titleBoxSearch?: string;
  className?: string;
  children: React.ReactNode;
};
export const ReportListTop: React.FC<Props> = ({
  form,
  onFinish,
  loading,
  initialValuesForm,
  titleBoxSearch = '',
  className = '',
  children,
}) => {
  return (
    <div className={clsx('report-list-top shadow-xl', className)}>
      <BoxSearchWrapper title={titleBoxSearch}>
        <Form form={form} onFinish={onFinish} className="flex" initialValues={initialValuesForm}>
          <Form.Item name="limit" className="w-40">
            <Select
              options={TOP_OPTIONS.map((top) => ({
                label: `TOP ${top}`,
                value: top,
              }))}
            />
          </Form.Item>
          <Form.Item name="fromDate" className="w-40">
            <DatePicker placeholder="Từ ngày" format={DATE_TIME_FORMAT} />
          </Form.Item>
          <Form.Item name="toDate" className="w-40">
            <DatePicker placeholder="Đến ngày" format={DATE_TIME_FORMAT} />
          </Form.Item>
          <Form.Item className="btn-search">
            <ButtonSecondary htmlType="submit" loading={loading}>
              <SearchWhiteIcon />
              <span>Search</span>
            </ButtonSecondary>
          </Form.Item>
        </Form>
      </BoxSearchWrapper>
      <div className="data-wrapper">{children}</div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { useHandleAddress } from 'src/components/HandleAddress/useHandleAddress';
import { ModalConfirmDelete } from 'src/components/ModalConfirmDelete';
import { TableCustom } from 'src/components/TableCustom';
import {
  CommonStatusEnum,
  FIRST_PAGE,
  GenderEnum,
  GENDER_CONFIG,
  initParamsSearch,
  MESSAGE,
  PAGE_SIZE_OPTIONS,
  ResponseStatusEnum,
  RoleIdEnum,
  STATUS_CONFIG,
} from 'src/const';
import { formatStringDate, messageError, notificationSuccessAndScrollToTop } from 'src/helpers/fileHelper';
import { usePermission } from 'src/hooks/usePermission';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListUserAction } from 'src/redux/slices/user.slice';
import { deleteMember, IParamsSearchUser, IUser } from 'src/services/user';
import { MayBe } from 'src/types';

import { FormItemMember } from '../config';
import { ModalCreateUpdateMember } from '../ModalCreateUpdateMember';

import { ActionTable } from './ActionTable';
import './styles.scss';

type TListMemberProps = {
  paramsSearch: IParamsSearchUser;
  setParamsSearch: React.Dispatch<React.SetStateAction<IParamsSearchUser>>;
};
export const ListMember = ({ paramsSearch, setParamsSearch }: TListMemberProps) => {
  const { loading, users: listMember, pagination } = useAppSelector((state) => state.user);
  const { userAuth } = useAppSelector((state) => state.user);
  const { isAccessMember, isAdmin } = usePermission();
  const { getAddress } = useHandleAddress();
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [showModalCreateUpdate, setShowModalCreateUpdate] = useState(false);
  const [dataDetailMember, setDataDetailMember] = useState<MayBe<IUser>>(null);

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [recordDelete, setRecordDelete] = useState<MayBe<IUser>>(null);

  const tableColumns: any = [
    {
      title: 'Mã TNV',
      dataIndex: FormItemMember.ACCOUNT,
      width: 100,
    },
    {
      title: 'Họ tên',
      dataIndex: FormItemMember.NAME,
      width: 300,
      render: (name: string, record: IUser) => {
        return (
          <p
            className={clsx(record.status === CommonStatusEnum.ACTIVE ? 'active-name' : 'inactive-name')}
            onClick={() => isAccessMember && editRecord(record)}
          >
            {name}
          </p>
        );
      },
    },
    // {
    //   title: 'Vai trò',
    //   dataIndex: FormItemMember.ROLE,
    //   width: 220,
    //   render: (role: RoleIdEnum) => {
    //     return (
    //       <p
    //         className={clsx(
    //           role === RoleIdEnum.ADMIN ? 'role role-admin' : role === RoleIdEnum.USER ? '' : 'role role-leader',
    //         )}
    //       >
    //         {ROLE_CONFIG.find((item) => item.value === role)?.text || ''}
    //       </p>
    //     );
    //   },
    // },
    {
      title: 'Sinh nhật',
      dataIndex: FormItemMember.DOB,
      width: 110,
      render: (dob: string) => formatStringDate(dob),
    },
    {
      title: 'SĐT',
      dataIndex: FormItemMember.PHONE,
      width: 120,
    },
    {
      title: 'Email',
      dataIndex: FormItemMember.EMAIL,
      width: 300,
    },
    {
      title: 'Địa chỉ',
      dataIndex: FormItemMember.ADDRESS,
      width: 250,
      render: (address: string, record: IUser) => <p>{getAddress(record.hamletId, address)}</p>,
    },
    {
      title: 'Giới tính',
      dataIndex: FormItemMember.GENDER,
      width: 100,
      render: (gender: GenderEnum) => GENDER_CONFIG.find((item) => item.value === gender)?.text || '',
    },
    {
      title: 'Ghi chú',
      dataIndex: FormItemMember.NOTE,
      width: 200,
    },
    {
      title: 'Trạng thái',
      dataIndex: FormItemMember.STATUS,
      width: 120,
      render: (status: number, record: IUser) => {
        return (
          <p className={clsx(record.status === CommonStatusEnum.INACTIVE && 'inactive-status')}>
            {STATUS_CONFIG.find((item) => item.value === status)?.text || ''}
          </p>
        );
      },
    },
  ];

  if (isAccessMember) {
    tableColumns.push({
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: IUser) => {
        return (
          <ActionTable
            record={record}
            editRecord={() => editRecord(record)}
            showDeleteConfirm={() => showDeleteConfirm(record)}
          />
        );
      },
    });
  }

  useEffect(() => {
    getListMember(initParamsSearch);
  }, []);

  function onChangePage(page: any, pageSize: any) {
    getListMember({ ...paramsSearch, page, limit: pageSize });
  }

  async function getListMember(params: IParamsSearchUser) {
    setParamsSearch(params);
    const error: any = await dispatch(getListUserAction(params));
    if (error?.length) {
      messageError(error);
    }
  }

  function addRecord() {
    setEditMode(false);
    setShowModalCreateUpdate(true);
  }
  function editRecord(record: IUser) {
    if (record.role === RoleIdEnum.ADMIN && !isAdmin) {
      messageError(MESSAGE.noPermissionError);
      return;
    }
    setEditMode(true);
    setDataDetailMember(record);
    setShowModalCreateUpdate(true);
  }
  async function handleDeleteRecord() {
    if (!recordDelete) {
      return;
    }
    setLoadingDelete(true);
    const response: any = await deleteMember(recordDelete.id);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
    } else {
      const params =
        listMember.length > 1 // before delete, list data > 1 record
          ? paramsSearch // search with current params
          : {
              ...paramsSearch,
              page: paramsSearch.page - 1 || FIRST_PAGE,
            }; // search with previous page number
      getListMember(params);
      notificationSuccessAndScrollToTop();
    }
    setLoadingDelete(false);
    setRecordDelete(null);
  }

  const showDeleteConfirm = (recordDelete: IUser) => {
    if (!recordDelete) return;
    if (recordDelete.role === RoleIdEnum.ADMIN && !isAdmin) {
      messageError(MESSAGE.noPermissionError);
      return;
    }
    if (recordDelete.id === userAuth?.id) {
      messageError(MESSAGE.deleteYourselfError);
      return;
    }
    setRecordDelete(recordDelete);
  };

  return (
    <div className="list-member">
      <div className="flex justify-between">
        <p className="text-title mb-1">
          Tự hào có <span className="secondary-color">{pagination?.totalItem}</span> TNV
        </p>
        {isAccessMember && (
          <ButtonSecondary id="btn-add" onClick={addRecord}>
            <PlusOutlined /> Thêm
          </ButtonSecondary>
        )}
      </div>
      <TableCustom
        columns={tableColumns}
        dataSource={listMember}
        scroll={{ x: 1500 }}
        // scroll={{ x: 1500, y: 500 }}
        rowKey={(record: any) => record.id}
        pagination={{
          defaultPageSize: PAGE_SIZE_OPTIONS[1],
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          onChange: onChangePage,
          total: pagination.totalItem,
          current: pagination.currentPage,
        }}
        loading={loading}
      />
      <ModalCreateUpdateMember
        refetchData={() => getListMember(paramsSearch)}
        editMode={editMode}
        open={showModalCreateUpdate}
        onCloseModal={() => {
          setShowModalCreateUpdate(false);
          setDataDetailMember(null);
        }}
        dataDetailMember={dataDetailMember}
      />
      <ModalConfirmDelete
        loading={loadingDelete}
        open={!!recordDelete}
        content={recordDelete?.name}
        onCancel={() => setRecordDelete(null)}
        onDelete={handleDeleteRecord}
      />
    </div>
  );
};

import React, { useEffect, useState } from 'react';

import { CheckCircleOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';

import { ButtonSecondary } from 'src/components/Button/ButtonSecondary';
import { ModalConfirmDelete } from 'src/components/ModalConfirmDelete';
import { TableCustom } from 'src/components/TableCustom';
import { DEFAULT_PAGE_SIZE, ResponseStatusEnum } from 'src/const';
import { messageError, notificationSuccessAndScrollToTop } from 'src/helpers/fileHelper';
import { usePermission } from 'src/hooks/usePermission';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListCategoryAction } from 'src/redux/slices/category.slice';
import { deleteCategory, ICategory } from 'src/services/category';
import { MayBe } from 'src/types';

import { ModalCreateUpdateCategory } from '../ModalCreateUpdateCategory';

import { ActionTable } from './ActionTable';

import './styles.scss';

export const ListCategory = () => {
  const { loading, listCategory } = useAppSelector((state) => state.category);
  const { isAccessBook } = usePermission();
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [showModalCreateUpdate, setShowModalCreateUpdate] = useState(false);
  const [dataDetailCategory, setDataDetailCategory] = useState<MayBe<ICategory>>(null);

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [recordDelete, setRecordDelete] = useState<MayBe<ICategory>>(null);

  const tableColumns: any = [
    {
      title: 'Logo',
      dataIndex: 'logo',
      width: 120,
      render: (logo: string) => <img className="h-10" src={logo} alt="logo" />,
    },
    {
      title: 'Thể loại sách',
      dataIndex: 'name',
      width: 250,
      render: (name: string, record: ICategory) => {
        return (
          <p className="font-semi-bold cursor-pointer" onClick={() => editRecord(record)}>
            {name}
          </p>
        );
      },
    },
    {
      title: 'Mã thể loại',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Số điểm',
      dataIndex: 'point',
      width: 100,
    },
    {
      title: 'Cho mượn',
      dataIndex: 'canBorrow',
      width: 110,
      render: (canBorrow: number) => {
        return !!canBorrow ? (
          <CheckCircleOutlined className="text-green-500 text-lg" />
        ) : (
          <StopOutlined className="text-red-500 text-lg" />
        );
      },
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      width: 300,
    },
  ];

  if (isAccessBook) {
    tableColumns.push({
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: ICategory) => {
        return (
          <ActionTable
            record={record}
            editRecord={() => editRecord(record)}
            showDeleteConfirm={() => showDeleteConfirm(record)}
          />
        );
      },
    });
  }

  useEffect(() => {
    !listCategory?.length && getListCategory();
  }, []);

  async function getListCategory() {
    const error: any = await dispatch(getListCategoryAction());
    if (error?.length) {
      messageError(error);
    }
  }

  function addRecord() {
    setEditMode(false);
    setShowModalCreateUpdate(true);
  }
  function editRecord(record: ICategory) {
    setEditMode(true);
    setDataDetailCategory(record);
    setShowModalCreateUpdate(true);
  }
  async function deleteRecord() {
    if (!recordDelete) {
      setShowConfirmDelete(false);
      return;
    }
    setLoadingDelete(true);
    const response: any = await deleteCategory(recordDelete.id);
    if (response.status === ResponseStatusEnum.ERROR) {
      messageError(response.message);
    } else {
      getListCategory();
      notificationSuccessAndScrollToTop();
    }
    setLoadingDelete(false);
    setShowConfirmDelete(false);
  }

  const showDeleteConfirm = (recordDelete: ICategory) => {
    if (!recordDelete) return;
    setRecordDelete(recordDelete);
    setShowConfirmDelete(true);
  };

  return (
    <div className="list-book-category">
      <div className="flex justify-between">
        <p className="text-title mb-1">
          Tổng số thể loại sách: <span className="secondary-color">{listCategory?.length}</span>
        </p>
        {isAccessBook && (
          <ButtonSecondary id="btn-add" onClick={addRecord}>
            <PlusOutlined /> Thêm
          </ButtonSecondary>
        )}
      </div>
      <TableCustom
        columns={tableColumns}
        dataSource={listCategory}
        // scroll={{ x: 800, y: 500 }}
        scroll={{ x: 800 }}
        pagination={{ pageSize: DEFAULT_PAGE_SIZE }}
        rowKey={(record: any) => record.id}
        loading={loading}
      />
      <ModalCreateUpdateCategory
        refetchData={() => getListCategory()}
        editMode={editMode}
        open={showModalCreateUpdate}
        onCloseModal={() => {
          setShowModalCreateUpdate(false);
          setDataDetailCategory(null);
        }}
        dataDetailCategory={dataDetailCategory}
      />
      <ModalConfirmDelete
        loading={loadingDelete}
        open={showConfirmDelete}
        content={recordDelete?.name}
        onCancel={() => {
          setShowConfirmDelete(false);
          setRecordDelete(null);
        }}
        onDelete={deleteRecord}
      />
    </div>
  );
};

import { Col, Row } from 'antd';

import { ButtonCancel } from 'src/components/Button/ButtonCancel';
import { ModalCustom } from 'src/components/ModalCustom';
import { calculateOverdueDate, formatStringDateTime_hh_mm } from 'src/helpers';
import { useAppSelector } from 'src/redux/hook';

import './styles.scss';
import { ModalAddBookToRentedBook } from '../ModalAddBookToRentedBook';

import { BookDetail } from './BookDetail';
import { useHandleUpdateRentedBook } from './useHandleUpdateRentedBook';

interface IProps {
  refetchData: () => void;
  onCloseModal: () => void;
  open: boolean;
}

export const ModalRentedBookDetail = ({ refetchData, open, onCloseModal }: IProps) => {
  const { rentedBookSelected } = useAppSelector((state) => state.rentedBook);
  const { countItemNotReturn } = useHandleUpdateRentedBook();
  if (!rentedBookSelected) return null;
  const countOverdueDate = calculateOverdueDate(rentedBookSelected.expectedReturnDate, rentedBookSelected.returnedDate);

  return (
    <ModalCustom
      destroyOnClose={true}
      open={open}
      title="Thông tin chi tiết lượt mượn sách"
      className="modal-rented-book-detail"
      width={800}
      onCancel={onCloseModal}
    >
      <div className="flex flex-col items-center mb-5">
        <p className="font-semi-bold text-2xl primary-color">{rentedBookSelected.readerName}</p>
        <p>Số thẻ: {rentedBookSelected.cardNumber || rentedBookSelected.account}</p>
      </div>
      <Row className="mb-8">
        <Col md={24} lg={15} className="pr-5 mt-3">
          <p>
            Ngày mượn:{' '}
            <span className="font-semi-bold">{formatStringDateTime_hh_mm(rentedBookSelected.borrowedDate)}</span>
          </p>
          <p>
            TNV cho mượn:{' '}
            <span className="font-semi-bold">
              {rentedBookSelected.borrowedConfirmMemberAccount} - {rentedBookSelected.borrowedConfirmMemberName}
            </span>
          </p>
        </Col>
        <Col md={24} lg={9} className="mt-3">
          <p>
            Ngày trả dự kiến:{' '}
            <span className="font-semi-bold">{formatStringDateTime_hh_mm(rentedBookSelected.expectedReturnDate)}</span>
          </p>
          <p>
            Ngày trả toàn bộ:{' '}
            <span className="font-semi-bold">{formatStringDateTime_hh_mm(rentedBookSelected.returnedDate || '')}</span>
          </p>
          {countOverdueDate > 0 && (
            <p className="error-color">
              Số ngày quá hạn: <span className="font-semi-bold">{countOverdueDate}</span>
            </p>
          )}
        </Col>
      </Row>
      {rentedBookSelected.rentedBookDetail.map((item) => (
        <BookDetail key={item.rentedBookDetailId} itemRentedBookDetail={item} refetchData={refetchData} />
      ))}
      {countItemNotReturn === rentedBookSelected.rentedBookDetail.length && (
        <ModalAddBookToRentedBook refetchData={refetchData} />
      )}
      <div className="flex justify-center mt-8">
        <ButtonCancel id="btn-cancel" className="w-20" onClick={onCloseModal}>
          Thoát
        </ButtonCancel>
      </div>
    </ModalCustom>
  );
};

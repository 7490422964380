import clsx from 'clsx';
import './styles.scss';

type DropdownMenuProps = {
  children: React.ReactNode;
  className?: string;
};
type MenuItemProps = {
  icon?: JSX.Element;
  text: string;
  onClick: () => void;
  className?: string;
};

export const DropdownMenuCustom = (props: DropdownMenuProps) => {
  return <div className={clsx('dropdown-menu-custom', props.className || '')}>{props.children}</div>;
};

export const MenuItem = (props: MenuItemProps) => {
  return (
    <div className={clsx('menu-item', props.className || '')} onClick={props.onClick}>
      {props.icon && props.icon}
      <p>{props.text}</p>
    </div>
  );
};

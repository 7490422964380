import React from 'react';

import Card from '../../components/Card';

import { ListCategory } from './components';

import './styles.scss';

export const Category: React.FC = () => {
  return (
    <div className="book-category-page">
      <Card>
        <ListCategory />
      </Card>
    </div>
  );
};

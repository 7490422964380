import { SideBarMenu } from 'src/layouts/MainLayout/main.type';

export const checkCurrentMenu = (link: string) => {
  const findMenu: any = SideBarMenu.find((menubar: any) => {
    if (menubar?.children) {
      return menubar?.children.find((_children: any) => _children.link === link);
    }
    return menubar.link === link;
  });
  if (findMenu) {
    const childrenMenu = findMenu.children?.find((_children: any) => _children.link === link);
    if (childrenMenu) {
      return { ...childrenMenu, icon: findMenu.icon };
    }
    return findMenu;
  } else return SideBarMenu[0];
};

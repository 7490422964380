export enum OriginBookEnum {
  LIBRARY_FUND = 1,
  DONATE = 2,
}
export enum FormItemBook {
  LIST_CATEGORY_ID = 'listCategoryId',
  POSITION_ID = 'bookPositionId',
  NAME = 'name',
  CODE = 'code',
  QTY = 'qty',
  AVAILABLE = 'available',
  NOTE = 'note',
  DESCRIPTION = 'description',
  PUBLISHER_ID = 'publisherId',
  AUTHOR_ID = 'authorId',
  PUBLISH_YEAR = 'publishYear',
  ORIGIN_BOOK = 'originBook',
  ENTRY_DATE = 'entryDate',
}
export const ORIGIN_BOOK_CONFIG = [
  {
    text: 'Thư viện mua',
    value: OriginBookEnum.LIBRARY_FUND,
  },
  {
    text: 'Được ủng hộ',
    value: OriginBookEnum.DONATE,
  },
];

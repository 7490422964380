import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { TableCustom } from 'src/components/TableCustom';
import { DEFAULT_PAGE_SIZE, initParamsSearch, PAGE_SIZE_OPTIONS } from 'src/const';
import {
  formatDateRequestAPI,
  getFullImageUrl,
  messageError,
  parseListCategoryStringToJson,
} from 'src/helpers/fileHelper';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { getListBookBorrowedAction } from 'src/redux/slices/report.slice';
import { IBookBorrowed, IParamsGetBookBorrowed } from 'src/services/report';

import { SearchBookBorrowed } from '../SearchBookBorrowed';

import './styles.scss';

const INIT_PARAMS: IParamsGetBookBorrowed = {
  ...initParamsSearch,
  fromDate: formatDateRequestAPI(moment().startOf('month')),
  toDate: formatDateRequestAPI(moment().endOf('day')),
};

export const ListBookBorrowed = () => {
  const { loadingBookBorrowed, booksBorrowed, paginationBookBorrowed } = useAppSelector((state) => state.report);
  const dispatch = useAppDispatch();
  const [paramsSearch, setParamsSearch] = useState<IParamsGetBookBorrowed>(INIT_PARAMS);

  const tableColumns: any = [
    {
      title: 'Thể loại',
      width: 120,
      render: (record: IBookBorrowed) => {
        const dataCategory = parseListCategoryStringToJson(record.dataCategory);
        return (
          <>
            {dataCategory.map((item) => (
              <img key={item.id} className="h-10 my-1" src={getFullImageUrl(item.logo)} alt="logo" />
            ))}
          </>
        );
      },
    },
    {
      title: 'Mã sách',
      dataIndex: 'code',
      width: 100,
    },
    {
      title: 'Tên sách',
      dataIndex: 'name',
      width: 250,
    },
    {
      title: 'Tác giả',
      dataIndex: 'author',
      width: 150,
    },
  ];

  useEffect(() => {
    getListReader(INIT_PARAMS);
  }, []);

  function onChangePage(page: any, pageSize: any) {
    getListReader({ ...paramsSearch, page, limit: pageSize });
  }

  async function getListReader(params: IParamsGetBookBorrowed) {
    setParamsSearch(params);
    const error: any = await dispatch(getListBookBorrowedAction(params));
    if (error?.length) {
      messageError(error);
    }
  }

  return (
    <div className="list-book-borrowed">
      <SearchBookBorrowed paramsSearch={paramsSearch} setParamsSearch={setParamsSearch} />
      <div className="flex justify-between">
        <p className="text-base">
          Có tất cả <span className="secondary-color">{paginationBookBorrowed?.totalItem}</span> quyển sách
        </p>
      </div>
      <TableCustom
        columns={tableColumns}
        dataSource={booksBorrowed}
        scroll={{ x: 800, y: 500 }}
        rowKey={(record: any) => record.bookId}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          onChange: onChangePage,
          total: paginationBookBorrowed.totalItem,
          current: paginationBookBorrowed.currentPage,
        }}
        loading={loadingBookBorrowed}
      />
    </div>
  );
};

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import appReducer from './slices/app.slices';
import authReducer from './slices/auth.slice';
import authorReducer from './slices/author.slice';
import bookReducer from './slices/book.slice';
import bookPositionReducer from './slices/bookPosition.slice';
import categoryReducer from './slices/category.slice';
import publisherReducer from './slices/publisher.slice';
import readerReducer from './slices/reader.slice';
import rentedBookReducer from './slices/rentedBook.slice';
import reportReducer from './slices/report.slice';
import userReducer from './slices/user.slice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    book: bookReducer,
    bookPosition: bookPositionReducer,
    category: categoryReducer,
    publisher: publisherReducer,
    author: authorReducer,
    reader: readerReducer,
    rentedBook: rentedBookReducer,
    report: reportReducer,
    user: userReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(process.env.NODE_ENV === 'development' ? [createLogger({ collapsed: true })] : []),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

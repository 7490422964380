import { CommonStatusEnum } from 'src/const';
import { OrderByReaderEnum } from 'src/services/reader/reader.interface';

export enum FormItemReader {
  CARD_NUMBER = 'cardNumber',
  NAME = 'name',
  ADDRESS = 'address',
  GENDER = 'gender',
  DOB = 'dob',
  STATUS = 'status',
  LOCK_TO_DATE = 'lockToDate',
  LAST_ACTIVE_DATE = 'lastActiveDate',
  NOTE = 'note',
  PARENT_PHONE = 'parentPhone',
  PARENT_NAME = 'parentName',
  RANK_ID = 'rankId',
  POINT = 'point',
  CREATED_DATE = 'createdDate',
}
export const READER_STATUS_CONFIG = [
  {
    text: 'Hoạt động',
    value: CommonStatusEnum.ACTIVE,
  },
  {
    text: 'Tạm khoá',
    value: CommonStatusEnum.LOCK,
  },
];
export const ORDER_BY_READER = [
  {
    text: 'Số thẻ tăng dần',
    value: OrderByReaderEnum.CARD_NUMBER_ASC,
  },
  {
    text: 'Số thẻ giảm dần',
    value: OrderByReaderEnum.CARD_NUMBER_DESC,
  },
  {
    text: 'Năm sinh lớn => nhỏ',
    value: OrderByReaderEnum.DOB_ASC,
  },
  {
    text: 'Năm sinh nhỏ => lớn',
    value: OrderByReaderEnum.DOB_DESC,
  },
  {
    text: 'Làm mất nhiều sách',
    value: OrderByReaderEnum.LOST_DESC,
  },
];

import httpClient from 'src/configs/http-client';
import { jsonConfig } from 'src/const';
import { IList } from 'src/types';

import { IBookPosition } from './bookPosition.interface';

export const getListBookPosition = () => {
  return httpClient.get<IList<IBookPosition>>('/bookPosition/getListBookPosition');
};
export const createBookPosition = (body: IBookPosition) => {
  return httpClient.post<IBookPosition>('/bookPosition/create', body, jsonConfig);
};
export const updateBookPosition = (body: IBookPosition) => {
  return httpClient.post<IBookPosition>('/bookPosition/update', body, jsonConfig);
};
export const deleteBookPosition = (id: number) => {
  return httpClient.post<void>('/bookPosition/delete', { id }, jsonConfig);
};
